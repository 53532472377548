import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { Router } from '@angular/router';
import { addIcons } from 'ionicons';
import { chevronBackOutline } from 'ionicons/icons';
import { TranslocoModule } from '@ngneat/transloco';

import { DeleteAccountState } from '../../states/delete-account/delete-account.state';

@Component({
  selector: 'mbeon-pwa-delete-account-link-sended',
  standalone: true,
  imports: [
    CommonModule,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonToolbar,
    IonIcon,
    IonTitle,
    IonRow,
    TranslocoModule,
  ],
  templateUrl: './delete-account-link-has-been-sent.component.html',
  styleUrl: './delete-account-link-has-been-sent.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteAccountLinkHasBeenSentComponent {
  readonly #router: Router = inject(Router);

  readonly #deleteAccountState: DeleteAccountState = inject(DeleteAccountState);

  constructor() {
    addIcons({
      chevronBackOutline,
    });
  }

  async goBack(): Promise<void> {
    await this.#router.navigate(['app/profile']);
    this.#deleteAccountState.reset();
  }

  async goToStartPage(): Promise<void> {
    await this.#router.navigate(['app/consultations']);
    this.#deleteAccountState.reset();
  }
}
