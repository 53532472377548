import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import {
  ApproveCommunicationChannelTokenError,
  RegistrationApplicationService,
} from '@mbeon-pwa/domain';

import { IonicModule } from '@ionic/angular';

import { IonButton, IonHeader, IonTitle } from '@ionic/angular/standalone';

import { lastValueFrom, Observable } from 'rxjs';

import { RxState } from '@rx-angular/state';

import { RxLet } from '@rx-angular/template/let';

import { PLATFORM_IDENTIFIER, PlatformIdentifier } from '@mbeon-pwa/common';

import { RegistrationState } from '../../states/registration/registration.state';

interface ApproveEmailState {
  readonly showLoadingIndicator: boolean;
}

@Component({
  selector: 'mbeon-pwa-email',
  standalone: true,
  imports: [CommonModule, IonicModule, IonButton, IonHeader, IonTitle, RxLet],
  templateUrl: './approve-email.component.html',
  styleUrl: './approve-email.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: RxState,
      useFactory: (): RxState<ApproveEmailState> => new RxState(),
    },
  ],
})
export class ApproveEmailComponent implements OnInit {
  readonly #activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  readonly #platform: PlatformIdentifier = inject(PLATFORM_IDENTIFIER);

  readonly #registrationApplicationService: RegistrationApplicationService =
    inject(RegistrationApplicationService);

  readonly #router: Router = inject(Router);

  readonly #state: RxState<ApproveEmailState> =
    inject<RxState<ApproveEmailState>>(RxState);

  #registerState: RegistrationState = inject(RegistrationState);

  get state$(): Observable<ApproveEmailState> {
    return this.#state.select();
  }

  async ngOnInit(): Promise<void> {
    if (this.#platform.isBrowser) {
      const token: string | null =
        this.#activatedRoute.snapshot.queryParamMap.get('token');

      if (token) {
        this.#state.set({
          showLoadingIndicator: true,
        });

        this.#registerState.set({
          token: token,
        });

        try {
          await lastValueFrom(
            this.#registrationApplicationService.validateEmailActivateToken(
              token,
            ),
          );
          this.#registerState.set({ registerSuccess: true });
          await this.#router.navigate(['register/registration-success']);
        } catch (error: unknown) {
          if (error instanceof ApproveCommunicationChannelTokenError) {
            await this.#handleTokenError(error);
          } else {
            await this.#router.navigate(['non-specific-error']);
          }
        } finally {
          this.#state.set({
            showLoadingIndicator: false,
          });
        }
      } else {
        await this.#router.navigate(['login']);
      }
    }
  }

  async #handleTokenError(tokenError: ApproveCommunicationChannelTokenError) {
    if (
      tokenError.tokenForCommunicationChannelApprovalBelongsToAlreadyApprovedCommunicationChannel
    ) {
      await this.#router.navigate(['login']);
    } else if (tokenError.tokenForCommunicationChannelApprovalHasExpired) {
      this.#registerState.set({ registrationLinkExpired: true });
      await this.#router.navigate(['register/registration-link-expired']);
    } else {
      await this.#router.navigate(['non-specific-error']);
    }
  }
}
