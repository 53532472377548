<ng-container *transloco="let t">
  <ng-container *rxLet="state$; let state">
    <ion-alert
      [backdropDismiss]="false"
      [isOpen]="state.isOpen"
      [message]="state.message"
      [header]="t('global-message.header')"
      [buttons]="[
        {
          text: t('general.button.ok'),
          role: 'cancel'
        }
      ]"
      (didDismiss)="hideMessage()"
    ></ion-alert>
  </ng-container>
</ng-container>
