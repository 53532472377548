import {
  AuthenticationApplicationService,
  LanguageApplicationService,
  onDomainEvent,
  ProfileApplicationService,
  userAuthenticated,
  type UserProfile,
} from '@mbeon-pwa/domain';

import {
  catchError,
  exhaustMap,
  firstValueFrom,
  lastValueFrom,
  type Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';

import { AppState } from '../../state/app.state';

export function profileAppInitializer(
  appState: AppState,
  authenticationApplicationService: AuthenticationApplicationService,
  languageApplicationService: LanguageApplicationService,
  profileApplicationService: ProfileApplicationService,
) {
  return async (): Promise<void> => {
    const areTokensValid: boolean = await firstValueFrom(
      authenticationApplicationService.areTokensValid(),
    );

    if (areTokensValid) {
      await lastValueFrom(handleProfile());
    }

    onDomainEvent(userAuthenticated).pipe(switchMap(handleProfile)).subscribe();
  };

  function handleProfile(): Observable<void> {
    return profileApplicationService.getProfile().pipe(
      tap({
        next: (userProfile: UserProfile): void => {
          appState.setUserProfile(userProfile);
        },
      }),
      exhaustMap(
        (userProfile: UserProfile): Observable<void> =>
          languageApplicationService.setLanguage(userProfile.locale),
      ),
      catchError((): Observable<void> => {
        // TODO ?
        return of(undefined);
      }),
    );
  }
}
