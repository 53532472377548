import { type Route } from '@angular/router';

import { authenticatedGuard } from '../../common/guards/authenticated/authenticated.guard';

import { FavoritesComponent } from './pages/favorites/favorites.component';
import { consultantIdGuard } from './guards/consultant-id/consultant-id.guard';

export const ConsultantRoutes: readonly Route[] = [
  {
    path: 'consultant/:id',
    loadComponent: () =>
      import('./pages/consultant/consultant.component').then(
        (m) => m.ConsultantComponent,
      ),
    canActivate: [authenticatedGuard, consultantIdGuard],
  },
  {
    path: 'favorites',
    loadComponent: () =>
      import('./pages/favorites/favorites.component').then(
        (m) => m.FavoritesComponent,
      ),
    canActivate: [authenticatedGuard],
  },
  {
    path: 'choose-consultant',
    loadComponent: () =>
      import('./pages/choose-consultant/choose-consultant.component').then(
        (m) => m.ChooseConsultantComponent,
      ),
    canActivate: [authenticatedGuard],
  },
  {
    path: 'consultant-language',
    loadComponent: () =>
      import('./pages/consultant-language/consultant-language.component').then(
        (m) => m.ConsultantLanguageComponent,
      ),

    canActivate: [authenticatedGuard],
  },
  {
    path: 'consultant-gender',
    loadComponent: () =>
      import('./pages/consultant-gender/consultant-gender.component').then(
        (m) => m.ConsultantGenderComponent,
      ),
    canActivate: [authenticatedGuard],
  },
  {
    path: 'consultant-shared-files/:id',
    loadComponent: () =>
      import(
        './pages/consultant-shared-files/consultant-shared-files.component'
      ).then((m) => m.ConsultantSharedFilesComponent),
    canActivate: [authenticatedGuard],
  },
];
