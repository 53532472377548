<ng-container *transloco="let t">
  <ng-container *rxLet="state$; let state">
    <ng-container *rxIf="state.consultationServerConnectionDisconnected">
      <ion-icon name="information-circle"></ion-icon>
      <span>{{ t('toast.connection.could-not-been-established') }}</span>
    </ng-container>

    <ng-container *rxIf="state.consultationServerConnectionIsConnecting">
      <ion-icon name="information-circle"></ion-icon>
      <span>{{ t('toast.connection.is-established') }}</span>
    </ng-container>

    <ng-container *rxIf="state.consultationServerConnectionIsConnected">
      <ion-icon name="checkmark-circle"></ion-icon>
      <span>{{ t('toast.connection.has-been-established') }}</span>
    </ng-container>
  </ng-container>
</ng-container>
