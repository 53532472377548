import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  type InputSignal,
  OnInit,
} from '@angular/core';

import {
  IonButton,
  IonIcon,
  IonProgressBar,
  IonText,
} from '@ionic/angular/standalone';

import { RxState } from '@rx-angular/state';
import { RxIf } from '@rx-angular/template/if';
import { RxLet } from '@rx-angular/template/let';

import { addIcons } from 'ionicons';
import { pauseCircleOutline, playCircleOutline } from 'ionicons/icons';

import {
  defer,
  fromEvent,
  map,
  type Observable,
  of,
  switchMap,
  first,
  tap,
  filter,
} from 'rxjs';

import type { ConsultationMessagesVoiceMessageComponentState } from './consultation-messages-voice-message.component.state';

@Component({
  selector: 'mbeon-pwa-consultation-messages-voice-message',
  standalone: true,
  imports: [DatePipe, IonButton, IonIcon, RxIf, RxLet, IonProgressBar, IonText],
  templateUrl: './consultation-messages-voice-message.component.html',
  styleUrl: './consultation-messages-voice-message.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [RxState],
})
export class ConsultationMessagesVoiceMessageComponent implements OnInit {
  name: InputSignal<string | undefined> = input();

  source: InputSignal<string> = input.required();

  readonly state$: Observable<ConsultationMessagesVoiceMessageComponentState> =
    defer(
      (): Observable<ConsultationMessagesVoiceMessageComponentState> =>
        this.#state.select(),
    );

  readonly #audio: HTMLAudioElement = new Audio();

  readonly #state: RxState<ConsultationMessagesVoiceMessageComponentState> =
    inject<RxState<ConsultationMessagesVoiceMessageComponentState>>(RxState);

  constructor() {
    addIcons({
      pauseCircleOutline,
      playCircleOutline,
    });

    this.#state.set({
      currentTime: 0,
      isPlaying: false,
      ready: false,
    });

    effect((): void => {
      this.#state.set({
        name: this.name(),
        ready: false,
      });
    });

    effect((): void => {
      this.#audio.src = this.source();
    });
  }

  ngOnInit(): void {
    this.#state.connect(
      fromEvent(this.#audio, 'loadedmetadata').pipe(
        switchMap((): Observable<number> => {
          const duration: number = this.#audio.duration;

          if (duration !== Infinity || isNaN(Number(duration))) {
            return of(duration);
          }

          // chrome hack to get duration
          this.#audio.currentTime = 1e101;

          return fromEvent(this.#audio, 'timeupdate').pipe(
            first(),
            tap({
              next: (): void => {
                this.#audio.currentTime = 0;
              },
            }),
            map((): number => this.#audio.duration),
          );
        }),
        map(
          (
            duration: number,
          ): Partial<ConsultationMessagesVoiceMessageComponentState> => ({
            duration,
            ready: true,
            remainingTime: duration,
          }),
        ),
      ),
    );

    this.#state.connect(
      fromEvent(this.#audio, 'timeupdate').pipe(
        filter((): boolean => this.#state.get().ready),
        map(
          (): Partial<ConsultationMessagesVoiceMessageComponentState> => ({
            currentTime: this.#audio.currentTime,
            remainingTime: this.#audio.duration - this.#audio.currentTime,
          }),
        ),
      ),
    );

    this.#state.connect(
      fromEvent(this.#audio, 'ended').pipe(
        map(
          (): Partial<ConsultationMessagesVoiceMessageComponentState> => ({
            isPlaying: false,
            currentTime: 0,
            remainingTime: this.#audio.duration,
          }),
        ),
      ),
    );
  }

  async togglePlayState(): Promise<void> {
    const state: ConsultationMessagesVoiceMessageComponentState =
      this.#state.get();

    if (state.isPlaying) {
      this.#audio.pause();
    } else {
      await this.#audio.play();
    }

    this.#state.set({
      isPlaying: !state.isPlaying,
    });
  }
}
