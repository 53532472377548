import { inject } from '@angular/core';

import { first, map, Observable } from 'rxjs';

import { Router, UrlTree } from '@angular/router';

import { RegistrationState } from '../../../states/registration/registration.state';
import { RegistrationState as State } from '../../../states/registration/registration-state.type';

export const registrationPasswordDataGuard: () => Observable<
  true | UrlTree
> = (): Observable<true | UrlTree> => {
  const registrationState: RegistrationState = inject(RegistrationState);
  const router: Router = inject(Router);

  return registrationState.select().pipe(
    first(),
    map(
      (registrationState: State): true | UrlTree =>
        !!(
          registrationState.email &&
          registrationState.username &&
          registrationState.termsOfUse
        ) || router.parseUrl('register/account-data'),
    ),
  );
};
