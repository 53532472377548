import { type EnvironmentProviders, importProvidersFrom } from '@angular/core';

import { AuthenticationModule } from './authentication/authentication.module';
import type { AuthenticationConfig } from './authentication/types/authentication-config.type';
import { ConsultationModule } from './consultation/consultation.module';
import { ConsultationConfig } from './consultation/types/consultation-config.type';
import { ProfileModule } from './profile/profile.module';
import { UserInfoModule } from './user-info/user-info.module';

export function provideInfrastructure(config: {
  readonly authentication: AuthenticationConfig;
  readonly consultation: ConsultationConfig;
}): EnvironmentProviders {
  return importProvidersFrom([
    AuthenticationModule.forRoot(config.authentication),
    ConsultationModule.forRoot(config.consultation),
    ProfileModule.forRoot(),
    UserInfoModule.forRoot(),
  ]);
}
