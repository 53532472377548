<ion-header *transloco="let t">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancel()"
        >{{ t('password-reset.set-password.header.button.cancel') }}
      </ion-button>
    </ion-buttons>
    <ion-title>{{ t('password-reset.set-password.header.title') }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *transloco="let t" [fullscreen]="true">
  <ion-grid *rxLet="state$; let state" [fixed]="true">
    <ion-row class="ion-justify-content-center">
      <ion-col>
        <div class="p-6">
          <h2 class="mt-0 mb-2">
            {{ t('password-reset.set-password.title') }}
          </h2>
          <p class="mb-6 mt-0">
            {{ t('password-reset.set-password.description') }}
          </p>

          <form (ngSubmit)="submit()" [formGroup]="passwordForm">
            <ng-container>
              <mbeon-pwa-password-validator
                [control]="passwordForm.controls['password']"
              ></mbeon-pwa-password-validator>

              <ion-list>
                <mbeon-pwa-input-form-field
                  [control]="passwordForm.controls.password"
                  [hasError]="
                    state.passwordErrors?.passwordIsSimilarToEmailOrUsername ||
                    state.passwordErrors?.passwordIsSameAsCurrentPassword ||
                    (!!passwordForm.controls.password.errors &&
                      !!passwordForm.controls.password.value)
                  "
                  [label]="t('input.password.label')"
                  class="mb-1 block"
                  type="password"
                ></mbeon-pwa-input-form-field>
                <div class="input-error-message mb-2 pl-4">
                  <p
                    *ngIf="
                      state.passwordErrors?.passwordContainsSpacesOrTabs ||
                      (!!passwordForm.controls.password.errors?.[
                        'whiteSpace'
                      ] &&
                        !!passwordForm.controls.password.value)
                    "
                  >
                    {{ t('input-validator.password.whitespace') }}
                  </p>
                  <p
                    *ngIf="
                      state.passwordErrors?.passwordIsSimilarToEmailOrUsername
                    "
                  >
                    {{
                      t('input-validator.password-contains-username-or-email')
                    }}
                  </p>
                  <p
                    *ngIf="
                      state.passwordErrors?.passwordIsSameAsCurrentPassword
                    "
                  >
                    {{
                      t('input-validator.password-is-same-as-current-password')
                    }}
                  </p>
                </div>
                <mbeon-pwa-input-form-field
                  [control]="passwordForm.controls.confirmPassword"
                  [hasError]="
                    state.passwordErrors
                      ?.passwordConfirmationDiffersFromPassword ||
                    state.passwordErrors?.passwordIsSameAsCurrentPassword ||
                    state.passwordErrors
                      ?.passwordConfirmationDiffersFromPassword ||
                    !!passwordForm.errors?.['mismatch']
                  "
                  [label]="t('input.password-repeat.label')"
                  class="mb-4 block"
                  type="password"
                ></mbeon-pwa-input-form-field>
                <div class="input-error-message mb-0 pl-4">
                  <p
                    *ngIf="
                      (!!passwordForm.errors &&
                        passwordForm.errors?.['mismatch']) ||
                      state.passwordErrors
                        ?.passwordConfirmationDiffersFromPassword
                    "
                  >
                    {{ t('input-validator.password.repeat') }}
                  </p>
                </div>
              </ion-list>

              <ion-button
                [disabled]="passwordForm.invalid"
                class="button_primary mb-2"
                type="submit"
                >{{ t('password-reset.set-password.button.set-new-password') }}
              </ion-button>
            </ng-container>
          </form>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ng-template
  #passwordIndicator
  let-condition="condition"
  let-message="message"
  let-touched="touched"
  let-value="value"
>
  <div class="password-validator-message pb-6">
    <div>
      <ion-icon
        *ngIf="value === '' || value === null"
        class="untouched"
        name="ellipse"
      ></ion-icon>
      <ion-icon
        *ngIf="condition !== null && !condition && value.length > 0"
        class="error"
        name="close-circle"
      ></ion-icon>
      <ion-icon
        *ngIf="
          (condition && value.length > 0) ||
          (condition === null && value.length > 0)
        "
        class="success"
        name="checkmark-circle"
      ></ion-icon>
    </div>
    <p>{{ message }}</p>
  </div>
</ng-template>
