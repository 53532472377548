import { type Subject } from 'rxjs';

export function generateRenderCallbackProxy<T>(
  renderCallback$: Subject<T>,
): Subject<T> {
  return new Proxy(renderCallback$, {
    get(target: Subject<T>, p: keyof Subject<T>): unknown {
      if (p !== 'complete') {
        return target[p];
      }

      throw 'Restricted';
    },
  });
}
