import {
  inject,
  Injectable,
  InjectionToken,
  type OnDestroy,
} from '@angular/core';

import { compareDates } from '@mbeon-pwa/common';
import {
  type Consultant,
  ConsultationApplicationService,
  type ConsultationMessage,
  ConsultationStatistics,
  UserProfile,
} from '@mbeon-pwa/domain';

import { RxState } from '@rx-angular/state';

import {
  defer,
  exhaustMap,
  first,
  map,
  type Observable,
  of,
  switchMap,
  tap,
  withLatestFrom,
  filter,
  merge,
} from 'rxjs';

import { AppState } from '../../../core/state/app.state';
import { groupConsultationMessages } from '../helpers/group-consultation-messages/group-consultation-messages.helper';
import type { ChatThreadItem } from '../types/chat-thread-group.type';

export const CONSULTANT$: InjectionToken<Observable<Consultant>> =
  new InjectionToken('CONSULTANT');

interface State {
  readonly consultationStatistics: ConsultationStatistics;
  readonly initialFetchPerformed: boolean;
  readonly messages: readonly ConsultationMessage[];
  readonly messagesCount: number; // how many messages have been loaded so far WITHOUT greeting and absence

  readonly acknowledgmentTimestamp?: Date;
}

@Injectable()
export class ConsultationMessagesViewService implements OnDestroy {
  readonly messages$: Observable<readonly ChatThreadItem[]> = defer(
    (): Observable<readonly ChatThreadItem[]> =>
      this.#state.select().pipe(
        filter(
          (state: State): boolean =>
            state.initialFetchPerformed && !!state.consultationStatistics,
        ),
        withLatestFrom(this.#consultant$, this.#appState.userProfile$),
        map(
          ([state, consultant, userProfile]: readonly [
            State,
            Consultant,
            UserProfile,
          ]): readonly ChatThreadItem[] => {
            let messages: ConsultationMessage[];

            // >= - take absence message into account
            if (!this.canLoadMore() && !!consultant.autoAnswers.greeting) {
              messages = [
                {
                  id: 'mbeon.consultant.greeting',
                  message: consultant.autoAnswers.greeting,
                  recipient: userProfile.username,
                  poster: consultant.username,
                  attachments: [],
                  timestamp: null,
                },
                ...state.messages,
              ];
            } else {
              messages = [...state.messages];
            }

            return groupConsultationMessages(
              messages,
              userProfile.username,
              state.acknowledgmentTimestamp,
            );
          },
        ),
      ),
  );

  readonly #appState: AppState = inject(AppState);

  readonly #consultationApplicationService: ConsultationApplicationService =
    inject(ConsultationApplicationService);

  readonly #consultant$: Observable<Consultant> = inject(CONSULTANT$);

  readonly #state: RxState<State> = new RxState();

  constructor() {
    // initial fetch
    this.#state.connect(
      // when we get a new consultant
      this.#consultant$.pipe(
        tap({
          // we reset the state to initial value
          next: (): void => {
            this.#state.set({
              messagesCount: 0,
              initialFetchPerformed: false,
            });
          },
        }),
        // fetch all consultation messages for the first time
        switchMap(
          (
            consultant: Consultant,
          ): Observable<readonly ConsultationMessage[]> =>
            this.#consultationApplicationService
              .getConsultationMessages(consultant)
              .pipe(
                map(
                  (
                    consultationMessages: readonly ConsultationMessage[],
                  ): readonly ConsultationMessage[] => [
                    ...consultationMessages,
                    // if the consultant has an absence message we put it first
                    ...(consultant.autoAnswers?.absence
                      ? [
                          {
                            id: 'mbeon.consultant.absence',
                            message: consultant.autoAnswers.absence,
                            timestamp: new Date(),
                            poster: consultant.username,
                            recipient: '*',
                            attachments: [],
                          } satisfies ConsultationMessage,
                        ]
                      : []),
                  ],
                ),
              ),
        ),
        // then update the state
        map(
          (messages: readonly ConsultationMessage[]): Partial<State> => ({
            messages,
            messagesCount: messages.length,
            initialFetchPerformed: true,
          }),
        ),
      ),
    );

    // fetch statistics initially
    this.#state.connect(
      this.#consultant$.pipe(
        tap({
          next: (): void => {
            this.#state.set({
              consultationStatistics: undefined,
            });
          },
        }),
        switchMap(
          (consultant: Consultant): Observable<ConsultationStatistics> =>
            this.#consultationApplicationService.getConsultationStatistics(
              consultant,
            ),
        ),
        map(
          (consultationStatistics: ConsultationStatistics): Partial<State> => ({
            consultationStatistics,
          }),
        ),
      ),
    );

    // updates
    this.#state.connect(
      this.#consultant$.pipe(
        switchMap(
          (consultant: Consultant): Observable<ConsultationMessage> =>
            this.#consultationApplicationService.getConsultationMessageUpdates(
              consultant,
            ),
        ),
        withLatestFrom(this.#state.select('messages')),
        map(
          ([consultationMessage, consultationMessages]: readonly [
            ConsultationMessage,
            readonly ConsultationMessage[],
          ]): Partial<State> => ({
            messages: [...consultationMessages, consultationMessage],
            messagesCount: this.#state.get().messagesCount + 1,
          }),
        ),
      ),
    );

    this.#state.connect(
      this.#consultant$.pipe(
        tap({
          next: (): void => {
            this.#state.set({
              acknowledgmentTimestamp: undefined,
            });
          },
        }),
        switchMap(
          (consultant: Consultant): Observable<Date | undefined> =>
            merge(
              this.#consultationApplicationService.getConsultationAcknowledgementUpdates(
                consultant,
              ),
              this.#state
                .select('consultationStatistics')
                .pipe(
                  map(
                    (
                      consultationStatistics: ConsultationStatistics,
                    ): Date | undefined =>
                      consultationStatistics?.acknowledgements.consultant,
                  ),
                ),
            ),
        ),
        map((update: Date | undefined): Partial<State> => {
          const acknowledgmentTimestamp: Date | undefined =
            this.#state.get().acknowledgmentTimestamp;

          return {
            acknowledgmentTimestamp:
              acknowledgmentTimestamp &&
              compareDates(acknowledgmentTimestamp, update ?? null) === 1
                ? acknowledgmentTimestamp
                : update,
          };
        }),
      ),
    );
  }

  ngOnDestroy(): void {
    this.#state.ngOnDestroy();
  }

  canLoadMore(): boolean {
    const state: State = this.#state.get();

    return state.messagesCount < state.consultationStatistics.messageCount;
  }

  loadPrevious(): Observable<void> {
    if (!this.canLoadMore()) {
      return of(undefined);
    }

    return this.#consultant$.pipe(
      first(),
      withLatestFrom(this.#state.select('messages')),
      exhaustMap(
        ([consultant, messages]: [
          Consultant,
          readonly ConsultationMessage[],
        ]): Observable<readonly ConsultationMessage[]> =>
          this.#consultationApplicationService.getConsultationMessages(
            consultant,
            messages.at(0)?.timestamp ?? undefined,
          ),
      ),
      withLatestFrom(this.#state.select('messages')),
      tap({
        next: ([loadedMessages, currentMessages]: [
          readonly ConsultationMessage[],
          readonly ConsultationMessage[],
        ]): void => {
          this.#state.set({
            messages: [...loadedMessages, ...currentMessages],
            messagesCount:
              this.#state.get().messagesCount + loadedMessages.length,
          });
        },
      }),
      map((): void => undefined),
    );
  }
}
