import { AbstractControl, ValidationErrors } from '@angular/forms';

import {
  isUsernameValid,
  usernameContainsOnlyValidCharacters,
  usernameContainsWhiteSpace,
  usernameMaxLength,
  usernameMinLength,
} from '@mbeon-pwa/domain';

import { ValidUsername } from './valid-username.type';

export function usernameValidator(
  control: AbstractControl,
): ValidationErrors | null {
  const username: unknown = control.value;

  if (typeof username !== 'string') {
    return {
      whiteSpace: false,
      minLength: false,
      maxLength: false,
      onlyValidCharacters: false,
    };
  }

  const usernameCheck: boolean = isUsernameValid(username);

  const usernameCheckModel: ValidUsername = {
    whiteSpace: usernameContainsWhiteSpace(username),
    minLength: !usernameMinLength(username),
    maxLength: !usernameMaxLength(username),
    onlyValidCharacters: !usernameContainsOnlyValidCharacters(username),
  };

  return usernameCheck ? null : usernameCheckModel;
}
