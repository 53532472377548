import {
  type AbstractControl,
  type ValidationErrors,
  type ValidatorFn,
} from '@angular/forms';

export function emailInPasswordValidator(
  sourceControl: AbstractControl<unknown>,
): ValidatorFn {
  return (control: AbstractControl<unknown>): ValidationErrors | null => {
    const emailValue: string = sourceControl.value as string;
    const passwordValue: string = control.value as string;
    const passwordIncludesEmail: boolean = passwordValue.includes(emailValue);

    if (emailValue && passwordValue && passwordIncludesEmail) {
      return {
        emailInPassword: passwordIncludesEmail,
      };
    }

    return null;
  };
}
