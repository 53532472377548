import type { ConsultationStatistics } from '@mbeon-pwa/domain';

import type { ChatHistory, MessageFile } from 'ejabberd-client';

export function createConsultationStatistics(
  chatHistory: ChatHistory,
  consultantLastRead?: string | number | null,
): ConsultationStatistics {
  return {
    messageCount: chatHistory.total,
    lastMessageTimestamp: chatHistory.messages.at(-1)?.timestamp,
    lastMessage: chatHistory.messages.at(-1)
      ? {
          from: chatHistory.messages.at(-1)!.from,
          text: chatHistory.messages.at(-1)!.body,
          isVoiceMessage:
            chatHistory.messages
              .at(-1)!
              .files.filter(
                (messageFile: MessageFile): boolean =>
                  messageFile.disposition === 'inline',
              ).length > 0,
          hasAttachments: (chatHistory.messages.at(-1)!.files.length ?? 0) > 0,
        }
      : undefined,
    acknowledgements: {
      consultant:
        consultantLastRead && !isNaN(new Date(consultantLastRead).getTime())
          ? new Date(consultantLastRead)
          : undefined,
    },
  };
}
