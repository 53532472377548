import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { catchError, map, type Observable, throwError } from 'rxjs';

import {
  AuthenticationTokens,
  ForgotProfileNameErrors,
  UnauthorizedAccess,
  UnknownError,
  UserRepository,
} from '@mbeon-pwa/domain';

import type { AccessTokensDTO } from '../../types/access-tokens-dto.type';
import type { ForgotProfileErrorsDto } from '../../types/forgot-profile-errors-dto.error';
import type { SubmitFormErrorsDTO } from '../../types/submit-form-errors-dto.error';
import { extendSessionHttpToken } from '../../values/extend-session.http-token';

import { getSubmitFormErrors } from './helper/get-submit-form-errors/get-submit-form-errors';
import { getForgotUsernameErrors } from './helper/get-forgot-username-errors/get-forgot-username-errors';

@Injectable()
export class UserRepositoryImpl implements UserRepository {
  readonly #httpClient: HttpClient = inject(HttpClient);

  login(
    username: string,
    password: string,
    keepLoggedIn: boolean,
  ): Observable<AuthenticationTokens> {
    return this.#httpClient
      .post<AccessTokensDTO>(
        `login_check`,
        {
          username,
          password,
          stay_logged_in: keepLoggedIn, // TODO HIER NOCH INFO, OB EINGELOGGT BLEIBEN -> PROPERTY KANN SICH ÄNDERN
        },
        {
          withCredentials: true,
        },
      )
      .pipe(
        map(this.#mapAuthenticationTokens),
        catchError((error: unknown): Observable<never> => {
          if (error instanceof UnauthorizedAccess) {
            return throwError(() => new UnauthorizedAccess());
          }

          return throwError((): UnknownError => new UnknownError());
        }),
      );
  }

  extendSession(): Observable<AuthenticationTokens> {
    return this.#httpClient
      .post<AccessTokensDTO>(
        `token/refresh`,
        {},
        {
          withCredentials: true,
          context: new HttpContext().set(extendSessionHttpToken, true),
        },
      )
      .pipe(map(this.#mapAuthenticationTokens));
  }

  logout(): Observable<void> {
    return this.#httpClient.post<void>(`logout`, null, {
      withCredentials: true,
    });
  }

  register(data: {
    readonly email: string;
    readonly username: string;
    readonly password: string;
    readonly passwordConfirmation: string;
    readonly locale: string;
    readonly termsOfUse: boolean;
    readonly privacy: boolean;
  }): Observable<void> {
    return this.#httpClient
      .post<void>(`customers`, {
        username: data.username,
        password: data.password,
        password_confirmation: data.passwordConfirmation,
        locale: data.locale,
        opt_in_terms_and_conditions: data.termsOfUse,
        opt_in_data_privacy_statement: data.privacy,
        communication_channels: {
          email: data.email,
        },
      })
      .pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 422) {
              const submitErrors: SubmitFormErrorsDTO | undefined = error.error
                ?.errors as SubmitFormErrorsDTO;

              if (submitErrors) {
                return throwError(() => getSubmitFormErrors(submitErrors));
              }
            }
          }

          return throwError(() => new UnknownError());
        }),
      );
  }

  retrieveUsername(email: string): Observable<void> {
    return this.#httpClient
      .post<void>('customer/remind-username', {
        email: email,
      })
      .pipe(
        catchError((error: unknown) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 422) {
              const submitErrors: ForgotProfileErrorsDto | undefined =
                error.error?.errors;

              if (submitErrors) {
                return throwError(
                  (): ForgotProfileNameErrors =>
                    getForgotUsernameErrors(submitErrors),
                );
              }
            }
          }

          return throwError((): UnknownError => new UnknownError());
        }),
      );
  }

  #mapAuthenticationTokens(
    accessTokensDTO: AccessTokensDTO,
  ): AuthenticationTokens {
    return {
      api: accessTokensDTO.refresh_token_expires_at, // HIER KOMMT DAS REFRESH_TOKEN EXPIRY DATE REIN!
      xmpp: accessTokensDTO.xmpp_jwt,
    };
  }
}
