import type {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function oldAndNewPasswordNotEqualValidator(): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const oldPassword: string | undefined = formGroup.get('oldPassword')?.value;
    const password = formGroup.get('password')?.value;

    return oldPassword !== password ? null : { oldNewPasswordMatch: true };
  };
}
