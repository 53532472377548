import { first, map, Observable } from 'rxjs';

import { inject } from '@angular/core';

import { UsernameResetState } from '../../state/username-reset/username-reset.state';

import type { UsernameResetState as State } from '../../state/username-reset/username-reset.state.type';

export const userNameSuccessGuard: () => Observable<boolean> =
  (): Observable<boolean> => {
    const usernameResetState: UsernameResetState = inject(UsernameResetState);

    return usernameResetState.select().pipe(
      first(),
      map((stateValue: State): boolean => {
        return stateValue.sendSuccess && !stateValue.sendError;
      }),
    );
  };
