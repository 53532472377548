import { type Route } from '@angular/router';

import { profileRoutes } from '../profile/routes';
import { chatRoutes } from '../consultation/routes';

import { authenticatedGuard } from '../../common/guards/authenticated/authenticated.guard';

import { HomeComponent } from './pages/home/home.component';

export const homeRoutes: readonly Route[] = [
  {
    path: 'app',
    component: HomeComponent,
    children: [...profileRoutes, ...chatRoutes],
    canActivate: [authenticatedGuard],
  },
];
