import { ResetPasswordInitErrors } from '@mbeon-pwa/domain';

import { ResetPasswordInitErrorsDTO } from '../../../../types/reset-password-init-errors-dto.type';

export function getPasswordResetInitErrors(
  responseErrors: ResetPasswordInitErrorsDTO,
): ResetPasswordInitErrors {
  const emailErrors: boolean = (responseErrors.email ?? '').length > 0;

  return new ResetPasswordInitErrors({
    emailAddressContainsInvalidString:
      emailErrors &&
      responseErrors.email!.includes('EMAIL_ADDRESS_CONTAINS_INVALID_STRING'),
    emailAddressContainsNotAllowedCharacters:
      emailErrors &&
      responseErrors.email!.includes(
        'EMAIL_ADDRESS_CONTAINS_NOT_ALLOWED_CHARACTERS',
      ),
    emailAddressContainsSpacesOrTabs:
      emailErrors &&
      responseErrors.email!.includes('EMAIL_ADDRESS_CONTAINS_SPACES_OR_TABS'),
    emailAddressDnsCheckFailed:
      emailErrors &&
      responseErrors.email!.includes('EMAIL_ADDRESS_DNS_CHECK_FAILED'),
    emailAddressIsAlreadyInUse:
      emailErrors &&
      responseErrors.email!.includes('EMAIL_ADDRESS_IS_ALREADY_IN_USE'),
    emailAddressIsEmpty:
      emailErrors && responseErrors.email!.includes('EMAIL_ADDRESS_IS_EMPTY'),
    emailAddressIsNotAString:
      emailErrors &&
      responseErrors.email!.includes('EMAIL_ADDRESS_IS_NOT_A_STRING'),
    emailAddressIsNotRfcCompliant:
      emailErrors &&
      responseErrors.email!.includes('EMAIL_ADDRESS_IS_NOT_RFC_COMPLIANT'),
    emailAddressIsTooLong:
      emailErrors &&
      responseErrors.email!.includes('EMAIL_ADDRESS_IS_TOO_LONG'),
    emailAddressIsTooShort:
      emailErrors &&
      responseErrors.email!.includes('EMAIL_ADDRESS_IS_TOO_SHORT'),
  });
}
