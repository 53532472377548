import { APP_INITIALIZER, type FactoryProvider } from '@angular/core';

import { type Observable } from 'rxjs';

/* eslint-disable @typescript-eslint/no-explicit-any */
export function provideAppInitializer(
  factory: (
    ...dependencies: readonly any[]
  ) => () => Observable<unknown> | Promise<unknown> | void,
  dependencies: readonly any[],
): FactoryProvider {
  return {
    provide: APP_INITIALIZER,
    useFactory: factory,
    multi: true,
    deps: [...dependencies],
  };
}
