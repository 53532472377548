import { RxState } from '@rx-angular/state';

import { Injectable } from '@angular/core';

import { DeleteAccountState as State } from './delete-account.state.type';

@Injectable({
  providedIn: 'root',
})
export class DeleteAccountState extends RxState<State> {
  constructor() {
    super();
    this.reset();
  }

  reset(): void {
    this.set({
      linkHasBeenSent: false,
      linkExpired: false,
      deleteAccountSuccess: false,
      token: undefined,
    });
  }

  setLinkHasBeenSent(sent: boolean): void {
    this.set({
      linkHasBeenSent: sent,
    });
  }

  setLinkExpired(expired: boolean): void {
    this.set({
      linkExpired: expired,
    });
  }

  setDeleteAccountSuccess(deleted: boolean): void {
    this.set({
      deleteAccountSuccess: deleted,
    });
  }

  setDeleteAccountToken(token: string): void {
    this.set({
      token: token,
    });
  }
}
