import { inject, Injectable } from '@angular/core';
import { Messaging } from '@angular/fire/messaging';
import { SwPush } from '@angular/service-worker';

import { Platform } from '@ionic/angular';

import { ProfileApplicationService, UserProfile } from '@mbeon-pwa/domain';

import {
  BehaviorSubject,
  combineLatestWith,
  defer,
  exhaustMap,
  first,
  forkJoin,
  from,
  fromEvent,
  lastValueFrom,
  map,
  type Observable,
  ReplaySubject,
  startWith,
  take,
  skip,
  filter,
} from 'rxjs';

import {
  FIREBASE_MESSAGING,
  FirebaseMessaging,
} from '../../../common/values/firebase-messaging.injection-token';
import { NAVIGATOR } from '../../../common/values/navigator.injection-token';
import { AppState } from '../../state/app.state';

@Injectable({
  providedIn: 'root',
})
export class PushNotifications {
  readonly canBeActivated$: Observable<boolean> = defer(
    (): Observable<boolean> =>
      this.#pushNotificationsPermissionState$.pipe(
        combineLatestWith(this.#technicalRequirementsSatisfied()),
        map(
          ([permissionState, technicalRequirementsSatisfied]: readonly [
            PermissionState,
            boolean,
          ]): boolean =>
            permissionState !== 'denied' && technicalRequirementsSatisfied,
        ),
      ),
  );

  readonly canRetrievePushNotifications$: Observable<boolean> = defer(
    (): Observable<boolean> =>
      this.#pushNotificationsPermissionState$.pipe(
        combineLatestWith(this.#pushNotificationsToken$),
        map(
          ([permissionState, token]: readonly [
            PermissionState,
            string | undefined,
          ]): boolean =>
            this.#checkIfPermissionStateIsSufficient(permissionState) &&
            !!token,
        ),
      ),
  );

  readonly #appState: AppState = inject(AppState);

  readonly #firebaseMessaging: FirebaseMessaging = inject(FIREBASE_MESSAGING);

  readonly #messaging: Messaging = inject(Messaging);

  readonly #navigator: Navigator | undefined = inject(NAVIGATOR);

  readonly #platform: Platform = inject(Platform);

  readonly #profileApplicationService: ProfileApplicationService = inject(
    ProfileApplicationService,
  );

  readonly #pushNotificationsPermissionState$: ReplaySubject<PermissionState> =
    new ReplaySubject(1);

  readonly #pushNotificationsToken$: BehaviorSubject<string | undefined> =
    new BehaviorSubject<string | undefined>(undefined);

  readonly #swPush: SwPush = inject(SwPush);

  constructor() {
    if (this.#navigator?.permissions) {
      from(
        this.#navigator.permissions.query({
          name: 'notifications',
        }),
      )
        .pipe(
          exhaustMap(
            (
              permissionStatus: PermissionStatus,
            ): Observable<PermissionStatus> => {
              return fromEvent(permissionStatus, 'change')
                .pipe(map((): PermissionStatus => permissionStatus))
                .pipe(startWith(permissionStatus));
            },
          ),
          map(
            (permissionStatus: PermissionStatus): PermissionState =>
              permissionStatus.state,
          ),
        )
        .subscribe((permissionState: PermissionState): void => {
          this.#pushNotificationsPermissionState$.next(permissionState);
        });
    } else {
      this.#pushNotificationsPermissionState$.next('denied');
    }
  }

  async init(): Promise<void> {
    const isActivated: boolean = await lastValueFrom(
      this.#profileApplicationService.isNotificationsActivated(),
    );

    const pushNotificationsPermissionState: PermissionState =
      await lastValueFrom(
        this.#pushNotificationsPermissionState$.pipe(first()),
      );

    if (isActivated) {
      await lastValueFrom(
        this.#appState.userProfile$.pipe(
          filter(
            (userProfile: UserProfile | null): userProfile is UserProfile =>
              !!userProfile,
          ),
          take(1),
          exhaustMap((): Promise<void> => this.enable(true)),
        ),
      );
    } else if (
      !this.#checkIfPermissionStateIsSufficient(
        pushNotificationsPermissionState,
      )
    ) {
      await this.disable();
    }
  }

  async disable(): Promise<void> {
    if (await this.#technicalRequirementsSatisfied()) {
      const currentToken: string | undefined =
        this.#pushNotificationsToken$.getValue();
      if (currentToken) {
        // ignore errors
        this.#profileApplicationService
          .disableNotifications(currentToken)
          .subscribe({});
      }

      await this.#firebaseMessaging.deleteToken(this.#messaging);

      this.#pushNotificationsToken$.next(undefined);
    }
  }

  async enable(onlyIfPermissionWasGranted: boolean): Promise<void> {
    const [
      pushNotificationsPermissionState,
      technicalRequirementsSatisfied,
    ]: readonly [PermissionState, boolean] = await lastValueFrom(
      forkJoin([
        this.#pushNotificationsPermissionState$.pipe(first()),
        this.#technicalRequirementsSatisfied(),
      ]),
    );

    if (
      technicalRequirementsSatisfied &&
      (!onlyIfPermissionWasGranted ||
        this.#checkIfPermissionStateIsSufficient(
          pushNotificationsPermissionState,
        ))
    ) {
      try {
        const serviceWorkerRegistration: ServiceWorkerRegistration | undefined =
          await this.#navigator?.serviceWorker.ready;

        const token: string = await this.#firebaseMessaging.getToken(
          this.#messaging,
          {
            serviceWorkerRegistration,
          },
        );

        this.#pushNotificationsToken$.next(token);

        // ignore errors
        this.#profileApplicationService
          .enableNotifications(token)
          .subscribe({});
      } catch (e: unknown) {
        this.#pushNotificationsToken$.next(undefined);

        throw e;
      }
    }
  }

  #checkIfPermissionStateIsSufficient(
    permissionState: PermissionState,
  ): boolean {
    // workaround for iOS bug: https://forums.developer.apple.com/forums/thread/731412
    return (
      (this.#platform.is('ios') &&
        this.#platform.is('pwa') &&
        permissionState === 'prompt') ||
      permissionState === 'granted'
    );
  }

  async #technicalRequirementsSatisfied(): Promise<boolean> {
    const results: readonly boolean[] = await Promise.all([
      this.#firebaseMessaging.isSupported(),
      this.#swPush.isEnabled,
    ]);

    return results.every((result: boolean): boolean => result);
  }
}
