import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  type OnInit,
  Renderer2,
} from '@angular/core';

import { IonIcon } from '@ionic/angular/standalone';

import { TranslocoDirective } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { RxState } from '@rx-angular/state';
import { selectSlice } from '@rx-angular/state/selections';
import { RxIf } from '@rx-angular/template/if';
import { RxLet } from '@rx-angular/template/let';

import { addIcons } from 'ionicons';
import { checkmarkCircle, informationCircle } from 'ionicons/icons';

import { defer, filter, type Observable, timer } from 'rxjs';

import { AppState } from '../../../../core/state/app.state';
import { AppState as State } from '../../../../core/state/app.state.type';

type ConsultationServerStatusState = Pick<
  State,
  | 'consultationServerConnectionIsConnected'
  | 'consultationServerConnectionIsConnecting'
  | 'consultationServerConnectionDisconnected'
  | 'displayedStatus'
>;

@UntilDestroy()
@Component({
  selector: 'mbeon-pwa-consultation-server-status',
  standalone: true,
  templateUrl: './consultation-server-status.component.html',
  styleUrl: './consultation-server-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonIcon, RxLet, RxIf, TranslocoDirective],
  viewProviders: [RxState],
})
export class ConsultationServerStatusComponent implements OnInit {
  readonly state$: Observable<ConsultationServerStatusState> = defer(
    (): Observable<ConsultationServerStatusState> => this.#state,
  );

  readonly #appState: AppState = inject(AppState);

  readonly #elementRef: ElementRef<HTMLElement> = inject(ElementRef);

  readonly #renderer2: Renderer2 = inject(Renderer2);

  readonly #state: Observable<ConsultationServerStatusState> = this.#appState
    .select()
    .pipe(
      selectSlice([
        'consultationServerConnectionIsConnected',
        'consultationServerConnectionIsConnecting',
        'consultationServerConnectionDisconnected',
        'displayedStatus',
      ]),
    );

  constructor() {
    addIcons({
      checkmarkCircle,
      informationCircle,
    });
  }

  ngOnInit(): void {
    this.#appState.hold(
      this.#state.pipe(
        filter(
          (state: ConsultationServerStatusState) =>
            (state.displayedStatus !== 'connecting' &&
              state.consultationServerConnectionIsConnecting) ||
            (state.displayedStatus !== 'connected' &&
              state.consultationServerConnectionIsConnected) ||
            (state.displayedStatus !== 'disconnected' &&
              state.consultationServerConnectionDisconnected),
        ),
      ),
      (state: ConsultationServerStatusState): void => {
        this.#checkState(
          state.consultationServerConnectionIsConnected,
          'state-connected',
          'connected',
          true,
        );
        this.#checkState(
          state.consultationServerConnectionIsConnecting,
          'state-connecting',
          'connecting',
        );
        this.#checkState(
          state.consultationServerConnectionDisconnected,
          'state-disconnected',
          'disconnected',
        );
      },
    );
  }

  #checkState(
    status: boolean,
    className: string,
    displayedStatus: State['displayedStatus'],
    removeClassAfterDelay = false,
  ): void {
    if (status) {
      this.#renderer2.addClass(this.#elementRef.nativeElement, className);

      this.#appState.set({
        displayedStatus,
      });

      if (removeClassAfterDelay) {
        timer(3000)
          .pipe(untilDestroyed(this))
          .subscribe({
            next: (): void => {
              this.#renderer2.removeClass(
                this.#elementRef.nativeElement,
                className,
              );
            },
          });
      }
    } else {
      this.#renderer2.removeClass(this.#elementRef.nativeElement, className);
    }
  }
}
