import { Injectable } from '@angular/core';

import type { Translation, TranslocoLoader } from '@ngneat/transloco';

type LanguageResolver = {
  readonly default: Translation;
};

@Injectable({
  providedIn: 'root',
})
export class TranslocoHttpLoader implements TranslocoLoader {
  readonly #meta: Promise<LanguageResolver> = import(
    '../../../../../meta.json'
  );

  async getTranslation(languageCode: string): Promise<Translation> {
    const [translation, consultantLanguages, meta]: readonly [
      LanguageResolver,
      LanguageResolver,
      LanguageResolver,
    ] = await Promise.all([
      import(`../../../../i18n/${languageCode}.json`),
      import(`../../../../i18n/${languageCode}_consultant_languages.json`),
      this.#meta,
    ]);

    return {
      consultant_languages: consultantLanguages.default,
      ...translation.default,
      '$build.app-build': meta.default['build-identifier'],
      '$build.app-version': meta.default['version'],
    };
  }
}
