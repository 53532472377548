import { AbstractControl, ValidationErrors } from '@angular/forms';

export function emailValidation(
  sourceControl: AbstractControl,
): ValidationErrors | null {
  const emailValue: string = sourceControl.value as string;
  const validEmail: boolean =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(emailValue);

  return !emailValue || validEmail
    ? null
    : {
        emailInvalid: true,
      };
}
