import type { Subject } from 'rxjs';

import type { StropheJS } from '../../types/strophe-js.type';

export function onConnect(
  stropheJS: StropheJS,
  status: number,
  emitters: {
    readonly isConnecting: Subject<boolean>;
    readonly isConnected: Subject<boolean>;
    readonly hasAuthenticationFailed: Subject<void>;
  },
): void {
  switch (status) {
    case stropheJS.Strophe.Status.CONNECTING:
      emitters.isConnecting.next(true);
      break;

    case stropheJS.Strophe.Status.AUTHFAIL:
      emitters.isConnected.next(false);
      emitters.isConnecting.next(false);
      emitters.hasAuthenticationFailed.next();
      break;

    case stropheJS.Strophe.Status.DISCONNECTED:
      emitters.isConnected.next(false);
      emitters.isConnecting.next(false);
      break;

    case stropheJS.Strophe.Status.CONNECTED:
      emitters.isConnected.next(true);
      emitters.isConnecting.next(false);
      break;
  }
}
