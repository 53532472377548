import type { Environment } from './environment.type';

export const environment: Environment = {
  apiBaseUrl: 'https://api.dev.drk.adesso-service.net',

  firebase: {
    appId: '1:820637867715:web:7abe0f9b0e66b72ea7cc76',
    apiKey: 'AIzaSyAlBFOTnDsPUYmefzXnjQqm8IDn2mn1dmM',
    projectId: 'mebon-pwa-dev',

    messaging: {
      senderId: '820637867715',
    },
  },

  chat: {
    host: 'xmpp.mbeon.org',
    websocket: 'wss://xmpp.dev.drk.adesso-service.net',
  },
};
