import {
  ChangeDetectionStrategy,
  Component,
  inject,
  type OnInit,
} from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import {
  IonActionSheet,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonRow,
  IonSkeletonText,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

import {
  type Consultant,
  ConsultantApplicationService,
} from '@mbeon-pwa/domain';

import { TranslocoDirective } from '@ngneat/transloco';

import { RxState } from '@rx-angular/state';
import { RxIf } from '@rx-angular/template/if';
import { RxLet } from '@rx-angular/template/let';
import { RxPush } from '@rx-angular/template/push';

import { addIcons } from 'ionicons';
import { chevronBackOutline, ellipsisHorizontal } from 'ionicons/icons';

import { defer, EMPTY, map, type Observable, switchMap } from 'rxjs';

import { ConsultationMessagesComponent } from '../../components/consultation-messages/consultation-messages.component';
import { ConsultationMessageInputComponent } from '../../components/consultation-message-input/consultation-message-input.component';

import { ConsultationServerStatusComponent } from '../../components/consultation-server-status/consultation-server-status.component';

import type { ChatThreadState } from './chat-thread.component.types';

@Component({
  selector: 'mbeon-pwa-chat-thread',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ConsultationMessageInputComponent,
    ConsultationMessagesComponent,
    ConsultationServerStatusComponent,
    IonActionSheet,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonList,
    IonRow,
    IonSkeletonText,
    IonSpinner,
    IonTitle,
    IonToolbar,
    RxIf,
    RxLet,
    RxPush,
    TranslocoDirective,
  ],
  templateUrl: './chat-thread.component.html',
  styleUrl: './chat-thread.component.scss',
  viewProviders: [RxState],
})
export class ChatThreadComponent implements OnInit {
  readonly state$: Observable<ChatThreadState> = defer(
    (): Observable<ChatThreadState> => this.#state.select(),
  );

  readonly #activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  readonly #consultantApplicationService: ConsultantApplicationService = inject(
    ConsultantApplicationService,
  );

  readonly #router: Router = inject(Router);

  readonly #state: RxState<ChatThreadState> =
    inject<RxState<ChatThreadState>>(RxState);

  constructor() {
    addIcons({
      ellipsisHorizontal,
      chevronBackOutline,
    });

    this.displayConsultantProfile = this.displayConsultantProfile.bind(this);
    this.displaySharedFiles = this.displaySharedFiles.bind(this);

    this.#state.set({
      isLoading: true,
    });
  }

  ngOnInit() {
    this.#state.connect(
      this.#activatedRoute.paramMap.pipe(
        switchMap((paramMap: ParamMap): Observable<Consultant> => {
          const consultantId: string | null = paramMap.get('consultantId');
          return consultantId
            ? this.#consultantApplicationService.getConsultantProfile(
                consultantId,
              )
            : EMPTY;
        }),
        map(
          (consultant: Consultant): Pick<ChatThreadState, 'consultant'> => ({
            consultant,
          }),
        ),
      ),
    );
  }

  async displayConsultantProfile(): Promise<void> {
    await this.#router.navigate([
      'consultant',
      this.#state.get().consultant.id,
    ]);
  }

  async displaySharedFiles(): Promise<void> {
    await this.#router.navigate([
      'consultant-shared-files',
      this.#state.get().consultant.id,
    ]);
  }

  hideLoadingScreen(): void {
    this.#state.set({
      isLoading: false,
    });
  }

  async goBack(): Promise<void> {
    await this.#router.navigate(['app/consultations']);
  }
}
