import { type ErrorHandler, inject } from '@angular/core';

import {
  LoginApplicationService,
  onDomainEvent,
  UnableToProlongAuthentication,
  userSessionExpired,
} from '@mbeon-pwa/domain';

import { lastValueFrom, switchMap } from 'rxjs';

import { LOCATION } from '../../../common/values/location.injection-token';

export class GlobalErrorHandler implements ErrorHandler {
  readonly #loginApplicationService: LoginApplicationService = inject(
    LoginApplicationService,
  );

  readonly #location: Location | undefined = inject(LOCATION);

  constructor() {
    // only happens on errors
    onDomainEvent(userSessionExpired)
      .pipe(
        switchMap(
          (): Promise<void> =>
            this.handleError(new UnableToProlongAuthentication()),
        ),
      )
      .subscribe();
  }

  async handleError(error: unknown): Promise<void> {
    if (error instanceof UnableToProlongAuthentication) {
      try {
        await lastValueFrom(this.#loginApplicationService.logout());
      } catch (e) {
        // error handler received error

        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        this.#location?.replace('/');
      }
    } else {
      throw error;
    }
  }
}
