<ng-container *transloco="let t">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button (click)="gotToFavorites()">
          {{ t('chat-list.favorites') }}
        </ion-button>
      </ion-buttons>
      <ion-title>{{ t('chat-list.header.title') }}</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content [fullscreen]="true">
    <mbeon-pwa-consultation-server-status
      slot="fixed"
    ></mbeon-pwa-consultation-server-status>

    <ng-container *rxLet="state$; let state; suspense: suspense">
      <div class="content-wrapper" *rxIf="state.consultations.length > 0">
        <div class="chat-list-wrapper">
          <ion-grid [fixed]="true">
            <ion-row class="ion-justify-content-center">
              <ion-col>
                <mbeon-pwa-chat-list
                  [chatList]="state.consultations"
                ></mbeon-pwa-chat-list>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>

        <img class="logo" src="assets/img/logo-dark-shadow.svg" />

        <div class="new-chat-button-container">
          <ion-button class="pb-8 pt-4 m-0" (click)="startNewChat()">
            {{ t('chat-list.button.start-new-chat') }}
          </ion-button>
        </div>
      </div>

      <ion-grid *rxIf="state.consultations.length === 0" [fixed]="true">
        <ion-row class="ion-justify-content-center">
          <ion-col>
            <div class="p-6 content">
              <div class="icon-wrapper">
                <ion-icon color="primary" name="chatbubbles-outline"></ion-icon>
              </div>
              <p
                class="text-center mb-7"
                [innerHTML]="t('chat-list.no-chats.description')"
              ></p>
              <ion-button class="pb-8 pt-4 m-0" (click)="startNewChat()">
                {{ t('chat-list.button.start-new-chat') }}
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ng-container>

    <ng-template #suspense>
      <div class="content-wrapper">
        <div class="chat-list-wrapper">
          <ion-grid [fixed]="true">
            <ion-row class="ion-justify-content-center">
              <ion-col>
                <mbeon-pwa-chat-list-skeleton
                  [itemCount]="10"
                ></mbeon-pwa-chat-list-skeleton>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </div>
    </ng-template>
  </ion-content>
</ng-container>
