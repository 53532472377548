<ion-header *transloco="let t">
  <ion-toolbar>
    <ion-title>{{ t('register.email-link-sended.header.title') }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content *transloco="let t">
  <div class="p-8 full-view-content">
    <div class="content">
      <img alt="" class="mb-3_5" src="/assets/img/check-success.svg" />
      <h2 class="mt-0 mb-2">
        {{ t('register.email-link-sended.title') }}
      </h2>
      <p class="mb-6 mt-0">
        {{ t('register.email-link-sended.description') }}
      </p>
      <ion-button (click)="close()" class="button_primary">
        {{ t('register.email-link-sended.button') }}
      </ion-button>
    </div>
  </div>
</ion-content>
