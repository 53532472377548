import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { RxState } from '@rx-angular/state';

import { firstValueFrom, Observable } from 'rxjs';

import {
  ApproveDeleteTokenError,
  ProfileApplicationService,
} from '@mbeon-pwa/domain';

import { IonicModule } from '@ionic/angular';
import { RxLet } from '@rx-angular/template/let';

import { PLATFORM_IDENTIFIER, PlatformIdentifier } from '@mbeon-pwa/common';

import { DeleteAccountState } from '../../states/delete-account/delete-account.state';

interface ApproveTokenState {
  readonly showLoadingIndicator: boolean;
}

@Component({
  selector: 'mbeon-pwa-approve-delete-acc-token',
  standalone: true,
  imports: [CommonModule, IonicModule, RxLet],
  templateUrl: './approve-delete-acc-token.component.html',
  styleUrl: './approve-delete-acc-token.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: RxState,
      useFactory: (): RxState<ApproveTokenState> => new RxState(),
    },
  ],
})
export class ApproveDeleteAccTokenComponent implements OnInit {
  readonly #activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  readonly #platform: PlatformIdentifier = inject(PLATFORM_IDENTIFIER);

  readonly #router: Router = inject(Router);

  readonly #deleteAccountState: DeleteAccountState = inject(DeleteAccountState);

  readonly #profileApplicationService: ProfileApplicationService = inject(
    ProfileApplicationService,
  );

  readonly #state: RxState<ApproveTokenState> =
    inject<RxState<ApproveTokenState>>(RxState);

  get state$(): Observable<ApproveTokenState> {
    return this.#state.select();
  }

  async ngOnInit(): Promise<void> {
    if (this.#platform.isBrowser) {
      const token: string | null =
        this.#activatedRoute.snapshot.queryParamMap.get('token');

      if (token) {
        this.#state.set({
          showLoadingIndicator: true,
        });
        try {
          await firstValueFrom(
            this.#profileApplicationService.approveDeleteAccountToken(token),
          );
          this.#deleteAccountState.setDeleteAccountToken(token);
          await this.#router.navigate(['delete-account']);
        } catch (error) {
          if (error instanceof ApproveDeleteTokenError) {
            this.#deleteAccountState.setLinkExpired(true);
            if (error.tokenForAccountDeleteHasExpired) {
              await this.#router.navigate(['delete-account-link-expired']);
            } else {
              await this.#router.navigate(['non-specific-error']);
            }
          } else {
            await this.#router.navigate(['non-specific-error']);
          }
        } finally {
          this.#state.set({
            showLoadingIndicator: false,
          });
        }
      } else {
        await this.#router.navigate(['login']);
      }
    }
  }
}
