import { ModuleWithProviders, NgModule } from '@angular/core';

import { LanguageRepository, ProfileRepository } from '@mbeon-pwa/domain';

import { LanguageRepositoryImpl } from './repositories/language/language.repostitory';
import { ProfileRepositoryImpl } from './repositories/profile/profle.repository';

@NgModule()
export class ProfileModule {
  static forRoot(): ModuleWithProviders<ProfileModule> {
    return {
      ngModule: ProfileModule,
      providers: [
        {
          provide: LanguageRepository,
          useClass: LanguageRepositoryImpl,
        },
        {
          provide: ProfileRepository,
          useClass: ProfileRepositoryImpl,
        },
      ],
    };
  }
}
