import { CommonModule } from '@angular/common';
import { Component, inject, type OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonRow,
  IonTitle,
  IonToast,
  IonToolbar,
} from '@ionic/angular/standalone';

import {
  type Consultation,
  ConsultationApplicationService,
} from '@mbeon-pwa/domain';

import { TranslocoModule } from '@ngneat/transloco';

import { RxState } from '@rx-angular/state';
import { RxLet } from '@rx-angular/template/let';

import { addIcons } from 'ionicons';
import { chatbubblesOutline } from 'ionicons/icons';

import {
  catchError,
  defer,
  filter,
  interval,
  map,
  mergeMap,
  type Observable,
  of,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs';

import { RxIf } from '@rx-angular/template/if';

import { ActiveRouteService } from '../../../../common/services/active-route/active-route.service';
import { ChatListComponent } from '../../common/chat-list/chat-list.component';
import { ChatListSkeletonComponent } from '../../common/chat-list-skeleton/chat-list-skeleton.component';
import { ConsultationServerStatusComponent } from '../../components/consultation-server-status/consultation-server-status.component';

import { ChatOverviewState } from './chat-overview.component.state.type';

@Component({
  selector: 'mbeon-pwa-chat-overview',
  standalone: true,
  imports: [
    ChatListComponent,
    ChatListSkeletonComponent,
    CommonModule,
    ConsultationServerStatusComponent,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonRow,
    IonTitle,
    IonToast,
    IonToolbar,
    RxIf,
    RxLet,
    TranslocoModule,
  ],
  templateUrl: './chat-overview.component.html',
  styleUrl: './chat-overview.component.scss',
  viewProviders: [RxState],
})
export class ChatOverviewComponent implements OnInit {
  private static readonly refreshInterval = 10000 as const;

  readonly state$: Observable<ChatOverviewState> = defer(
    (): Observable<ChatOverviewState> => this.#state.select(),
  );

  readonly #activeRouteService: ActiveRouteService = inject(ActiveRouteService);

  readonly #consultationApplicationService: ConsultationApplicationService =
    inject(ConsultationApplicationService);

  readonly #isViewActive: Observable<boolean> =
    this.#activeRouteService.isActiveRoute('/app/consultations');

  readonly #router: Router = inject(Router);

  readonly #state: RxState<ChatOverviewState> =
    inject<RxState<ChatOverviewState>>(RxState);

  constructor() {
    addIcons({
      chatbubblesOutline,
    });
  }

  ngOnInit(): void {
    this.#state.connect(
      this.#isViewActive.pipe(
        filter((isViewActive: boolean): isViewActive is true => isViewActive),
        switchMap(
          (): Observable<number> =>
            interval(ChatOverviewComponent.refreshInterval).pipe(
              takeUntil(
                this.#isViewActive.pipe(
                  filter(
                    (isViewActive: boolean): isViewActive is false =>
                      !isViewActive,
                  ),
                ),
              ),
              startWith(0),
            ),
        ),
        mergeMap(
          (): Observable<readonly Consultation[]> =>
            this.#consultationApplicationService.getConsultations(),
        ),
        map(
          (
            consultations: readonly Consultation[],
          ): Partial<ChatOverviewState> => ({
            consultations,
          }),
        ),
      ),
    );
  }

  async gotToFavorites(): Promise<void> {
    await this.#router.navigate(['favorites']);
  }

  async startNewChat(): Promise<void> {
    await this.#router.navigate(['choose-consultant']);
  }
}
