import type {
  ConsultationMessage,
  ConsultationMessageAttachment,
} from '@mbeon-pwa/domain';

import type { Message } from 'ejabberd-client';

const messageTypeFileRegExp = /^&lt;file&gt;(https?:\/\/[^&]*)&lt;\/file&gt;$/;

export function createConsultationMessage(
  receivedMessage: Message,
): ConsultationMessage {
  const id: string = receivedMessage.id;
  const poster: string = receivedMessage.from.substring(
    0,
    Math.max(0, receivedMessage.from.lastIndexOf('@')) || undefined,
  );
  const recipient: string = receivedMessage.to.substring(
    0,
    Math.max(0, receivedMessage.to.lastIndexOf('@')) || undefined,
  );
  const timestamp: Date = receivedMessage.timestamp;

  let message: string | undefined = undefined;
  const attachments: ConsultationMessageAttachment[] = [];
  if (receivedMessage.body) {
    const fileExecArray: RegExpExecArray | null = messageTypeFileRegExp.exec(
      receivedMessage.body,
    );
    if (fileExecArray) {
      attachments.push({
        displayInline: false,
        url: fileExecArray[1],
      });
    } else {
      message = receivedMessage.body;
    }
  }

  if (receivedMessage.files.length > 0) {
    for (const receivedFile of receivedMessage.files) {
      let id: string | undefined = undefined;
      if (receivedFile.source) {
        try {
          const url: URL = new URL(receivedFile.source);
          id = url.searchParams.get('file_id') ?? undefined;
        } catch (e) {
          // ignore, could not retrieve id from message
        }
      }

      attachments.push({
        id,
        displayInline: receivedFile.disposition === 'inline',
        mimeType: receivedFile.mediaType,
        name: receivedFile.name,
        size: receivedFile.size,
        url: receivedFile.source,
      });
    }
  }

  return {
    id,
    attachments,
    message,
    poster,
    recipient,
    timestamp,
  };
}
