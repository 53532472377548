import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  makeEnvironmentProviders,
  type ModuleWithProviders,
  NgModule,
} from '@angular/core';

import { AuthenticationRepository, UserRepository } from '@mbeon-pwa/domain';

import { authenticationRequestHttpInterceptor } from './http-interceptors/authentication-request/authentication-request.http-interceptor';
import { AuthenticationConfig } from './types/authentication-config.type';
import { UserRepositoryImpl } from './repositories/user/user.repository';
import { AuthenticationRepositoryImpl } from './repositories/authentication/authentication.repository';

@NgModule()
export class AuthenticationModule {
  public static forRoot(
    authenticationConfig: AuthenticationConfig,
  ): ModuleWithProviders<AuthenticationModule> {
    return {
      ngModule: AuthenticationModule,
      providers: [
        provideHttpClient(
          withInterceptors([authenticationRequestHttpInterceptor]),
        ),
        makeEnvironmentProviders([
          {
            provide: AuthenticationConfig,
            useValue: authenticationConfig,
          },
          {
            provide: UserRepository,
            useClass: UserRepositoryImpl,
          },
          {
            provide: AuthenticationRepository,
            useClass: AuthenticationRepositoryImpl,
          },
        ]),
      ],
    };
  }
}
