import {
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { RxLet } from '@rx-angular/template/let';

import { IonIcon } from '@ionic/angular/standalone';

import { addIcons } from 'ionicons';
import { checkmarkCircle, closeCircle, ellipse } from 'ionicons/icons';
import { TranslocoModule } from '@ngneat/transloco';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { passwordStrengthValidator } from '../../../features/authentication/validators/password-strength/password-strength.validator';

@UntilDestroy()
@Component({
  selector: 'mbeon-pwa-password-validator',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, IonIcon, RxLet, TranslocoModule],
  templateUrl: './password-validator.component.html',
  styleUrl: './password-validator.component.scss',
})
export class PasswordValidatorComponent implements OnInit {
  @Input({
    required: true,
  })
  control!: FormControl;

  readonly #changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor() {
    addIcons({ ellipse, closeCircle, checkmarkCircle });
  }

  ngOnInit(): void {
    this.control.addValidators([passwordStrengthValidator]);

    this.control.statusChanges.pipe(untilDestroyed(this)).subscribe({
      next: (): void => {
        this.#changeDetectorRef.markForCheck();
      },
    });
  }
}
