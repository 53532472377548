import {
  BehaviorSubject,
  defer,
  first,
  firstValueFrom,
  type Observable,
  skip,
  Subject,
} from 'rxjs';

import { $iq, $msg, $pres, Strophe } from 'strophe.js';
import type Connection from 'strophe.js/src/types/connection';

import { EjabberdClientHandle } from './classes/ejabberd-client-handle/ejabberd-client-handle.class';
import { onConnect } from './helpers/on-connect/on-connect.handler';
import type { StropheJS } from './types/strophe-js.type';
import {
  mamNamespace,
  receiptsNamespace,
  rsmNamespace,
} from './values/namespaces.values';

export class EjabberdClient {
  readonly handle: EjabberdClientHandle;

  readonly isConnected$: Observable<boolean> = defer(
    (): Observable<boolean> => this.#isConnected.asObservable(),
  );

  readonly isConnecting$: Observable<boolean> = defer(
    (): Observable<boolean> => this.#isConnecting.asObservable(),
  );

  readonly hasAuthFailed$: Observable<void> = defer(
    (): Observable<void> => this.#hasAuthenticationFailed.asObservable(),
  );

  #connection?: Connection;

  readonly #isConnected: BehaviorSubject<boolean> = new BehaviorSubject(false);

  readonly #isConnecting: BehaviorSubject<boolean> = new BehaviorSubject(false);

  readonly #hasAuthenticationFailed: Subject<void> = new Subject();

  readonly #stropheJS: StropheJS;

  constructor(
    stropheJS: StropheJS = {
      $iq,
      $msg,
      $pres,
      Strophe,
    },
  ) {
    // message archive management
    // https://github.com/strophe/strophejs-plugin-mam/blob/master/src/strophe.mam.js
    stropheJS.Strophe.addNamespace(mamNamespace, 'urn:xmpp:mam:2');
    stropheJS.Strophe.addNamespace(receiptsNamespace, 'urn:xmpp:receipts');
    stropheJS.Strophe.addNamespace(
      rsmNamespace,
      'http://jabber.org/protocol/rsm',
    );

    this.handle = new EjabberdClientHandle(
      stropheJS,
      (): Connection | undefined => this.#connection,
    );

    this.#stropheJS = stropheJS;
  }

  async connect(
    serverURI: string,
    credentials: {
      readonly jid: string;
      readonly password: string;
    },
  ): Promise<void> {
    this.#connection?.disconnect('New connection');

    this.#connection = new this.#stropheJS.Strophe.Connection(serverURI);

    this.#connection.connect(
      credentials.jid,
      credentials.password,
      (status: number): void =>
        onConnect(this.#stropheJS, status, {
          isConnected: this.#isConnected,
          isConnecting: this.#isConnecting,
          hasAuthenticationFailed: this.#hasAuthenticationFailed,
        }),
    );

    const isConnected: boolean = await firstValueFrom(
      this.#isConnected.pipe(skip(1), first()),
    );

    if (!isConnected) {
      this.#connection?.disconnect('failure from ejabberd');
      this.#connection = undefined;
      throw 'Could not connect';
    }

    // must be sent, else "invalid xml" is returned
    await new Promise<void>((resolve: () => void, reject: () => void): void => {
      if (this.#connection) {
        this.#connection.sendPresence(
          this.#stropheJS.$pres().tree(),
          resolve,
          reject,
        );
      } else {
        reject();
      }
    });

    (window as any).test = this.#connection;
  }

  disconnect(): void {
    this.#connection?.disconnect();

    this.#isConnected.next(false);
    this.#isConnecting.next(false);
  }
}
