import { type Route } from '@angular/router';

import { authenticatedGuard } from '../../common/guards/authenticated/authenticated.guard';

import { ChatThreadComponent } from './pages/chat-thread/chat-thread.component';
import { ChatOverviewComponent } from './pages/chat-overview/chat-overview.component';

export const chatRoutes: readonly Route[] = [
  {
    path: 'consultations',
    component: ChatOverviewComponent,
    canActivate: [authenticatedGuard],
  },
];

export const globalChatRoutes: readonly Route[] = [
  {
    path: 'app/consultations/:consultantId',
    component: ChatThreadComponent,
    canActivate: [authenticatedGuard],
  },
];
