import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import type { LanguageRepository, SupportedLanguage } from '@mbeon-pwa/domain';

import { catchError, EMPTY, type Observable, of } from 'rxjs';

import { LOCAL_STORAGE } from '../../../core/values/local-storage.injection-token';

@Injectable()
export class LanguageRepositoryImpl implements LanguageRepository {
  readonly #httpClient: HttpClient = inject(HttpClient);

  readonly #localStorage: Storage = inject(LOCAL_STORAGE);

  getDefaultLanguage(): Observable<SupportedLanguage | undefined> {
    return of(
      (this.#localStorage.getItem('lang') as SupportedLanguage) ?? undefined,
    );
  }

  setDefaultLanguage(languageCode: string): Observable<void> {
    this.#localStorage.setItem('lang', languageCode);

    return this.#setLanguage(languageCode);
  }

  #setLanguage(languageCode: string): Observable<void> {
    return this.#httpClient
      .post<void>(
        'customer/locale',
        {
          locale: languageCode,
        },
        {
          withCredentials: true,
        },
      )
      .pipe(catchError((): Observable<never> => EMPTY));
  }
}
