import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

import { TranslocoModule } from '@ngneat/transloco';

import { Router } from '@angular/router';

import { InputFormFieldComponent } from '../../../../common/components/inputs/input-form-field/input-form-field.component';

@Component({
  selector: 'mbeon-pwa-forgot-user-name-sended',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    InputFormFieldComponent,
    IonButton,
    IonButtons,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonContent,
    IonGrid,
    IonRow,
    TranslocoModule,
    IonCol,
  ],
  templateUrl: './forgot-user-name-sended.component.html',
  styleUrl: './forgot-user-name-sended.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotUserNameSendedComponent {
  readonly #router: Router = inject(Router);

  async close(): Promise<void> {
    await this.#router.navigate(['login']);
  }
}
