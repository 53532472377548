import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'chatThreadFileSize',
  standalone: true,
  pure: true,
})
export class ChatThreadFileSizePipe implements PipeTransform {
  private static readonly sizeBoundaries: string[] = [
    'B',
    'KB',
    'MB',
    'GB',
    'TB',
  ];

  transform(fileSize: number): string {
    for (
      let multiplier = 0;
      multiplier < ChatThreadFileSizePipe.sizeBoundaries.length;
      multiplier++
    ) {
      const boundarySize: number = Math.pow(1000, multiplier);
      const quotient: number = fileSize / boundarySize;

      if (quotient > 1 && quotient < 1000) {
        return `${Math.round(quotient * 10) / 10}${ChatThreadFileSizePipe.sizeBoundaries[multiplier]}`;
      }
    }

    return `${fileSize}B`;
  }
}
