import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  GlobalMessage,
  LanguageApplicationService,
  SupportedLanguage,
  UserInfoApplicationService,
} from '@mbeon-pwa/domain';
import { firstValueFrom, lastValueFrom, Observable, switchMap } from 'rxjs';
import { RxState } from '@rx-angular/state';
import { RxLet } from '@rx-angular/template/let';
import { IonAlert } from '@ionic/angular/standalone';
import { TranslocoDirective } from '@ngneat/transloco';

import { AppState } from '../../state/app.state';

interface GlobalMessageComponentState {
  isOpen: boolean;
  message: string;
  id: string;
}

@Component({
  selector: 'mbeon-pwa-startup-message',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RxLet, IonAlert, TranslocoDirective],
  templateUrl: './startup-message.component.html',
  styleUrl: './startup-message.component.scss',
  viewProviders: [RxState],
})
export class StartupMessageComponent {
  readonly #appState: AppState = inject(AppState);

  readonly #userInfoApplicationService: UserInfoApplicationService = inject(
    UserInfoApplicationService,
  );

  readonly #languageApplicationService: LanguageApplicationService = inject(
    LanguageApplicationService,
  );

  readonly #globalMessageComponentState: RxState<GlobalMessageComponentState> =
    inject<RxState<GlobalMessageComponentState>>(RxState);

  readonly state$: Observable<GlobalMessageComponentState> =
    this.#globalMessageComponentState.select();

  constructor() {
    this.#appState.userProfile$
      .pipe(switchMap(() => this.#handleGlobalMessage()))
      .subscribe();
  }

  async #handleGlobalMessage(): Promise<void> {
    try {
      const message: GlobalMessage = await firstValueFrom(
        this.#userInfoApplicationService.getGlobalMessage(),
      );

      const lastMessageId: string = await firstValueFrom(
        this.#userInfoApplicationService.getSeenGlobalMessageIds(),
      );

      const messageWasNotShown: boolean = lastMessageId !== message.id;

      const currentLanguage: SupportedLanguage = await lastValueFrom(
        this.#languageApplicationService.getDefaultLanguage(),
      );

      if (messageWasNotShown) {
        this.#globalMessageComponentState.set({
          isOpen: true,
          message: message.messages[currentLanguage] ?? undefined,
          id: message.id,
        });
      }
    } catch (e) {
      //TODO Error handling
    }
  }

  hideMessage(): void {
    this.#userInfoApplicationService.setGlobalMessageSeen(
      this.#globalMessageComponentState.get('id'),
    );
    this.#globalMessageComponentState.set({
      isOpen: false,
    });
  }
}
