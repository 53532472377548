import { inject } from '@angular/core';
import { Observable, map, tap } from 'rxjs';

import { AuthenticationApplicationService } from '@mbeon-pwa/domain';
import { Router, UrlTree } from '@angular/router';

export const notAuthenticatedGuard: () => Observable<
  true | UrlTree
> = (): Observable<true | UrlTree> => {
  const authenticationApplicationService: AuthenticationApplicationService =
    inject(AuthenticationApplicationService);
  const router: Router = inject(Router);

  return authenticationApplicationService
    .areTokensValid()
    .pipe(
      map(
        (valid: boolean): true | UrlTree =>
          !valid || router.parseUrl('/app/consultations'),
      ),
    );
};
