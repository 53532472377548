import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { type GlobalMessage, type UserInfoRepository } from '@mbeon-pwa/domain';

import { EMPTY, exhaustMap, Observable, of } from 'rxjs';

import { LOCAL_STORAGE } from '../../../core/values/local-storage.injection-token';
import type { GlobalMessageDto } from '../../types/global-message-dto.type';

@Injectable()
export class UserInfoRepositoryImpl implements UserInfoRepository {
  private static readonly globalMessagesKey = 'global-messages-id' as const;

  readonly #httpClient: HttpClient = inject(HttpClient);

  readonly #localStorage: Storage = inject(LOCAL_STORAGE);

  getGlobalMessage(): Observable<GlobalMessage> {
    return this.#httpClient
      .get<GlobalMessageDto | null>('startup-message', {
        withCredentials: true,
      })
      .pipe(
        exhaustMap(
          (
            globalMessageDto: GlobalMessageDto | null,
          ): Observable<GlobalMessage | never> => {
            if (globalMessageDto === null) {
              return EMPTY;
            }
            return of({
              id: globalMessageDto.id,
              active_until: globalMessageDto.active_until,
              messages: {
                ar_AE: globalMessageDto.messages.ar_AE,
                de_DE: globalMessageDto.messages.de_DE,
                en_GB: globalMessageDto.messages.en_GB,
                ru_RU: globalMessageDto.messages.ru_RU,
                uk_UA: globalMessageDto.messages.uk_UA,
                fa_IR: globalMessageDto.messages.fa_IR,
              },
            });
          },
        ),
      );
  }

  getSeenGlobalMessageIds(): Observable<string> {
    const rawId: string | null = this.#localStorage.getItem(
      UserInfoRepositoryImpl.globalMessagesKey,
    );

    if (rawId !== 'undefined' && rawId !== null) {
      return of(JSON.parse(rawId));
    }

    return of('');
  }

  setGlobalMessageWasSeen(id: string): Observable<void> {
    this.#localStorage.setItem(
      UserInfoRepositoryImpl.globalMessagesKey,
      JSON.stringify(id),
    );

    return of(undefined);
  }
}
