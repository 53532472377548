import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import {
  type ConsultationAttachment,
  type ConsultationFilesRepository,
  IllegalConsultationFileError,
  IllegalConsultationFileReason,
} from '@mbeon-pwa/domain';

import { catchError, map, type Observable, throwError } from 'rxjs';

import type { FileUploadDTO } from '../../types/file-upload-dto.type';
import type { DownloadFileDto } from '../../types/download-file-dto.type';

@Injectable()
export class ConsultationFilesRepositoryImpl
  implements ConsultationFilesRepository
{
  readonly #httpClient: HttpClient = inject(HttpClient);

  addNewFile(
    consultantId: string,
    attachment: Blob,
    isVoiceMessage: boolean,
  ): Observable<ConsultationAttachment> {
    const formData: FormData = new FormData();
    formData.append('file', attachment);
    formData.append('is_voice_message', isVoiceMessage ? '1' : '0');
    formData.append('recipient_id', consultantId);

    return this.#httpClient
      .post<FileUploadDTO>('consultation/files/upload', formData, {
        withCredentials: true,
      })
      .pipe(
        map(
          (fileUploadDto: FileUploadDTO): ConsultationAttachment => ({
            size: fileUploadDto.size,
            date: new Date(fileUploadDto.created_at),
            name: fileUploadDto.original_name,
            url: fileUploadDto.url,
            id: fileUploadDto.url,
            mimeType: fileUploadDto.mime_type,
            isVoiceMessage: fileUploadDto.is_voice_message,
          }),
        ),
        catchError((errorResponse: HttpErrorResponse): Observable<never> => {
          if (errorResponse.status === 413) {
            return throwError(
              (): IllegalConsultationFileError =>
                new IllegalConsultationFileError(
                  IllegalConsultationFileReason.IllegalSize,
                ),
            );
          }

          return throwError(
            (): IllegalConsultationFileError =>
              new IllegalConsultationFileError(
                IllegalConsultationFileReason.IllegalType,
              ),
          );
        }),
      );
  }

  getConsultationAttachments(
    consultantId: string,
  ): Observable<readonly ConsultationAttachment[]> {
    return this.#httpClient
      .post<readonly DownloadFileDto[]>(
        'consultation/files',
        { consultant_id: consultantId },
        {
          withCredentials: true,
        },
      )
      .pipe(
        map((downloadFiles: readonly DownloadFileDto[]) => {
          return downloadFiles.map(
            (downloadFile: DownloadFileDto): ConsultationAttachment => {
              return {
                date: new Date(downloadFile.upload_timestamp),
                id: downloadFile.id,
                isVoiceMessage: downloadFile.is_voice_message,
                mimeType: downloadFile.mime_type,
                name: downloadFile.original_name,
                size: downloadFile.size,
                url: downloadFile.url,
              };
            },
          );
        }),
      );
  }
}
