<ng-container *transloco="let t">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button (click)="goBack()">
          <ion-icon name="chevron-back-outline"></ion-icon>
        </ion-button>
      </ion-buttons>

      <ion-title>
        <span *rxLet="state$; let state; suspense: suspense">{{
          state.consultant?.name && state.consultant?.lastName
            ? state.consultant?.name + ' ' + state.consultant?.lastName
            : state.consultant?.name
              ? state.consultant?.name
              : state.consultant?.lastName
                ? state.consultant?.lastName
                : state.consultant?.username
        }}</span>

        <ng-template #suspense>
          <ion-skeleton-text></ion-skeleton-text>
        </ng-template>
      </ion-title>

      <ion-buttons slot="end">
        <ion-button color="primary" id="options">
          <ion-icon name="ellipsis-horizontal"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <mbeon-pwa-consultation-server-status
      slot="fixed"
    ></mbeon-pwa-consultation-server-status>

    <ng-container *rxLet="state$; let state">
      <mbeon-pwa-chat-thread-messages
        slot="fixed"
        [consultant]="state.consultant"
        (initiallyRendered)="hideLoadingScreen()"
      ></mbeon-pwa-chat-thread-messages>

      <div
        class="loading-screen"
        [class.is-visible]="state.isLoading"
        slot="fixed"
      >
        <ion-spinner name="lines-sharp" slot="fixed"></ion-spinner>
      </div>
    </ng-container>
  </ion-content>

  <ion-footer>
    <ion-toolbar>
      <mbeon-pwa-chat-thread-footer
        *rxLet="state$; let state"
        [consultant]="state.consultant"
        [disabled]="state.isLoading"
      ></mbeon-pwa-chat-thread-footer>
    </ion-toolbar>
  </ion-footer>

  <ion-action-sheet
    trigger="options"
    header="{{ t('chat-thread.message.options.button.header') }}"
    [buttons]="[
      {
        text: t('chat-thread.message.options.button.shared-files'),
        handler: displaySharedFiles
      },
      {
        text: t('chat-thread.message.options.button.profile'),
        handler: displayConsultantProfile
      },
      {
        text: t('chat-thread.message.options.button.cancel'),
        role: 'cancel'
      }
    ]"
  >
  </ion-action-sheet>
</ng-container>
