import { first, map, Observable } from 'rxjs';

import { inject } from '@angular/core';

import { Router, UrlTree } from '@angular/router';

import { RegistrationState } from '../../../states/registration/registration.state';
import { RegistrationState as State } from '../../../states/registration/registration-state.type';

export const registrationLinkExpiredGuard: () => Observable<
  true | UrlTree
> = (): Observable<true | UrlTree> => {
  const registrationState: RegistrationState = inject(RegistrationState);
  const router: Router = inject(Router);

  return registrationState.select().pipe(
    first(),
    map((registrationState: State): true | UrlTree => {
      return (
        !!registrationState.registrationLinkExpired ||
        router.parseUrl('register/account-data')
      );
    }),
  );
};
