import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

import { TranslocoModule } from '@ngneat/transloco';

import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import {
  NewPasswordErrors,
  ProfileApplicationService,
  UserProfile,
} from '@mbeon-pwa/domain';

import { Router } from '@angular/router';

import { NgIf } from '@angular/common';

import { lastValueFrom, Observable } from 'rxjs';

import { RxLet } from '@rx-angular/template/let';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { NavBackButtonComponent } from '../../../../common/components/buttons/nav-back-button/nav-back-button.component';
import { InputFormFieldComponent } from '../../../../common/components/inputs/input-form-field/input-form-field.component';
import { passwordRepeatValidator } from '../../../authentication/validators/password-repeat/password-repeat.validator';
import { PasswordValidatorComponent } from '../../../../common/components/password-validators/password-validator.component';
import { SetPasswordState } from '../../states/set-password/set-password.state';
import { oldAndNewPasswordNotEqualValidator } from '../../../authentication/validators/old-and-new-password-not-equal/old-and-new-password-not-equal.validator';
import { usernameInPasswordValidator } from '../../../authentication/validators/username-in-password/username-in-password.validator';
import { AppState } from '../../../../core/state/app.state';
import { emailInPasswordValidator } from '../../../authentication/validators/email-in-password/email-in-password.validator';

@UntilDestroy()
@Component({
  selector: 'mbeon-pwa-set-password',
  standalone: true,
  imports: [
    IonButtons,
    IonHeader,
    IonToolbar,
    NavBackButtonComponent,
    IonTitle,
    IonContent,
    TranslocoModule,
    InputFormFieldComponent,
    FormsModule,
    ReactiveFormsModule,
    IonButton,
    IonSpinner,
    NgIf,
    PasswordValidatorComponent,
    IonGrid,
    IonRow,
    IonCol,
    RxLet,
  ],
  templateUrl: './set-password.component.html',
  styleUrl: './set-password.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetPasswordComponent implements OnInit {
  readonly newPasswordForm: FormGroup<{
    readonly oldPassword: FormControl<string | null>;
    readonly password: FormControl<string | null>;
    readonly confirmPassword: FormControl<string | null>;
  }> = new FormGroup(
    {
      oldPassword: new FormControl<string>('', [Validators.required]),
      password: new FormControl<string>('', [Validators.required]),
      confirmPassword: new FormControl<string>('', []),
    },
    [
      passwordRepeatValidator(),
      oldAndNewPasswordNotEqualValidator(),
      Validators.required,
    ],
  );

  #appDataState: AppState = inject(AppState);

  #appDataStateData: UserProfile | null = this.#appDataState.get('userProfile');

  readonly #userNameValue: FormControl<string | null>;

  readonly #userEmailValue: FormControl<string | null>;

  readonly #profileService: ProfileApplicationService = inject(
    ProfileApplicationService,
  );

  readonly #setPasswordState: SetPasswordState = inject(SetPasswordState);

  readonly setPasswordStateErrors$: Observable<NewPasswordErrors> =
    this.#setPasswordState.select('newPasswordErrors');

  readonly #router: Router = inject(Router);

  constructor() {
    this.#userNameValue = new FormControl(
      this.#appDataStateData?.username ?? '',
    );
    this.#userEmailValue = new FormControl(this.#appDataStateData?.email ?? '');

    this.newPasswordForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.#setPasswordState.reset();
      });
  }

  ngOnInit(): void {
    this.newPasswordForm.controls.password.addValidators([
      usernameInPasswordValidator(this.#userNameValue),
      emailInPasswordValidator(this.#userEmailValue),
    ]);
  }

  async submit(): Promise<void> {
    this.#setPasswordState.reset();

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    if (this.newPasswordForm.valid) {
      try {
        await lastValueFrom(
          this.#profileService.setNewProfilePassword(
            this.newPasswordForm.controls.oldPassword.value!,
            this.newPasswordForm.controls.password.value!,
            this.newPasswordForm.controls.confirmPassword.value!,
          ),
        );
        this.#setPasswordState.setPasswordHasBeenSent(true);

        await this.#router.navigate(['set-password-success']);

        this.newPasswordForm.reset({
          oldPassword: '',
          password: '',
          confirmPassword: '',
        });
      } catch (error) {
        if (error instanceof NewPasswordErrors) {
          this.#setPasswordState.setNewPasswordErrors(error);
        } else {
          await this.#router.navigate(['non-specific-error']);
        }

        this.#setPasswordState.setPasswordHasBeenSent(false);
      }
    }
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }
}
