import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import {
  ApproveCommunicationChannelTokenError,
  AuthenticationRepository,
  type AuthenticationTokens,
  UnauthorizedAccess,
  UnknownError,
} from '@mbeon-pwa/domain';

import { catchError, exhaustMap, type Observable, of, throwError } from 'rxjs';

import { LOCAL_STORAGE } from '../../../core/values/local-storage.injection-token';
import type { ApproveCommunicationChannelTokenErrorDTO } from '../../types/approve-communication-channel-token-error-dto.type';
import type { ResetNewPasswordErrorDTO } from '../../types/reset-new-password-error-dto.type';
import type { ResetPasswordInitErrorsDTO } from '../../types/reset-password-init-errors-dto.type';

import { getApproveCommunicationChannelTokenErrors } from './helper/get-approve-communication-channel-token-errors/get-approve-communication-channel-token-errors';
import { getPasswordResetInitErrors } from './helper/get-password-reset-init-errors/get-password-reset-init-errors';
import { getResetNewPasswordErrors } from './helper/get-new-password-errors/get-reset-new-password-errors';

@Injectable()
export class AuthenticationRepositoryImpl implements AuthenticationRepository {
  private static readonly authTokensKey = 'auth-tokens' as const;

  readonly #httpClient: HttpClient = inject(HttpClient);

  readonly #localStorage: Storage = inject(LOCAL_STORAGE);

  saveAuthenticationTokens(tokens: AuthenticationTokens): Observable<void> {
    const serializedTokens: string = JSON.stringify(tokens);

    this.#localStorage.setItem(
      AuthenticationRepositoryImpl.authTokensKey,
      serializedTokens,
    );

    return of(undefined);
  }

  getAuthenticationTokens(): Observable<AuthenticationTokens> {
    const rawTokens: string | null = this.#localStorage.getItem(
      AuthenticationRepositoryImpl.authTokensKey,
    );

    if (rawTokens) {
      const tokens: AuthenticationTokens = JSON.parse(
        rawTokens,
        (key: string, value: unknown): Date | unknown => {
          if (key === 'api' && typeof value === 'string') {
            return new Date(value);
          }

          return value;
        },
      );

      if (tokens.api && tokens.api.getTime() >= new Date().getTime()) {
        return of(tokens);
      }

      return this.deleteAuthenticationTokens().pipe(
        exhaustMap(
          (): Observable<never> =>
            throwError((): UnauthorizedAccess => new UnauthorizedAccess()),
        ),
      );
    }

    return throwError((): UnauthorizedAccess => new UnauthorizedAccess());
  }

  deleteAuthenticationTokens(): Observable<void> {
    this.#localStorage.removeItem(AuthenticationRepositoryImpl.authTokensKey);

    return of(undefined);
  }

  resetPassword(
    password: string,
    confirmPassword: string,
    token: string,
  ): Observable<void> {
    return this.#httpClient
      .post<void>('reset-password/change-password', {
        token: token,
        password: password,
        password_confirmation: confirmPassword,
      })
      .pipe(
        catchError((error: unknown): Observable<never> => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 422) {
              const submitErrors: ResetNewPasswordErrorDTO | undefined = error
                .error?.errors as ResetNewPasswordErrorDTO;

              if (submitErrors) {
                return throwError(() =>
                  getResetNewPasswordErrors(submitErrors),
                );
              }
            }
          }

          return throwError((): UnknownError => new UnknownError());
        }),
      );
  }

  sendNewPasswordRequest(mail: string): Observable<void> {
    return this.#httpClient
      .post<void>('reset-password/init', {
        email: mail,
      })
      .pipe(
        catchError((error: unknown): Observable<never> => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 422) {
              const submitErrors: ResetPasswordInitErrorsDTO | undefined = error
                .error?.errors as ResetPasswordInitErrorsDTO;

              if (submitErrors) {
                return throwError(() =>
                  getPasswordResetInitErrors(submitErrors),
                );
              }
            }
          }

          return throwError((): UnknownError => new UnknownError());
        }),
      );
  }

  validateEmailActivateToken(token: string): Observable<void> {
    return this.#httpClient
      .post<void>('communication-channel/approve', {
        token: token,
      })
      .pipe(
        catchError((error: unknown): Observable<never> => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 422) {
              const errors:
                | ApproveCommunicationChannelTokenErrorDTO
                | undefined = error.error
                ?.errors as ApproveCommunicationChannelTokenErrorDTO;

              if (errors) {
                return throwError(() =>
                  getApproveCommunicationChannelTokenErrors(errors),
                );
              }
            }
          }

          return throwError(() => new UnknownError());
        }),
      );
  }

  resendEmailValidationLink(expiredToken: string): Observable<void> {
    return this.#httpClient
      .post<void>('communication-channel/resend-approval-token', {
        token: expiredToken,
      })
      .pipe(
        catchError((error): Observable<never> => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 422) {
              const errors:
                | ApproveCommunicationChannelTokenErrorDTO
                | undefined = error.error
                ?.errors as ApproveCommunicationChannelTokenErrorDTO;

              if (errors) {
                return throwError(() =>
                  getApproveCommunicationChannelTokenErrors(errors),
                );
              }
            }
          }

          return throwError(() => new UnknownError());
        }),
      );
  }

  validatePasswordResetToken(token: string): Observable<void> {
    return this.#httpClient
      .post<void>('reset-password/verify', {
        token: token,
      })
      .pipe(
        catchError((error): Observable<never> => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 422) {
              const errors:
                | ApproveCommunicationChannelTokenErrorDTO
                | undefined = error.error
                ?.errors as ApproveCommunicationChannelTokenErrorDTO;

              if (errors) {
                return throwError(
                  (): ApproveCommunicationChannelTokenError =>
                    getApproveCommunicationChannelTokenErrors(errors),
                );
              }
            }
          }

          return throwError((): UnknownError => new UnknownError());
        }),
      );
  }
}
