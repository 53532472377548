import { RxState } from '@rx-angular/state';

import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { NewPasswordErrors } from '@mbeon-pwa/domain';

import type { SetPasswordState as State } from './set-password.state.type';

@Injectable({
  providedIn: 'root',
})
export class SetPasswordState extends RxState<State> {
  readonly state$: Observable<State> = this.select();

  #newPasswordErrorsDefault: NewPasswordErrors = {
    currentPasswordIsNotAString: false,
    currentPasswordIsNotCorrect: false,
    passwordConfirmationDiffersFromPassword: false,
    passwordConfirmationIsNotAString: false,
    passwordContainsNoMandatorySpecialCharacters: false,
    passwordContainsSpacesOrTabs: false,
    passwordDoesNotContainANumber: false,
    passwordDoesNotContainLowercaseCharacters: false,
    passwordDoesNotContainUppercaseCharacters: false,
    passwordIsEmpty: false,
    passwordIsNotaString: false,
    passwordIsSameAsCurrentPassword: false,
    passwordIsSimilarToEmailOrUsername: false,
    passwordIsTooLong: false,
    passwordIsTooShort: false,
  };

  constructor() {
    super();

    this.set({
      success: false,
      newPasswordErrors: this.#newPasswordErrorsDefault,
    });
  }

  setPasswordHasBeenSent(success: boolean): void {
    this.set({
      success: success,
    });
  }

  setNewPasswordErrors(errors: NewPasswordErrors): void {
    this.set({
      newPasswordErrors: errors,
    });
  }

  reset(): void {
    this.set({
      newPasswordErrors: this.#newPasswordErrorsDefault,
      success: false,
    });
  }
}
