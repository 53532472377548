import { type Route } from '@angular/router';

import { provideTranslocoScope } from '@ngneat/transloco';

import { LanguageApplicationService } from '@mbeon-pwa/domain';

const loader = (fileName: string) => {
  const languageApplicationService: readonly string[] =
    LanguageApplicationService.supportedLanguages;

  return languageApplicationService.reduce((acc: object, lang: string) => {
    return {
      ...acc,
      [lang]: () => import(`../../../i18n/${lang}_${fileName}.json`),
    };
  }, {});
};

export const contentRoutes: readonly Route[] = [
  {
    path: 'about',
    loadComponent: () =>
      import('./pages/about/about-page.component').then(
        (m) => m.AboutComponent,
      ),
    providers: [
      provideTranslocoScope({
        scope: 'about',
        loader: loader('about'),
      }),
    ],
  },
  {
    path: 'data-privacy',
    loadComponent: () =>
      import('./pages/data-privacy/data-privacy-page.component').then(
        (m) => m.DataPrivacyComponent,
      ),
    providers: [
      provideTranslocoScope({
        scope: 'dataPrivacy',
        loader: loader('data_privacy'),
      }),
    ],
  },
  {
    path: 'imprint',
    loadComponent: () =>
      import('./pages/imprint/imprint-page.component').then(
        (m) => m.ImprintComponent,
      ),
    providers: [
      provideTranslocoScope({
        scope: 'imprint',
        loader: loader('imprint'),
      }),
    ],
  },
  {
    path: 'terms-of-use',
    loadComponent: () =>
      import('./pages/terms-of-use/terms-of-use-page.component').then(
        (m) => m.TermsOfUseComponent,
      ),
    providers: [
      provideTranslocoScope({
        scope: 'termsOfUse',
        loader: loader('terms_of_use'),
      }),
    ],
  },
  {
    path: 'maintenance',
    loadComponent: () =>
      import('./pages/maintenance/maintenance.component').then(
        (m) => m.MaintenanceComponent,
      ),
  },
  {
    path: 'non-specific-error',
    loadComponent: () =>
      import('./pages/non-specific-error/non-specific-error.component').then(
        (m) => m.NonSpecificErrorComponent,
      ),
  },
];
