import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { TranslocoDirective, TranslocoModule } from '@ngneat/transloco';
import {
  ApproveCommunicationChannelTokenError,
  RegistrationApplicationService,
} from '@mbeon-pwa/domain';
import { lastValueFrom, Observable } from 'rxjs';

import { RxLet } from '@rx-angular/template/let';

import { RegistrationState } from '../../states/registration/registration.state';
import { RegistrationState as State } from '../../states/registration/registration-state.type';

@Component({
  selector: 'mbeon-pwa-registration-link-expired',
  standalone: true,
  imports: [
    IonButton,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    TranslocoDirective,
    TranslocoModule,
    IonCol,
    IonGrid,
    IonRow,
    RxLet,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './registration-link-expired.component.html',
  styleUrls: ['./registration-link-expired.component.scss'],
})
export class RegistrationLinkExpiredComponent {
  readonly #router: Router = inject(Router);

  #registerState: RegistrationState = inject(RegistrationState);

  #registerStateData: State = this.#registerState.get();

  readonly #registrationApplicationService: RegistrationApplicationService =
    inject(RegistrationApplicationService);

  get state$(): Observable<State> {
    return this.#registerState.select();
  }

  async goToLogin(): Promise<void> {
    await this.#router.navigate(['login']);
    this.#registerState.reset();
  }

  async resendActivationLink() {
    if (this.#registerStateData.token) {
      try {
        await lastValueFrom(
          this.#registrationApplicationService.resendEmailValidationLink(
            this.#registerStateData.token,
          ),
        );

        this.#registerState.set({ accountDataSended: true });
        await this.#router.navigate(['register/email-link-sended']);
      } catch (error: unknown) {
        if (error instanceof ApproveCommunicationChannelTokenError) {
          await this.#handleTokenErrors(error);
        } else {
          await this.#router.navigate(['non-specific-error']);
        }
      }
    }
  }

  async #handleTokenErrors(tokenErrors: ApproveCommunicationChannelTokenError) {
    if (
      tokenErrors.tokenForCommunicationChannelApprovalBelongsToAlreadyApprovedCommunicationChannel
    ) {
      await this.#router.navigate(['login']);
    } else {
      await this.#router.navigate(['non-specific-error']);
    }
  }
}
