import { inject } from '@angular/core';
import {
  type ActivatedRouteSnapshot,
  type CanActivateFn,
  Router,
} from '@angular/router';

export const deleteAccountDeepLinkGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
) => {
  const router: Router = inject(Router);
  const tokenIncluded = !!route.queryParamMap.get('token');

  return tokenIncluded || router.parseUrl('login');
};
