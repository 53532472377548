import { compareDates } from '@mbeon-pwa/common';
import type {
  ConsultationMessage,
  ConsultationMessageAttachment,
} from '@mbeon-pwa/domain';

import type {
  ChatThreadItem,
  ChatThreadItemMessageAttachment,
} from '../../types/chat-thread-group.type';

export function groupConsultationMessages(
  consultationMessages: readonly ConsultationMessage[],
  username: string,
  lastReadTimestamp?: Date,
): readonly ChatThreadItem[] {
  if (consultationMessages.length === 0) {
    return [];
  }

  const chatThreadItems: ChatThreadItem[] = [];

  for (let index = 0; index < consultationMessages.length; index++) {
    const previousConsultationMessage: ConsultationMessage | undefined =
      index > 0 ? consultationMessages[index - 1] : undefined;

    const consultationMessage: ConsultationMessage =
      consultationMessages[index];

    if (index === 0) {
      // first indicator
      chatThreadItems.push({
        date: normalizeDate(consultationMessage.timestamp),
      });
    } else if (
      previousConsultationMessage &&
      compareDates(
        normalizeDate(consultationMessage.timestamp),
        normalizeDate(previousConsultationMessage.timestamp),
      ) === 1
    ) {
      chatThreadItems.push({
        date: normalizeDate(consultationMessage.timestamp),
      });
    }

    const attachments: ChatThreadItemMessageAttachment[] = [];
    let isVoiceMessage = false;
    for (const attachment of consultationMessage.attachments) {
      attachments.push({
        fileType: getFileType(attachment),
        ...attachment,
      });

      if (!isVoiceMessage && attachment.displayInline) {
        isVoiceMessage = true;
      }
    }

    chatThreadItems.push({
      message: {
        isMine: username === consultationMessage.poster,
        isFirstMessageByPoster:
          !previousConsultationMessage ||
          previousConsultationMessage.poster !== consultationMessage.poster,
        ...consultationMessage,
        attachments,
        message: isVoiceMessage ? undefined : consultationMessage.message,
        acknowledged:
          (lastReadTimestamp &&
            compareDates(lastReadTimestamp, consultationMessage.timestamp) ===
              1) ||
          false,
      },
    });
  }

  return chatThreadItems;
}

function getFileType(
  attachment: ConsultationMessageAttachment,
): ChatThreadItemMessageAttachment['fileType'] {
  if (attachment.mimeType?.startsWith('image/')) {
    return 'image';
  }

  if (attachment.mimeType?.startsWith('audio/')) {
    return 'voice-message';
  }

  return 'miscellaneous';
}

function normalizeDate(date: Date | null): Date | null {
  if (date === null) {
    return null;
  }

  // only take days, months and years into account, reset time-related data to 0
  const normalizedDate: Date = new Date(date);
  normalizedDate.setHours(0);
  normalizedDate.setMinutes(0);
  normalizedDate.setSeconds(0);
  normalizedDate.setMilliseconds(0);

  return normalizedDate;
}
