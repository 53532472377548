<ng-container *transloco="let t">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button (click)="goBack()">
          <ion-icon name="chevron-back-outline" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>{{ t('forgot-user-name-mail.header.title') }}</ion-title>
      <ion-buttons (click)="close()" slot="end">
        <ion-button>
          <ion-icon name="close" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content *rxLet="state$; let state" [fullscreen]="true">
    <ion-grid [fixed]="true" class="height-full">
      <ion-row class="ion-justify-content-center height-full">
        <ion-col>
          <div class="px-6 content-wrapper pt-6">
            <p class="mb-6 mt-0">
              {{ t('forgot-user-name-mail.description') }}
            </p>
            <form
              (ngSubmit)="submit()"
              [formGroup]="userNameForm"
              class="height-full"
            >
              <ion-list>
                <mbeon-pwa-input-form-field
                  [clearInput]="true"
                  [control]="userNameForm.controls.userMail"
                  [hasError]="
                    userNameForm.controls.userMail.errors?.['emailInvalid'] ||
                    !!state.sendError
                  "
                  [label]="t('input.email.label')"
                ></mbeon-pwa-input-form-field>
                <div class="input-error-message mb-2 mt-1 pl-4">
                  <p
                    *ngIf="
                      (!!userNameForm.controls.userMail.errors?.[
                        'emailInvalid'
                      ] &&
                        userNameForm.controls.userMail.value) ||
                      !!state.sendError
                    "
                  >
                    {{ t('input.email.invalid') }}
                  </p>
                </div>
              </ion-list>
              <ion-button
                [disabled]="userNameForm.invalid"
                class="button_primary mb-12 mt-2"
                type="submit"
                >{{ t('forgot-user-name-mail.button.send-username') }}
              </ion-button>
            </form>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</ng-container>
