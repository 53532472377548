import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'mbeon-pwa-password-reset-link-success',
  standalone: true,
  imports: [
    CommonModule,
    IonButton,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    TranslocoModule,
    IonCol,
    IonGrid,
    IonRow,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './password-reset-link-success.component.html',
  styleUrls: ['./password-reset-link-success.component.scss'],
})
export class PasswordResetLinkSuccessComponent {
  #router: Router = inject(Router);

  async close(): Promise<void> {
    await this.#router.navigate(['login']);
  }
}
