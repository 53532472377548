import { AccountDataErrors } from '@mbeon-pwa/domain';

import type { SubmitFormErrorsDTO } from '../../../../types/submit-form-errors-dto.error';

export function getSubmitFormErrors(
  responseErrors: SubmitFormErrorsDTO,
): AccountDataErrors {
  const emailErrors: boolean = (responseErrors.email ?? '').length > 0;
  const usernameErrors: boolean = (responseErrors.username ?? '').length > 0;
  const passwordErrors: boolean = (responseErrors.password ?? '').length > 0;

  return new AccountDataErrors({
    emailAddressContainsInvalidString:
      emailErrors &&
      responseErrors.email!.includes('EMAIL_ADDRESS_CONTAINS_INVALID_STRING'),
    emailAddressContainsNotAllowedCharacters:
      emailErrors &&
      responseErrors.email!.includes(
        'EMAIL_ADDRESS_CONTAINS_NOT_ALLOWED_CHARACTERS',
      ),
    emailAddressContainsSpacesOrTabs:
      emailErrors &&
      responseErrors.email!.includes('EMAIL_ADDRESS_CONTAINS_SPACES_OR_TABS'),
    emailAddressDnsCheckFailed:
      emailErrors &&
      responseErrors.email!.includes('EMAIL_ADDRESS_DNS_CHECK_FAILED'),
    emailAddressIsAlreadyInUse:
      emailErrors &&
      responseErrors.email!.includes('EMAIL_ADDRESS_IS_ALREADY_IN_USE'),
    emailAddressIsEmpty:
      emailErrors && responseErrors.email!.includes('EMAIL_ADDRESS_IS_EMPTY'),
    emailAddressIsNotAString:
      emailErrors &&
      responseErrors.email!.includes('EMAIL_ADDRESS_IS_NOT_A_STRING'),
    emailAddressIsNotRfcCompliant:
      emailErrors &&
      responseErrors.email!.includes('EMAIL_ADDRESS_IS_NOT_RFC_COMPLIANT'),
    emailAddressIsTooLong:
      emailErrors &&
      responseErrors.email!.includes('EMAIL_ADDRESS_IS_TOO_LONG'),
    emailAddressIsTooShort:
      emailErrors &&
      responseErrors.email!.includes('EMAIL_ADDRESS_IS_TOO_SHORT'),
    passwordContainsNoMandatorySpecialCharacters:
      passwordErrors &&
      responseErrors.password!.includes(
        'PASSWORD_CONTAINS_NO_MANDATORY_SPECIAL_CHARACTERS',
      ),
    passwordContainsSpacesOrTabs:
      passwordErrors &&
      responseErrors.password!.includes('PASSWORD_CONTAINS_SPACES_OR_TABS'),
    passwordDoesNotContainANumber:
      passwordErrors &&
      responseErrors.password!.includes('PASSWORD_DOES_NOT_CONTAIN_A_NUMBER'),
    passwordDoesNotContainLowercaseCharacters:
      passwordErrors &&
      responseErrors.password!.includes(
        'PASSWORD_DOES_NOT_CONTAIN_LOWERCASE_CHARACTERS',
      ),
    passwordDoesNotContainUppercaseCharacters:
      passwordErrors &&
      responseErrors.password!.includes(
        'PASSWORD_DOES_NOT_CONTAIN_UPPERCASE_CHARACTERS',
      ),
    passwordIsEmpty:
      passwordErrors && responseErrors.password!.includes('PASSWORD_IS_EMPTY'),
    passwordIsNotaString:
      passwordErrors &&
      responseErrors.password!.includes('PASSWORD_IS_NOT_A_STRING'),
    passwordIsSameAsCurrentPassword:
      passwordErrors &&
      responseErrors.password!.includes('PASSWORD_IS_SAME_AS_CURRENT_PASSWORD'),
    passwordIsSimilarToEmailOrUsername:
      passwordErrors &&
      responseErrors.password!.includes(
        'PASSWORD_IS_SIMILAR_TO_EMAIL_OR_USERNAME',
      ),
    passwordIsTooLong:
      passwordErrors &&
      responseErrors.password!.includes('PASSWORD_IS_TOO_LONG'),
    passwordIsTooShort:
      passwordErrors &&
      responseErrors.password!.includes('PASSWORD_IS_TOO_SHORT'),
    usernameContainsNotAllowedCharacters:
      usernameErrors &&
      responseErrors.username!.includes(
        'USERNAME_CONTAINS_NOT_ALLOWED_CHARACTERS',
      ),
    usernameContainsSpacesOrTabs:
      usernameErrors &&
      responseErrors.username!.includes('USERNAME_CONTAINS_SPACES_OR_TABS'),
    usernameIsAlreadyInUse:
      usernameErrors &&
      responseErrors.username!.includes('USERNAME_IS_ALREADY_IN_USE'),
    usernameIsEmpty:
      usernameErrors && responseErrors.username!.includes('USERNAME_IS_EMPTY'),
    usernameIsNotAString:
      usernameErrors &&
      responseErrors.username!.includes('USERNAME_IS_NOT_A_STRING'),
    usernameIsSimilarToEmail:
      usernameErrors &&
      responseErrors.username!.includes('USERNAME_IS_SIMILAR_TO_EMAIL'),
    usernameIsTooLong:
      usernameErrors &&
      responseErrors.username!.includes('USERNAME_IS_TOO_LONG'),
    usernameIsTooShort:
      usernameErrors &&
      responseErrors.username!.includes('USERNAME_IS_TOO_SHORT'),
  });
}
