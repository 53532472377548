import { inject } from '@angular/core';
import { type CanActivateFn, Router, type UrlTree } from '@angular/router';

import { first, map } from 'rxjs';

import { DeleteAccountState } from '../../states/delete-account/delete-account.state';
import type { DeleteAccountState as State } from '../../states/delete-account/delete-account.state.type';

export const deleteAccountLinkHasBeenSentGuard: CanActivateFn = () => {
  const deleteAccountState: DeleteAccountState = inject(DeleteAccountState);
  const router: Router = inject(Router);

  return deleteAccountState.select().pipe(
    first(),
    map((deleteAccountState: State): true | UrlTree => {
      return deleteAccountState.linkHasBeenSent || router.parseUrl('login');
    }),
  );
};
