import { NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { Router } from '@angular/router';

import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonList,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

import {
  AccountDataErrors,
  RegistrationApplicationService,
} from '@mbeon-pwa/domain';

import { TranslocoModule } from '@ngneat/transloco';

import { lastValueFrom, Observable } from 'rxjs';

import { RxLet } from '@rx-angular/template/let';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { RxState } from '@rx-angular/state';

import { RxIf } from '@rx-angular/template/if';

import { InputFormFieldComponent } from '../../../../common/components/inputs/input-form-field/input-form-field.component';
import { RegistrationState } from '../../states/registration/registration.state';
import { passwordRepeatValidator } from '../../validators/password-repeat/password-repeat.validator';
import { PasswordValidatorComponent } from '../../../../common/components/password-validators/password-validator.component';
import { RegistrationState as State } from '../../states/registration/registration-state.type';
import { emailInPasswordValidator } from '../../validators/email-in-password/email-in-password.validator';
import { usernameInPasswordValidator } from '../../validators/username-in-password/username-in-password.validator';

interface RegistrationPasswordDataComponentState {
  readonly showLoadingIndicator: boolean;
}

@UntilDestroy()
@Component({
  selector: 'mbeon-pwa-password-data',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    InputFormFieldComponent,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonList,
    IonTitle,
    IonToolbar,
    NgIf,
    NgTemplateOutlet,
    ReactiveFormsModule,
    TranslocoModule,
    PasswordValidatorComponent,
    RxLet,
    IonCol,
    IonGrid,
    IonRow,
    IonSpinner,
    RxIf,
  ],
  templateUrl: './registration-password-data-page.component.html',
  styleUrls: ['./registration-password-data-page.component.scss'],
  viewProviders: [
    {
      provide: RxState,
      useFactory: (): RxState<RegistrationPasswordDataComponentState> =>
        new RxState(),
    },
  ],
})
export class RegistrationPasswordDataComponent implements OnInit {
  readonly passwordForm: FormGroup<{
    readonly password: FormControl<string | null>;
    readonly confirmPassword: FormControl<string | null>;
  }>;

  #registrationState: RegistrationState = inject(RegistrationState);

  #registerApplicationService: RegistrationApplicationService = inject(
    RegistrationApplicationService,
  );

  #router: Router = inject(Router);

  #registerStateData: State = this.#registrationState.get();

  readonly #emailValue: FormControl;

  readonly #userNameValue: FormControl;

  readonly #registrationPasswordDataComponentState: RxState<RegistrationPasswordDataComponentState> =
    inject<RxState<RegistrationPasswordDataComponentState>>(RxState);

  constructor() {
    this.passwordForm = new FormGroup({
      password: new FormControl(this.#registerStateData?.password ?? ''),
      confirmPassword: new FormControl(
        this.#registerStateData?.confirmPassword ?? '',
      ),
    });

    this.#emailValue = new FormControl(this.#registerStateData?.email ?? '');

    this.#userNameValue = new FormControl(
      this.#registerStateData?.username ?? '',
    );

    this.passwordForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.#registrationState.setResetPasswordDataErrors();
    });

    this.#registrationPasswordDataComponentState.set({
      showLoadingIndicator: false,
    });
  }

  get state$(): Observable<State> {
    return this.#registrationState.select();
  }

  get componentState$(): Observable<RegistrationPasswordDataComponentState> {
    return this.#registrationPasswordDataComponentState.select();
  }

  ngOnInit(): void {
    this.passwordForm.setValidators([
      passwordRepeatValidator(),
      Validators.required,
    ]);

    this.passwordForm.controls.password.addValidators([
      usernameInPasswordValidator(this.#userNameValue),
      emailInPasswordValidator(this.#emailValue),
      Validators.required,
    ]);
  }

  async cancel(): Promise<void> {
    this.#registrationState.reset();
    await this.#router.navigate(['login']);
  }

  async goBack(): Promise<void> {
    this.#registrationState.setPasswordData(
      this.passwordForm.value.password
        ? this.passwordForm.value.password
        : null,
      this.passwordForm.value.confirmPassword
        ? this.passwordForm.value.confirmPassword
        : null,
    );

    await this.#router.navigate(['register/account-data']);
  }

  async submit() {
    this.#registerStateData = this.#registrationState.get();

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    if (this.passwordForm.valid) {
      this.#registrationPasswordDataComponentState.set({
        showLoadingIndicator: true,
      });

      this.#registrationState.setPasswordData(
        this.passwordForm.value.password!,
        this.passwordForm.value.confirmPassword!,
      );

      try {
        await lastValueFrom(
          this.#registerApplicationService.register({
            email: this.#registerStateData.email!,
            password: this.passwordForm.value.password!,
            passwordConfirmation: this.passwordForm.value.confirmPassword!,
            username: this.#registerStateData.username!,
            termsOfUse: this.#registerStateData.termsOfUse!,
            privacy: this.#registerStateData.privacy!,
          }),
        );

        this.#registrationState.reset();
        this.#registrationState.setAccountDataSended(true);

        await this.#router.navigate(['register/email-link-sended']);
      } catch (error: unknown) {
        if (error instanceof AccountDataErrors) {
          const submitErrors: AccountDataErrors = error;

          const usernameInvalid: boolean =
            submitErrors.usernameIsNotAString ||
            submitErrors.usernameContainsNotAllowedCharacters ||
            submitErrors.usernameIsEmpty ||
            submitErrors.usernameIsSimilarToEmail ||
            submitErrors.usernameContainsSpacesOrTabs ||
            submitErrors.usernameIsTooLong ||
            submitErrors.usernameIsTooShort;

          const emailInvalid: boolean =
            submitErrors.emailAddressContainsInvalidString ||
            submitErrors.emailAddressIsEmpty ||
            submitErrors.emailAddressContainsSpacesOrTabs ||
            submitErrors.emailAddressIsTooLong ||
            submitErrors.emailAddressIsTooShort ||
            submitErrors.emailAddressContainsNotAllowedCharacters ||
            submitErrors.emailAddressDnsCheckFailed ||
            submitErrors.emailAddressIsNotRfcCompliant;

          this.#registrationState.setAccountDataError(submitErrors);

          if (
            usernameInvalid ||
            submitErrors.usernameIsAlreadyInUse ||
            emailInvalid ||
            submitErrors.emailAddressIsAlreadyInUse
          ) {
            await this.#router.navigate(['register/account-data']);
          }
        } else {
          this.#registrationState.reset();
          await this.#router.navigate(['non-specific-error']);
        }

        this.#registrationState.setAccountDataSended(false);
      } finally {
        this.#registrationPasswordDataComponentState.set({
          showLoadingIndicator: false,
        });
      }

      /* eslint-enable */
    }
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }
}
