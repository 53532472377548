import {
  type AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  InjectionToken,
  Output,
} from '@angular/core';

import { IonSpinner } from '@ionic/angular/standalone';

import { PLATFORM_IDENTIFIER, PlatformIdentifier } from '@mbeon-pwa/common';

import { RxState } from '@rx-angular/state';
import { RxIf } from '@rx-angular/template/if';
import { RxLet } from '@rx-angular/template/let';

import {
  debounceTime,
  defer,
  exhaustMap,
  filter,
  fromEvent,
  map,
  type Observable,
  tap,
} from 'rxjs';

import { ConsultationMessagesViewService } from '../../service/consultation-messages-view.service';

import type { ConsultationMessagesInfiniteLoaderState } from './consultation-messages-infinite-loader.state';

export const SCROLL_CONTAINER: InjectionToken<ElementRef> = new InjectionToken(
  'SCROLL_CONTAINER',
);

@Component({
  selector: 'mbeon-pwa-consultation-messages-infinite-loader',
  standalone: true,
  templateUrl: './consultation-messages-infinite-loader.component.html',
  styleUrl: './consultation-messages-infinite-loader.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [RxState],
  imports: [IonSpinner, RxIf, RxLet],
})
export class ConsultationMessagesInfiniteLoaderComponent
  implements AfterViewInit
{
  @Output()
  readonly isLoading: EventEmitter<boolean> = new EventEmitter();

  readonly state$: Observable<ConsultationMessagesInfiniteLoaderState> = defer(
    (): Observable<ConsultationMessagesInfiniteLoaderState> =>
      this.#state.select(),
  );

  readonly #consultationMessagesViewService: ConsultationMessagesViewService =
    inject(ConsultationMessagesViewService);

  readonly #hostElement: ElementRef<HTMLElement> = inject(SCROLL_CONTAINER, {
    skipSelf: true,
    host: true,
  });

  readonly #platformIdentifier: PlatformIdentifier =
    inject(PLATFORM_IDENTIFIER);

  readonly #state: RxState<ConsultationMessagesInfiniteLoaderState> =
    inject<RxState<ConsultationMessagesInfiniteLoaderState>>(RxState);

  constructor() {
    this.#state.set({
      isLoading: false,
    });
  }

  ngAfterViewInit(): void {
    if (this.#platformIdentifier.isBrowser) {
      this.#state.connect(
        fromEvent(this.#hostElement.nativeElement, 'scroll').pipe(
          debounceTime(150),
          filter(
            (): boolean =>
              !this.#state.get().isLoading &&
              this.#consultationMessagesViewService.canLoadMore() &&
              this.#hostElement.nativeElement.scrollTop < 25,
          ),
          tap({
            next: (): void => {
              this.#state.set({
                isLoading: true,
                scrollPositionAnchor:
                  this.#hostElement.nativeElement.scrollHeight,
              });

              this.isLoading.next(true);
            },
          }),
          exhaustMap(
            (): Observable<void> =>
              this.#consultationMessagesViewService.loadPrevious(),
          ),
          debounceTime(200),
          tap({
            next: (): void => {
              // scroll back to previous position
              this.#hostElement.nativeElement.scrollTo({
                top:
                  this.#hostElement.nativeElement.scrollHeight -
                  (this.#state.get().scrollPositionAnchor ?? 0),
              });

              this.isLoading.next(false);
            },
          }),
          map(
            (): Partial<ConsultationMessagesInfiniteLoaderState> => ({
              isLoading: false,
              scrollPositionAnchor: undefined,
            }),
          ),
        ),
      );
    }
  }
}
