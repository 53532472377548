import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

import { TranslocoModule } from '@ngneat/transloco';

import { RegistrationState } from '../../states/registration/registration.state';

@Component({
  selector: 'mbeon-pwa-registration-success',
  standalone: true,
  imports: [
    CommonModule,
    IonButton,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    TranslocoModule,
    IonCol,
    IonGrid,
    IonRow,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './registration-email-link-sended.component.html',
  styleUrls: ['./registration-email-link-sended.component.scss'],
})
export class RegistrationEmailLinkSendedComponent {
  #registrationState: RegistrationState = inject(RegistrationState);

  readonly #router: Router = inject(Router);

  async close(): Promise<void> {
    this.#registrationState.reset();
    await this.#router.navigate(['login']);
  }
}
