import { inject, Pipe, PipeTransform } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'chatThreadMessageGroupDate',
  standalone: true,
  pure: false,
})
export class ChatThreadMessageGroupDatePipe implements PipeTransform {
  readonly #nowDate: Date = new Date();

  readonly #translocoService: TranslocoService = inject(TranslocoService);

  constructor() {
    this.#nowDate.setHours(0);
    this.#nowDate.setMinutes(0);
    this.#nowDate.setSeconds(0);
    this.#nowDate.setMilliseconds(0);
  }

  transform(date: Date): string | null {
    const comparisonDate: Date = new Date(date);
    comparisonDate.setHours(0);
    comparisonDate.setMinutes(0);
    comparisonDate.setSeconds(0);
    comparisonDate.setMilliseconds(0);

    const dateDifference: number =
      (comparisonDate.getTime() - this.#nowDate.getTime()) / 86400000;

    if (dateDifference === 0) {
      return this.#translocoService.translate('chat-thread.message.date.today');
    } else if (dateDifference === -1) {
      return this.#translocoService.translate(
        'chat-thread.message.date.yesterday',
      );
    }

    return `${('0' + comparisonDate.getDate()).slice(-2)}.${('0' + (comparisonDate.getMonth() + 1)).slice(-2)}.${comparisonDate.getFullYear()}`;
  }
}
