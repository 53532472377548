import { Injectable } from '@angular/core';

import { RxState } from '@rx-angular/state';

import { ForgotProfileNameErrors } from '@mbeon-pwa/domain';

import type { UsernameResetState as State } from './username-reset.state.type';

@Injectable({
  providedIn: 'root',
})
export class UsernameResetState extends RxState<State> {
  constructor() {
    super();

    this.reset();
  }

  setSuccess(success: boolean) {
    this.set({ sendSuccess: success });
  }

  setError(error: ForgotProfileNameErrors) {
    this.set({ sendError: error });
  }

  reset() {
    this.set({
      sendSuccess: false,
      sendError: undefined,
    });
  }
}
