<ng-container *transloco="let t">
  <ng-container *rxLet="state$; let state">
    <ion-button
      class="record-button"
      color="primary"
      fill="solid"
      shape="round"
      size="small"
      [disabled]="state.isDisabled"
      (click)="recordVoiceMessage()"
    >
      <ion-icon color="light" name="mic-outline" slot="icon-only"></ion-icon>
    </ion-button>

    <ion-modal [backdropDismiss]="false" [isOpen]="state.displayRecordingModal">
      <ng-template>
        <div class="recorder">
          <div class="audio-recorder">
            <div
              class="microphone-indicator"
              [class.state-is-recording]="state.isRecording"
            >
              <ion-icon name="mic-outline"></ion-icon>
            </div>

            <div class="recording-studio">
              <span
                >{{
                  t('chat-thread.message.voice-message.recording-time')
                }}:&nbsp;</span
              >
              <span class="font-bold">{{
                state.recordingTime * 1000 | date: 'mm:ss'
              }}</span>
            </div>
          </div>

          <ion-button
            [color]="state.isRecording ? 'danger' : 'primary'"
            expand="block"
            fill="clear"
            (click)="toggleRecording(!state.isRecording)"
          >
            <ng-container *rxIf="state.isRecording">
              <ion-icon name="pause-circle-outline"></ion-icon>
              <span>{{
                t('chat-thread.message.voice-message.pause-recording')
              }}</span>
            </ng-container>

            <ng-container *rxIf="!state.isRecording">
              <ion-icon name="play-circle-outline"></ion-icon>
              <span>{{
                t('chat-thread.message.voice-message.continue-recording')
              }}</span>
            </ng-container>
          </ion-button>

          <div class="buttons">
            <ion-button
              size="small"
              [disabled]="state.isRecording || state.recordingTime < 1"
              (click)="sendRecording()"
              ><span>{{ t('chat-thread.message.voice-message.send') }}</span>
            </ion-button>
            <ion-button
              color="danger"
              fill="clear"
              size="small"
              (click)="abortRecording()"
            >
              <span>{{ t('chat-thread.message.voice-message.cancel') }}</span>
            </ion-button>
          </div>
        </div>
      </ng-template>
    </ion-modal>
  </ng-container>
</ng-container>
