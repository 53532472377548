import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { IonApp, IonRouterOutlet, IonToast } from '@ionic/angular/standalone';

import {
  LanguageApplicationService,
  languageChanged,
  ON_DOMAIN_EVENT,
  type OnDomainEvent,
  type SupportedLanguage,
} from '@mbeon-pwa/domain';

import { RxPush } from '@rx-angular/template/push';

import {
  defer,
  map,
  merge,
  type Observable,
  shareReplay,
  switchMap,
} from 'rxjs';

import { RxLet } from '@rx-angular/template/let';

import { StartupMessageComponent } from './core/components/startup-message/startup-message.component';

@Component({
  standalone: true,
  imports: [
    IonApp,
    IonRouterOutlet,
    IonToast,
    RxLet,
    RxPush,
    StartupMessageComponent,
  ],
  selector: 'mbeon-pwa-root',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  readonly isRtlLang$: Observable<boolean> = defer(
    (): Observable<boolean> =>
      merge(
        this.#languageApplicationService.getDefaultLanguage(),
        this.#onDomainEvent(languageChanged).pipe(
          switchMap((): Observable<SupportedLanguage> => {
            return this.#languageApplicationService.getDefaultLanguage();
          }),
        ),
      ).pipe(
        map((lang: SupportedLanguage): boolean => {
          return this.#languageApplicationService.isRtlLanguage(lang);
        }),
        shareReplay(1),
      ),
  );

  readonly #languageApplicationService: LanguageApplicationService = inject(
    LanguageApplicationService,
  );

  readonly #onDomainEvent: OnDomainEvent = inject(ON_DOMAIN_EVENT);
}
