<ion-header *transloco="let t">
  <ion-toolbar>
    <ion-title>{{ t('register.success.header.title') }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *transloco="let t" [fullscreen]="true">
  <ion-grid [fixed]="true">
    <ion-row class="ion-justify-content-center">
      <ion-col>
        <div class="p-6 container">
          <div>
            <h2 class="mt-0 font-semibold">
              {{ t('register.success.title') }}
            </h2>
            <p class="mt-0">{{ t('register.success.description') }}</p>
          </div>
          <div>
            <ion-button (click)="goToLogin()" class="button_primary mb-8">
              {{ t('register.success.button') }}
            </ion-button>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
