<ng-container *rxLet="state$; let state">
  <ion-button shape="round" size="small" (click)="togglePlayState()">
    <ion-icon
      slot="icon-only"
      [name]="!state.isPlaying ? 'play-circle-outline' : 'pause-circle-outline'"
    ></ion-icon>
  </ion-button>

  <ion-progress-bar
    color="primary"
    [value]="state.ready ? state.currentTime / state.duration : 0"
  ></ion-progress-bar>

  <div class="info">
    <!-- To use the date pipe for formatting seconds we convert seconds to milliseconds -->
    <ion-text color="primary">{{
      state.remainingTime * 1000 | date: 'm:ss'
    }}</ion-text>
  </div>
</ng-container>
