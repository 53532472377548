import { first, map, Observable } from 'rxjs';
import { inject } from '@angular/core';

import { Router, UrlTree } from '@angular/router';

import { SetPasswordState } from '../../states/set-password/set-password.state';

export const setPasswordGuard: () => Observable<
  true | UrlTree
> = (): Observable<true | UrlTree> => {
  const setPasswordState: SetPasswordState = inject(SetPasswordState);
  const router: Router = inject(Router);

  return setPasswordState.select('success').pipe(
    first(),
    map(
      (success: boolean | undefined): true | UrlTree =>
        success || router.parseUrl('app/set-password'),
    ),
  );
};
