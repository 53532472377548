<ng-container *transloco="let t">
  <ng-container
    [ngTemplateOutletContext]="{
      value: control.value,
      condition: control.errors?.['minLength'],
      message: t('input-validator.password.length')
    }"
    [ngTemplateOutlet]="passwordIndicator"
  ></ng-container>

  <ng-container
    [ngTemplateOutletContext]="{
      value: control.value,
      condition:
        control.errors?.['smallLetters'] && control.errors?.['largeLetters'],
      message: t('input-validator.password.small-large-letters')
    }"
    [ngTemplateOutlet]="passwordIndicator"
  ></ng-container>

  <ng-container
    [ngTemplateOutletContext]="{
      value: control.value,
      condition: control.errors?.['number'],
      message: t('input-validator.password.numbers')
    }"
    [ngTemplateOutlet]="passwordIndicator"
  ></ng-container>

  <ng-container
    [ngTemplateOutletContext]="{
      value: control.value,
      condition: control.errors?.['special'],
      message: t('input-validator.password.valid-characters')
    }"
    [ngTemplateOutlet]="passwordIndicator"
  ></ng-container>
</ng-container>

<ng-template
  #passwordIndicator
  let-condition="condition"
  let-message="message"
  let-touched="touched"
  let-value="value"
>
  <div class="password-validator-message pb-4">
    <div>
      <ion-icon
        *ngIf="value === '' || value === null"
        class="untouched"
        name="ellipse"
      ></ion-icon>
      <ion-icon
        *ngIf="condition === false && value.length > 0"
        class="error"
        name="close-circle"
      ></ion-icon>
      <ion-icon
        *ngIf="
          (condition && value.length > 0) ||
          ((condition === undefined || condition === null) && value.length > 0)
        "
        class="success"
        name="checkmark-circle"
      ></ion-icon>
    </div>
    <p>{{ message }}</p>
  </div>
</ng-template>
