import { inject, InjectionToken, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

export const NAVIGATOR: InjectionToken<Navigator | undefined> =
  new InjectionToken('NAVIGATOR', {
    providedIn: 'platform',
    factory(): Navigator | void {
      const platformId: object = inject(PLATFORM_ID);

      if (isPlatformBrowser(platformId)) {
        return window.navigator;
      }
    },
  });
