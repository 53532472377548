import { type Builder, Strophe } from 'strophe.js';

import type { StropheJS } from '../../types/strophe-js.type';

/**
 * example output
 *
 * <iq to="$from" type="set" xmlns="jabber:client">
 *     <query xmlns="urn:xmpp:mam:2">
 *         <x type="submit" xmlns="jabber:x:data">
 *             <field type="hidden" var="FORM_TYPE">
 *                 <value>urn:xmpp:mam:2</value>
 *             </field>
 *             <field var="with">
 *                 <value>$to</value>
 *             </field>
 *         </x>
 *         <set xmlns="http://jabber.org/protocol/rsm">
 *             <max>$filter?.max</max>
 *             <before></before>
 *         </set>
 *     </query>
 * </iq>
 */
export function generateMAMIq(
  stropheJS: StropheJS,
  from: string,
  to: string,
  filter?: {
    readonly start?: Date;
    readonly end?: Date;
    readonly order?: 'asc' | 'desc';
    readonly max?: number;
  },
): Element {
  const receiverJid: string = stropheJS.Strophe.getBareJidFromJid(from);
  const recipientJid: string = stropheJS.Strophe.getBareJidFromJid(to);

  const iq: Builder = stropheJS
    .$iq({
      type: 'set',
      to: receiverJid,
    })
    .c('query', {
      xmlns: Strophe.NS['MAM'],
    })
    .c('x', {
      xmlns: 'jabber:x:data',
      type: 'submit',
    });

  iq.c('field', {
    var: 'FORM_TYPE',
    type: 'hidden',
  })
    .c('value')
    .t(Strophe.NS['MAM'])
    .up()
    .up();

  iq.c('field', {
    var: 'with',
  })
    .c('value')
    .t(recipientJid)
    .up()
    .up();

  if (filter?.start) {
    iq.c('field', {
      var: 'start',
    })
      .c('value')
      .t(filter.start.toISOString())
      .up()
      .up();
  }

  if (filter?.end) {
    iq.c('field', {
      var: 'end',
    })
      .c('value')
      .t(filter.end.toISOString())
      .up()
      .up();
  }

  iq.up();

  iq.c('set', {
    xmlns: Strophe.NS['RSM'],
  });

  if (typeof filter?.max === 'number' && filter.max >= 0) {
    iq.c('max', filter.max.toString(10)).up();
  }

  if (!filter?.order || filter.order === 'desc') {
    iq.c('before', '').up();
  }

  iq.up();

  return iq.tree();
}
