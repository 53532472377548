import {
  type AbstractControl,
  type ValidationErrors,
  type ValidatorFn,
} from '@angular/forms';

export function usernameInEmailValidator(
  sourceControl: AbstractControl<unknown>,
): ValidatorFn {
  return (control: AbstractControl<unknown>): ValidationErrors | null => {
    const emailValue: string = sourceControl.value as string;
    const usernameValue: string = control.value as string;

    if (
      emailValue &&
      usernameValue &&
      checkUsernameInEmail(emailValue, usernameValue)
    ) {
      return {
        usernameInEmail: true,
      };
    }

    return null;
  };
}

function checkUsernameInEmail(
  emailValue: string,
  usernameValue: string,
): boolean {
  const [localPart] = emailValue.split('@');
  return localPart.toLowerCase() === usernameValue.toLowerCase();
}
