import { inject } from '@angular/core';
import {
  HttpErrorResponse,
  type HttpEvent,
  type HttpHandlerFn,
  type HttpInterceptorFn,
  type HttpRequest,
} from '@angular/common/http';

import {
  catchError,
  exhaustMap,
  finalize,
  Observable,
  share,
  throwError,
} from 'rxjs';

import {
  AuthenticationRepository,
  AuthenticationTokens,
  UnableToProlongAuthentication,
  UnauthorizedAccess,
  UserRepository,
} from '@mbeon-pwa/domain';

import { AuthenticationConfig } from '../../types/authentication-config.type';
import { extendSessionHttpToken } from '../../values/extend-session.http-token';

export const authenticationRequestHttpInterceptor: HttpInterceptorFn = (
  httpRequest: HttpRequest<unknown>,
  httpHandler: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const authenticationConfig: AuthenticationConfig =
    inject(AuthenticationConfig);

  const authenticationRepository: AuthenticationRepository = inject(
    AuthenticationRepository,
  );
  const userRepository: UserRepository = inject(UserRepository);

  //relative urls
  let request: HttpRequest<unknown>;
  if (!/^[^:]+:\/\//.test(httpRequest.url)) {
    request = httpRequest.clone({
      url: `${authenticationConfig.baseUrl}/${httpRequest.url}`,
    });
  } else {
    request = httpRequest;
  }

  // do not apply error handling for extend session requests -> would result in a loop if failed
  if (request.context.get(extendSessionHttpToken)) {
    return httpHandler(request).pipe(
      catchError((error: unknown): Observable<HttpEvent<unknown>> => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return throwError((): UnauthorizedAccess => new UnauthorizedAccess());
        }

        return throwError((): unknown => error);
      }),
    );
  }

  return httpHandler(request).pipe(
    checkUnauthorized((): Observable<HttpEvent<unknown>> => {
      return refresh(authenticationRepository, userRepository).pipe(
        exhaustMap((): Observable<HttpEvent<unknown>> => httpHandler(request)),
        checkUnauthorized(),
      );
    }),
  );
};

function checkUnauthorized(
  interceptor?: () => Observable<HttpEvent<unknown>>,
): (source$: Observable<HttpEvent<unknown>>) => Observable<HttpEvent<unknown>> {
  return (
    source$: Observable<HttpEvent<unknown>>,
  ): Observable<HttpEvent<unknown>> => {
    return source$.pipe(
      catchError((error: unknown): Observable<HttpEvent<unknown>> => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          const urlPath: string = new URL(error.url!).pathname;

          // have to check the url for login error handling
          // the login don't have go to refresh if a 401 appears
          if (typeof interceptor === 'function' && urlPath !== '/login_check') {
            return interceptor();
          }

          return throwError((): UnauthorizedAccess => new UnauthorizedAccess());
        }

        return throwError((): unknown => error);
      }),
    );
  };
}

let pendingTokenRequest$: Observable<void> | undefined;

function refresh(
  authenticationRepository: AuthenticationRepository,
  userRepository: UserRepository,
): Observable<void> {
  if (!(pendingTokenRequest$ instanceof Observable)) {
    pendingTokenRequest$ = userRepository.extendSession().pipe(
      exhaustMap(
        (authenticationTokens: AuthenticationTokens): Observable<void> =>
          authenticationRepository.saveAuthenticationTokens(
            authenticationTokens,
          ),
      ),
      catchError(
        (): Observable<never> =>
          authenticationRepository
            .deleteAuthenticationTokens()
            .pipe(
              exhaustMap(
                (): Observable<never> =>
                  throwError(
                    (): UnableToProlongAuthentication =>
                      new UnableToProlongAuthentication(),
                  ),
              ),
            ),
      ),
      finalize(() => {
        pendingTokenRequest$ = undefined;
      }),
      share(),
    );
  }

  return pendingTokenRequest$;
}
