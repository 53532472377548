import type { Message, MessageFile } from '../../types/message.type';
import type { StropheJS } from '../../types/strophe-js.type';

export function transformChatMessage(
  stropheJS: StropheJS,
  stanza: Element,
): Message {
  const messageElement: Element = stanza.querySelector('message') ?? stanza;

  const id: string = messageElement.getAttribute('id') ?? '';
  const from: string = stropheJS.Strophe.getBareJidFromJid(
    messageElement.getAttribute('from') ?? '',
  );
  const to: string = stropheJS.Strophe.getBareJidFromJid(
    messageElement.getAttribute('to') ?? '',
  );

  let body: string | undefined;
  const bodyElement: Element | null = messageElement.querySelector('body');
  if (bodyElement) {
    body = stropheJS.Strophe.getText(bodyElement);
  }

  const forwardedElement: Element | null = stanza.querySelector('forwarded');
  const isArchived = !!forwardedElement;

  const delayElement: Element | null = stanza.querySelector('delay');
  const stamp: string | null = delayElement?.getAttribute('stamp') ?? null;
  const timestamp: Date = stamp ? new Date(stamp) : new Date();

  const files: MessageFile[] = [];
  const fileSharingElements: NodeListOf<Element> =
    stanza.querySelectorAll('file-sharing');
  if (fileSharingElements.length > 0) {
    for (let index = 0; index < fileSharingElements.length; index++) {
      const fileSharingElement: Element = fileSharingElements.item(index);

      const disposition: 'attachment' | 'inline' =
        fileSharingElement.getAttribute('disposition') as
          | 'attachment'
          | 'inline';

      const fileElement: Element | null =
        fileSharingElement.querySelector('file');

      const descriptionElement: Element | null =
        fileElement?.querySelector('desc') ?? null;
      const description: string | undefined = descriptionElement
        ? stropheJS.Strophe.getText(descriptionElement)
        : undefined;

      const dimensionsElement: Element | null =
        fileElement?.querySelector('dimensions') ?? null;
      const dimensions: string | undefined = dimensionsElement
        ? stropheJS.Strophe.getText(dimensionsElement)
        : undefined;

      const mediaTypeElement: Element | null =
        fileElement?.querySelector('media-type') ?? null;
      const mediaType: string | undefined = mediaTypeElement
        ? stropheJS.Strophe.getText(mediaTypeElement)
        : undefined;

      const nameElement: Element | null =
        fileElement?.querySelector('name') ?? null;
      const name: string | undefined = nameElement
        ? stropheJS.Strophe.getText(nameElement)
        : undefined;

      const sizeElement: Element | null =
        fileElement?.querySelector('size') ?? null;
      const size: number | undefined = sizeElement
        ? parseInt(stropheJS.Strophe.getText(sizeElement), 10)
        : undefined;

      const urlDataElement: Element | null =
        fileSharingElement
          .querySelector('sources')
          ?.querySelector('url-data') ?? null;
      const source: string | undefined =
        urlDataElement?.getAttribute('target') ?? undefined;

      files.push({
        disposition,
        description,
        dimensions,
        mediaType,
        name,
        size,
        source,
      });
    }
  }

  return {
    id,
    from,
    to,
    body,
    timestamp,
    files,
    isArchived,
  } satisfies Message;
}
