import { NewPasswordErrors } from '@mbeon-pwa/domain';

import { NewPasswordErrorDto } from '../../../../entities/new-password-error-dto.type';

export function getNewPasswordErrors(
  responseErrors: NewPasswordErrorDto,
): NewPasswordErrors {
  const currentPassword: boolean =
    (responseErrors?.current_password ?? []).length > 0;

  const passwordConfirmation: boolean =
    (responseErrors?.password_confirmation ?? []).length > 0;

  const passwordErrors: boolean = (responseErrors?.password ?? []).length > 0;

  return new NewPasswordErrors({
    currentPasswordIsNotAString:
      currentPassword &&
      responseErrors.current_password.includes(
        'CURRENT_PASSWORD_IS_NOT_A_STRING',
      ),
    currentPasswordIsNotCorrect:
      currentPassword &&
      responseErrors.current_password.includes(
        'CURRENT_PASSWORD_IS_NOT_CORRECT',
      ),
    passwordConfirmationDiffersFromPassword:
      passwordConfirmation &&
      responseErrors.password_confirmation.includes(
        'PASSWORD_CONFIRMATION_DIFFERS_FROM_PASSWORD',
      ),
    passwordConfirmationIsNotAString:
      passwordConfirmation &&
      responseErrors.password_confirmation.includes(
        'PASSWORD_CONFIRMATION_IS_NOT_A_STRING',
      ),
    passwordContainsNoMandatorySpecialCharacters:
      passwordErrors &&
      responseErrors.password.includes(
        'PASSWORD_CONTAINS_NO_MANDATORY_SPECIAL_CHARACTERS',
      ),
    passwordContainsSpacesOrTabs:
      passwordErrors &&
      responseErrors.password.includes('PASSWORD_CONTAINS_SPACES_OR_TABS'),
    passwordDoesNotContainANumber:
      passwordErrors &&
      responseErrors.password.includes('PASSWORD_DOES_NOT_CONTAIN_A_NUMBER'),
    passwordDoesNotContainLowercaseCharacters:
      passwordErrors &&
      responseErrors.password.includes(
        'PASSWORD_DOES_NOT_CONTAIN_LOWERCASE_CHARACTERS',
      ),
    passwordDoesNotContainUppercaseCharacters:
      passwordErrors &&
      responseErrors.password.includes(
        'PASSWORD_DOES_NOT_CONTAIN_UPPERCASE_CHARACTERS',
      ),
    passwordIsEmpty:
      passwordErrors && responseErrors.password.includes('PASSWORD_IS_EMPTY'),
    passwordIsNotaString:
      passwordErrors &&
      responseErrors.password.includes('PASSWORD_IS_NOT_A_STRING'),
    passwordIsSameAsCurrentPassword:
      passwordErrors &&
      responseErrors.password.includes('PASSWORD_IS_SAME_AS_CURRENT_PASSWORD'),
    passwordIsSimilarToEmailOrUsername:
      passwordErrors &&
      responseErrors.password.includes(
        'PASSWORD_IS_SIMILAR_TO_EMAIL_OR_USERNAME',
      ),
    passwordIsTooLong:
      passwordErrors &&
      responseErrors.password.includes('PASSWORD_IS_TOO_LONG'),
    passwordIsTooShort:
      passwordErrors &&
      responseErrors.password.includes('PASSWORD_IS_TOO_SHORT'),
  });
}
