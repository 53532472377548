<ion-header *transloco="let t">
  <ion-toolbar>
    <ion-title
      >{{ t('profile.set-new-password-success.header.title') }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *transloco="let t">
  <div class="p-8 full-view-content">
    <div class="content">
      <img alt="" class="mb-3_5" src="/assets/img/check-success.svg" />

      <p class="mb-6 mt-0">
        {{ t('profile.set-new-password-success.description') }}
      </p>
      <ion-button (click)="close()" class="button_primary">
        {{ t('profile.set-new-password-success.button') }}
      </ion-button>
    </div>
  </div>
</ion-content>
