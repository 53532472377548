import { DOCUMENT } from '@angular/common';
import {
  type ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { provideHttpClient, withFetch } from '@angular/common/http';
import {
  type FirebaseApp,
  initializeApp,
  provideFirebaseApp,
} from '@angular/fire/app';
import {
  getMessaging,
  type Messaging,
  provideMessaging,
} from '@angular/fire/messaging';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';

import { provideIonicAngular } from '@ionic/angular/standalone';

import { PLATFORM_IDENTIFIER } from '@mbeon-pwa/common';
import {
  AuthenticationApplicationService,
  LanguageApplicationService,
  ProfileApplicationService,
} from '@mbeon-pwa/domain';

import { provideTransloco, TranslocoService } from '@ngneat/transloco';

import { environment } from '../environments';

import { appRoutes } from './app.routes';
import { NAVIGATOR } from './common/values/navigator.injection-token';
import { provideAppBootstrapListener } from './core/app-bootstrap-listener/provide-app-bootstrap-listener';
import { pushNotificationsBootstrapListener } from './core/app-bootstrap-listener/push-notifications/push-notifications.bootstrap-listener';
import { languageAppInitializer } from './core/app-initializer/language/language.app-initializer';
import { profileAppInitializer } from './core/app-initializer/profile/profile.app-initializer';
import { provideAppInitializer } from './core/app-initializer/provide-app-initializer';
import { GlobalErrorHandler } from './core/services/error-handler/error-handler';
import { PushNotifications } from './core/services/push-notifications/push-notifications';
import { TranslocoHttpLoader } from './core/services/transloco-http-loader/transloco-http-loader';
import { AppState } from './core/state/app.state';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(withFetch()),
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    importProvidersFrom([
      provideFirebaseApp(
        (): FirebaseApp =>
          initializeApp({
            appId: environment.firebase.appId,
            apiKey: environment.firebase.apiKey,
            projectId: environment.firebase.projectId,
            messagingSenderId: environment.firebase.messaging.senderId,
          }),
      ),
      provideMessaging((): Messaging => getMessaging()),
    ]),
    provideIonicAngular({
      mode: 'ios',
    }),
    provideRouter([...appRoutes], withEnabledBlockingInitialNavigation()),
    provideServiceWorker('mbeon-sw.js', {
      enabled: true,
      registrationStrategy: 'registerImmediately',
    }),
    provideTransloco({
      config: {
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),

    // bootstrap-listeners
    provideAppBootstrapListener(pushNotificationsBootstrapListener, [
      DOCUMENT,
      NAVIGATOR,
      PLATFORM_IDENTIFIER,
      PushNotifications,
    ]),

    // initializers
    provideAppInitializer(languageAppInitializer, [
      LanguageApplicationService,
      TranslocoService,
    ]),
    provideAppInitializer(profileAppInitializer, [
      AppState,
      AuthenticationApplicationService,
      LanguageApplicationService,
      ProfileApplicationService,
    ]),
  ],
};
