import { Pipe, type PipeTransform } from '@angular/core';
import sanitize from 'sanitize-html';

export type MessageBodyFragment =
  | {
      readonly type: 'text';
      readonly text: string;
    }
  | {
      readonly type: 'link';
      readonly text: string;
      readonly destination: string;
    }
  | {
      readonly type: 'break';
      readonly text: string;
    };

@Pipe({
  standalone: true,
  name: 'chatThreadMessage',
  pure: true,
})
export class ChatThreadMessagePipe implements PipeTransform {
  private static readonly delimiter = ' ';

  transform(messageBody: string): readonly MessageBodyFragment[] {
    return messageBody
      .replace(/\n/g, ' \n ')
      .split(ChatThreadMessagePipe.delimiter)
      .filter((value: string) => value !== '')
      .reduce(
        (
          previousValue: MessageBodyFragment[],
          currentValue: string,
        ): MessageBodyFragment[] => {
          const currentMessageBodyFragment: MessageBodyFragment =
            this.#getMessageBodyFragment(currentValue);

          const previousFragment: MessageBodyFragment | undefined =
            previousValue.at(-1);

          if (
            !previousFragment ||
            previousFragment.type !== 'text' ||
            currentMessageBodyFragment.type !== 'text'
          ) {
            return [...previousValue, currentMessageBodyFragment];
          }

          const splicesElements: MessageBodyFragment = previousValue.pop()!;

          return [
            ...previousValue,
            {
              type: 'text',
              text: `${splicesElements.text} ${currentMessageBodyFragment.text}`,
            },
          ];
        },
        [],
      )
      .map((messageFragment: MessageBodyFragment): MessageBodyFragment => {
        if (
          messageFragment.type === 'text' &&
          messageFragment.text.length > 0
        ) {
          const decodedString: string = this.#decodeHtmlString(
            messageFragment.text,
          );

          return {
            type: 'text',
            text: sanitize(decodedString, {
              allowedTags: [
                'b',
                'i',
                'ul',
                'li',
                'ol',
                'strong',
                'a',
                'font',
                'u',
              ],
              allowedAttributes: {
                a: ['href'],
                font: ['color'],
              },
            }),
          };
        }

        return messageFragment;
      });
  }

  #getMessageBodyFragment(fragment: string): MessageBodyFragment {
    if (fragment === '\n') {
      return {
        type: 'break',
        text: 'break',
      };
    }
    if (/^https?:\/\//.test(fragment)) {
      return {
        type: 'link',
        text: fragment,
        destination: fragment,
      };
    }

    return {
      type: 'text',
      text: fragment,
    };
  }

  #decodeHtmlString(htmlString: string): string {
    const doc: Document = new DOMParser().parseFromString(
      htmlString,
      'text/html',
    );
    return doc.documentElement.textContent ?? '';
  }
}
