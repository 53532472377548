<ng-container *transloco="let t">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <mbeon-pwa-nav-back-button></mbeon-pwa-nav-back-button>
      </ion-buttons>
      <ion-title>{{ t('profile.set-new-password.header.title') }}</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content *rxLet="setPasswordStateErrors$; let errorState">
    <ion-grid [fixed]="true">
      <ion-row class="ion-justify-content-center">
        <ion-col>
          <div class="px-4">
            <p class="mb-6 mt-0 my-5">
              {{ t('profile.set-new-password.description') }}
            </p>
            <form (ngSubmit)="submit()" [formGroup]="newPasswordForm">
              <mbeon-pwa-input-form-field
                [control]="newPasswordForm.controls.oldPassword"
                [hasError]="
                  (!!newPasswordForm.controls.oldPassword.errors &&
                    !!newPasswordForm.controls.password.value) ||
                  errorState.currentPasswordIsNotCorrect
                "
                [label]="t('input.old-password.label')"
                class="block"
                type="password"
              >
              </mbeon-pwa-input-form-field>
              <div class="input-error-message mb-2 pl-4">
                <p *ngIf="errorState.currentPasswordIsNotCorrect">
                  {{ t('input.current-password.invalid') }}
                </p>
              </div>

              <h5 class="pb-1">
                {{ t('profile.set-new-password.new-pasword') }}
              </h5>

              <mbeon-pwa-password-validator
                [control]="newPasswordForm.controls.password"
              ></mbeon-pwa-password-validator>

              <mbeon-pwa-input-form-field
                [control]="newPasswordForm.controls.password"
                [hasError]="
                  !!newPasswordForm.controls.password.errors &&
                  !!newPasswordForm.controls.password.value
                "
                [label]="t('input.new-password.label')"
                type="password"
              >
              </mbeon-pwa-input-form-field>
              <div class="input-error-message mb-2 pl-4">
                <p
                  *ngIf="
                    !!newPasswordForm.controls.password.errors?.[
                      'whiteSpace'
                    ] && !!newPasswordForm.controls.password.value
                  "
                >
                  {{ t('input-validator.password.whitespace') }}
                </p>

                <p
                  *ngIf="
                    errorState.passwordIsSameAsCurrentPassword ||
                    (newPasswordForm.controls.oldPassword.touched &&
                      !!newPasswordForm.errors?.['oldNewPasswordMatch'])
                  "
                >
                  {{
                    t('input-validator.password-is-same-as-current-password')
                  }}
                </p>
                <p *ngIf="errorState.passwordIsSimilarToEmailOrUsername">
                  {{ t('input-validator.password-contains-username-or-email') }}
                </p>
                <p
                  *ngIf="
                    !!newPasswordForm.controls.password.errors?.[
                      'emailInPassword'
                    ] && !!newPasswordForm.controls.password.value
                  "
                >
                  {{ t('input-validator.password-contains-email') }}
                </p>
                <p
                  *ngIf="
                    !!newPasswordForm.controls.password.errors?.[
                      'usernameInPassword'
                    ] && !!newPasswordForm.controls.password.value
                  "
                >
                  {{ t('input-validator.password-contains-username') }}
                </p>
                <p *ngIf="errorState.passwordIsSameAsCurrentPassword">
                  {{
                    t('input-validator.password-is-same-as-current-password')
                  }}
                </p>
              </div>

              <mbeon-pwa-input-form-field
                [control]="newPasswordForm.controls.confirmPassword"
                [hasError]="
                  !!newPasswordForm.controls.confirmPassword.errors &&
                  !!newPasswordForm.controls.password.value
                "
                [label]="t('input.new-password-repeat.label')"
                type="password"
              >
              </mbeon-pwa-input-form-field>
              <div class="input-error-message mb-0 pl-4">
                <p
                  *ngIf="
                    !!newPasswordForm.errors &&
                    newPasswordForm.errors?.['mismatch']
                  "
                >
                  {{ t('input-validator.password.repeat') }}
                </p>
              </div>

              <ion-button
                [disabled]="newPasswordForm.invalid"
                class="button_primary mb-7 mt-4"
                type="submit"
              >
                {{ t('profile.set-new-password.button') }}
              </ion-button>
            </form>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</ng-container>
