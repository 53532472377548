import {
  LanguageApplicationService,
  languageChanged,
  onDomainEvent,
} from '@mbeon-pwa/domain';

import { TranslocoService } from '@ngneat/transloco';

import { concatWith, type Observable, switchMap } from 'rxjs';

export function languageAppInitializer(
  languageApplicationService: LanguageApplicationService,
  translocoService: TranslocoService,
): () => void {
  return (): void => {
    translocoService.setAvailableLangs(
      languageApplicationService.getAvailableLanguages() as string[],
    );

    languageApplicationService
      .getDefaultLanguage()
      .pipe(
        concatWith(
          onDomainEvent(languageChanged).pipe(
            switchMap(
              (): Observable<string> =>
                languageApplicationService.getDefaultLanguage(),
            ),
          ),
        ),
      )
      .subscribe(async (languageCode: string): Promise<void> => {
        translocoService.setActiveLang(languageCode);
      });
  };
}
