import { InjectionToken } from '@angular/core';
import { getToken, deleteToken, isSupported } from '@angular/fire/messaging';

export interface FirebaseMessaging {
  readonly deleteToken: typeof deleteToken;
  readonly getToken: typeof getToken;
  readonly isSupported: typeof isSupported;
}

export const FIREBASE_MESSAGING: InjectionToken<FirebaseMessaging> =
  new InjectionToken('FIREBASE_MESSAGING', {
    providedIn: 'root',
    factory: (): FirebaseMessaging => ({
      deleteToken,
      getToken,
      isSupported,
    }),
  });
