import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  type Consultant,
  type ConsultantFilter,
  type ConsultantPreview,
  ConsultantRepository,
  type FavoriteConsultant,
} from '@mbeon-pwa/domain';

import { map, type Observable } from 'rxjs';

import type { ConsultantDto } from '../../types/consultant.dto.type';
import type { ConsultantFavoriteDto } from '../../types/consultant-favorite.dto.type';
import type { ConsultantsFilterDto } from '../../types/consultants-filter-dto.type';
import type { ConsultantsListItemDto } from '../../types/consultants-list-item.dto.type';

@Injectable()
export class ConsultantRepositoryImpl implements ConsultantRepository {
  readonly #httpClient: HttpClient = inject(HttpClient);

  getConsultant(consultantId: string): Observable<Consultant> {
    return this.#httpClient
      .post<ConsultantDto>(
        'consultant/profile',
        { consultant_id: consultantId },
        {
          withCredentials: true,
        },
      )
      .pipe(
        map((consultantDto: ConsultantDto): Consultant => {
          return {
            id: consultantDto.id,
            username: consultantDto.username,
            image: consultantDto.avatar,
            about: consultantDto.public_description,
            language: consultantDto.languages,
            favorite: consultantDto.favorite,
            lastName: consultantDto.last_name,
            name: consultantDto.first_name,
            location: {
              city: consultantDto.address.city,
              street: consultantDto.address.street,
              houseNumber: consultantDto.address.house_number,
              zipcode: consultantDto.address.zip_code,
            },
            autoAnswers: {
              absence:
                consultantDto.outofoffice && consultantDto.outofoffice_message
                  ? consultantDto.outofoffice_message
                  : undefined,
              greeting: consultantDto.greeting_message ?? undefined,
            },
          };
        }),
      );
  }

  getFavoriteConsultants(): Observable<FavoriteConsultant[]> {
    return this.#httpClient
      .get<readonly ConsultantFavoriteDto[]>('consultant/favourites', {
        withCredentials: true,
      })
      .pipe(
        map(
          (
            consultantFavorites: readonly ConsultantFavoriteDto[],
          ): FavoriteConsultant[] => {
            return consultantFavorites.map(
              (
                consultantFavorite: ConsultantFavoriteDto,
              ): FavoriteConsultant => {
                return {
                  id: consultantFavorite.id,
                  image: consultantFavorite.avatar,
                  languages: consultantFavorite.languages,
                  lastName: consultantFavorite.last_name,
                  location: {
                    city: consultantFavorite.address.city,
                    zipcode: consultantFavorite.address.zip_code,
                  },
                  name: consultantFavorite.first_name,
                  username: consultantFavorite.username,
                };
              },
            );
          },
        ),
      );
  }

  setConsultantFavorite(consultantId: string): Observable<void> {
    return this.#httpClient.post<void>(
      'consultant/favourites/add',
      {
        consultant_id: consultantId,
      },
      {
        withCredentials: true,
      },
    );
  }

  unSetConsultantFavorite(consultantId: string): Observable<void> {
    return this.#httpClient.post<void>(
      'consultant/favourites/remove',
      {
        consultant_id: consultantId,
      },
      {
        withCredentials: true,
      },
    );
  }

  getFilteredConsultants(
    consultantFilter: ConsultantFilter,
  ): Observable<ConsultantPreview[]> {
    const filter: ConsultantsFilterDto = {
      city_or_zip_code: consultantFilter.location ?? undefined,
      full_name: consultantFilter.name ?? undefined,
      languages: [...(consultantFilter.languages ?? [])],
      gender: [...(consultantFilter.gender ?? [])],
      geolocation:
        consultantFilter.geoLocation?.lat && consultantFilter.geoLocation?.long
          ? {
              lat: consultantFilter.geoLocation!.lat,
              lng: consultantFilter.geoLocation!.long,
            }
          : undefined,
    };
    return this.#httpClient
      .post<ConsultantsListItemDto[]>('consultant/list', filter, {
        withCredentials: true,
      })
      .pipe(
        map(
          (
            consultantsListItemDto: ConsultantsListItemDto[],
          ): ConsultantPreview[] => {
            return consultantsListItemDto.map(
              (
                consultantsListItem: ConsultantsListItemDto,
              ): ConsultantPreview => {
                return {
                  id: consultantsListItem.id,
                  distance: consultantsListItem.distance,
                  username: consultantsListItem.username,
                  image: consultantsListItem.avatar,
                  languages: consultantsListItem.languages,
                  lastName: consultantsListItem.last_name,
                  name: consultantsListItem.first_name,
                  location: {
                    city: consultantsListItem.address.city,
                    zipcode: consultantsListItem.address.zip_code,
                  },
                };
              },
            );
          },
        ),
      );
  }
}
