import { inject } from '@angular/core';

import { map, Observable } from 'rxjs';

import { Router, UrlTree } from '@angular/router';

import { AuthenticationApplicationService } from '@mbeon-pwa/domain';

export const authenticatedGuard: () => Observable<
  true | UrlTree
> = (): Observable<true | UrlTree> => {
  const authenticationApplicationService: AuthenticationApplicationService =
    inject(AuthenticationApplicationService);
  const router: Router = inject(Router);

  return authenticationApplicationService
    .areTokensValid()
    .pipe(
      map(
        (valid: boolean): true | UrlTree => valid || router.parseUrl('/login'),
      ),
    );
};
