<ion-content *rxLet="state$; let state">
  <div class="container">
    <div class="background"></div>
    <div class="content">
      <div class="icon">
        <img alt="" class="my-1_5" src="/assets/img/logo.svg" />
      </div>
    </div>
    <div class="loading-spinner">
      <ion-spinner
        *ngIf="state.showLoadingIndicator"
        name="lines-sharp"
      ></ion-spinner>
    </div>
  </div>
</ion-content>
