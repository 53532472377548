import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';

import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

import {
  LanguageApplicationService,
  languageChanged,
  onDomainEvent,
  SupportedLanguage,
} from '@mbeon-pwa/domain';

import { TranslocoModule } from '@ngneat/transloco';

import { RxLet } from '@rx-angular/template/let';

import { addIcons } from 'ionicons';
import { checkmarkOutline } from 'ionicons/icons';

import { lastValueFrom, merge, type Observable, switchMap } from 'rxjs';

import { NavBackButtonComponent } from '../../../../common/components/buttons/nav-back-button/nav-back-button.component';

@Component({
  selector: 'mbeon-pwa-language-switch',
  standalone: true,
  imports: [
    CommonModule,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
    IonTitle,
    IonToolbar,
    TranslocoModule,
    NgFor,
    NgIf,
    RxLet,
    NavBackButtonComponent,
    TranslocoModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss'],
})
export class LanguageSwitchComponent {
  readonly #languageService: LanguageApplicationService = inject(
    LanguageApplicationService,
  );

  readonly availableLanguages: readonly SupportedLanguage[] =
    this.#languageService.getAvailableLanguages();

  readonly activeLang$: Observable<string>;

  constructor() {
    addIcons({ checkmarkOutline });

    this.activeLang$ = merge(
      this.#languageService.getDefaultLanguage(),
      onDomainEvent(languageChanged).pipe(
        switchMap((): Observable<string> => {
          return this.#languageService.getDefaultLanguage();
        }),
      ),
    );
  }

  async switchLanguage(languageCode: SupportedLanguage): Promise<void> {
    await lastValueFrom(this.#languageService.setLanguage(languageCode));
  }
}
