import {
  APP_BOOTSTRAP_LISTENER,
  ComponentRef,
  type FactoryProvider,
} from '@angular/core';

/* eslint-disable @typescript-eslint/no-explicit-any */
export function provideAppBootstrapListener(
  factory: (
    ...dependencies: readonly any[]
  ) => (compRef: ComponentRef<unknown>) => void,
  dependencies: readonly any[],
): FactoryProvider {
  return {
    provide: APP_BOOTSTRAP_LISTENER,
    useFactory: factory,
    deps: [...dependencies],
    multi: true,
  };
}
