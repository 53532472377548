<ion-header *transloco="let t">
  <ion-toolbar>
    <ion-title>{{ t('password-reset.link-expired.header.title') }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *transloco="let t" [fullscreen]="true">
  <ion-grid [fixed]="true">
    <ion-row class="ion-justify-content-center">
      <ion-col>
        <div class="container p-6">
          <div>
            <h2 class="mt-0 mb-2">
              {{ t('password-reset.link-expired.title') }}
            </h2>
            <p class="mb-6 mt-0">
              {{ t('password-reset.link-expired.description') }}
            </p>
          </div>
          <div>
            <ion-button
              (click)="goToPasswordReset()"
              class="button_primary mb-6"
              type="submit"
            >
              {{ t('password-reset.link-expired.button.reset-password') }}
            </ion-button>
            <ion-button
              (click)="close()"
              class="button_secondary mb-8"
              type="submit"
            >
              {{ t('password-reset.link-expired.button.back-to-login') }}
            </ion-button>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
