import {
  type AbstractControl,
  type ValidationErrors,
  type ValidatorFn,
} from '@angular/forms';

export function usernameInPasswordValidator(
  passwordControl: AbstractControl<unknown>,
): ValidatorFn {
  return (
    usernameControl: AbstractControl<unknown>,
  ): ValidationErrors | null => {
    if (!passwordControl.value || !usernameControl.value) {
      return null; // Return null if either control is empty
    }

    const passwordValue: string = passwordControl.value as string;
    const usernameValue: string = usernameControl.value as string;

    const usernameIncludedUsername: boolean = usernameValue
      .toLowerCase()
      .includes(passwordValue.toLowerCase());

    if (passwordValue && usernameValue && usernameIncludedUsername) {
      return {
        usernameInPassword: true,
      };
    }

    return null;
  };
}
