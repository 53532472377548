<ng-container *transloco="let t">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button (click)="cancel()"
          >{{ t('register.account-data.header.button') }}
        </ion-button>
      </ion-buttons>
      <ion-title>{{ t('register.account-data.header.title') }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content *rxLet="state$; let state">
    <ion-grid [fixed]="true">
      <ion-row class="ion-justify-content-center">
        <ion-col>
          <div class="p-6">
            <h2 class="mt-0 mb-1">{{ t('register.account-data.title') }}</h2>
            <p class="mb-6 mt-0">
              {{ t('register.account-data.description') }}
            </p>
            <form
              (ngSubmit)="submit()"
              [formGroup]="registrationAccountDataFormGroup"
            >
              <ion-list>
                <mbeon-pwa-input-form-field
                  [clearInput]="true"
                  [control]="registrationAccountDataFormGroup.controls.email"
                  [hasError]="
                    state.emailAlreadyInUse ||
                    state.emailInvalid ||
                    (!!registrationAccountDataFormGroup.controls.email.errors &&
                      registrationAccountDataFormGroup.controls.email.touched)
                  "
                  [label]="t('input.email.label')"
                  class="mb-0 block"
                ></mbeon-pwa-input-form-field>
                <div class="input-error-message mb-2 mt-1 pl-4">
                  <p
                    *ngIf="
                      registrationAccountDataFormGroup.controls.email.errors?.[
                        'emailInvalid'
                      ] &&
                      registrationAccountDataFormGroup.controls.email.touched
                    "
                  >
                    {{ t('input.email.invalid') }}
                  </p>

                  <p *ngIf="state.emailInvalid">
                    {{ t('input.email.dns-invalid') }}
                  </p>

                  <p *ngIf="state.emailAlreadyInUse">
                    {{ t('input-validator.email-already-in-use') }}
                  </p>
                </div>

                <mbeon-pwa-input-form-field
                  [clearInput]="true"
                  [control]="registrationAccountDataFormGroup.controls.username"
                  [hasError]="
                    state.usernameAlreadyInUse ||
                    state.usernameInvalid ||
                    state.usernameIsSimilarToEmail ||
                    (!!registrationAccountDataFormGroup.controls.username
                      .errors &&
                      registrationAccountDataFormGroup.controls.username
                        .touched)
                  "
                  [label]="t('input.username.label')"
                  class="mb-0 block"
                ></mbeon-pwa-input-form-field>

                <div class="input-error-message mb-2 mt-1 pl-4">
                  <p
                    *ngIf="
                      state.usernameInvalid ||
                      state.usernameIsSimilarToEmail ||
                      state.usernameAlreadyInUse ||
                      (!!registrationAccountDataFormGroup.controls.username
                        .errors &&
                        registrationAccountDataFormGroup.controls.username
                          .touched)
                    "
                  >
                    <span
                      *ngIf="
                        registrationAccountDataFormGroup.controls.username
                          .errors?.['usernameInEmail'] ||
                        state.usernameIsSimilarToEmail
                      "
                    >
                      {{ t('input-validator.username-mail-address') }}<br
                    /></span>

                    <span *ngIf="state.usernameAlreadyInUse">
                      {{ t('input-validator.username-already-in-use') }}<br
                    /></span>

                    <span
                      *ngIf="
                        registrationAccountDataFormGroup.controls.username
                          .errors?.['minLength'] ||
                        registrationAccountDataFormGroup.controls.username
                          .errors?.['maxLength']
                      "
                    >
                      {{ t('input-validator.username.length') }}<br
                    /></span>

                    <span
                      *ngIf="
                        registrationAccountDataFormGroup.controls.username
                          .errors?.['onlyValidCharacters']
                      "
                    >
                      {{ t('input-validator.username.valid-characters') }}<br
                    /></span>
                    <span
                      *ngIf="
                        registrationAccountDataFormGroup.controls.username
                          .errors?.['whiteSpace']
                      "
                    >
                      {{ t('input-validator.username.whitespace') }}</span
                    >
                  </p>
                </div>

                <ion-label class="custom-checkbox">
                  <ion-checkbox
                    formControlName="acceptTermsOfUse"
                    justify="center"
                    labelPlacement="end"
                  ></ion-checkbox>
                  <div
                    (click)="processLinks($event)"
                    [innerHTML]="t('register.account-data.form.terms-of-use')"
                  ></div>
                </ion-label>

                <ion-label class="custom-checkbox">
                  <ion-checkbox
                    formControlName="privacy"
                    justify="start"
                    labelPlacement="end"
                  ></ion-checkbox>
                  <div
                    (click)="processLinks($event)"
                    [innerHTML]="t('register.account-data.form.data-privacy')"
                  ></div>
                </ion-label>

                <ion-button
                  [disabled]="
                    registrationAccountDataFormGroup.invalid ||
                    (registrationAccountDataFormGroup.valid &&
                      (state.emailAlreadyInUse ||
                        state.emailInvalid ||
                        state.usernameAlreadyInUse ||
                        state.usernameInvalid ||
                        state.usernameIsSimilarToEmail))
                  "
                  class="button_primary mb-7 mt-4"
                  type="submit"
                  >{{ t('register.account-data.form.button.submit') }}
                </ion-button>
              </ion-list>
            </form>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</ng-container>
