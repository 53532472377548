import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { RxLet } from '@rx-angular/template/let';
import { TranslocoDirective } from '@ngneat/transloco';
import { addIcons } from 'ionicons';
import { chevronBackOutline } from 'ionicons/icons';
import { Router } from '@angular/router';
import {
  DeleteProfileError,
  ProfileApplicationService,
} from '@mbeon-pwa/domain';

import { firstValueFrom } from 'rxjs';

import { DeleteAccountState } from '../../states/delete-account/delete-account.state';

@Component({
  selector: 'mbeon-pwa-delete-account',
  standalone: true,
  imports: [
    CommonModule,
    IonButton,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    RxLet,
    TranslocoDirective,
    IonCol,
    IonGrid,
    IonRow,
    IonButtons,
    IonIcon,
  ],
  templateUrl: './delete-account.component.html',
  styleUrl: './delete-account.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteAccountComponent {
  readonly #router: Router = inject(Router);

  readonly #profileApplicationService: ProfileApplicationService = inject(
    ProfileApplicationService,
  );

  readonly #deleteAccountState: DeleteAccountState = inject(DeleteAccountState);

  constructor() {
    addIcons({
      chevronBackOutline,
    });
  }

  async goToStartPage(): Promise<void> {
    await this.#router.navigate(['app/consultations']);
    this.#deleteAccountState.reset();
  }

  async deleteAccount(): Promise<void> {
    const token: string = this.#deleteAccountState.get('token');

    try {
      await firstValueFrom(
        this.#profileApplicationService.deleteAccount(token),
      );

      this.#deleteAccountState.setDeleteAccountSuccess(true);
      await this.#router.navigate(['delete-account-success']);
    } catch (error: unknown) {
      if (error instanceof DeleteProfileError) {
        if (error.tokenForAccountDeleteHasExpired) {
          this.#deleteAccountState.setLinkExpired(true);
          await this.#router.navigate(['delete-account-link-expired']);
        }
      }

      await this.#router.navigate(['non-specific-error']);
    }
  }
}
