import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { RxLet } from '@rx-angular/template/let';
import { TranslocoDirective } from '@ngneat/transloco';
import { Router } from '@angular/router';

import { ProfileApplicationService } from '@mbeon-pwa/domain';

import { lastValueFrom } from 'rxjs';

import { DeleteAccountState } from '../../states/delete-account/delete-account.state';

@Component({
  selector: 'mbeon-pwa-delete-account-link-expired',
  standalone: true,
  imports: [
    CommonModule,
    IonButton,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    RxLet,
    TranslocoDirective,
    IonCol,
    IonGrid,
    IonRow,
  ],
  templateUrl: './delete-account-link-expired.component.html',
  styleUrl: './delete-account-link-expired.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteAccountLinkExpiredComponent {
  readonly #deleteAccountState: DeleteAccountState = inject(DeleteAccountState);

  readonly #router: Router = inject(Router);

  readonly #profileApplicationService: ProfileApplicationService = inject(
    ProfileApplicationService,
  );

  async reInitDeleteAccount(): Promise<void> {
    try {
      await lastValueFrom(
        this.#profileApplicationService.initializeProfileDeletion(),
      );
      this.#deleteAccountState.setLinkHasBeenSent(true);

      await this.#router.navigate(['delete-account-link-has-been-sent']);
    } catch (e) {
      await this.#router.navigate(['non-specific-error']);
    }
  }

  async goToStartPage(): Promise<void> {
    await this.#router.navigate(['app/consultations']);
    this.#deleteAccountState.reset();
  }
}
