import { inject, InjectionToken } from '@angular/core';

import { PLATFORM_IDENTIFIER, PlatformIdentifier } from '@mbeon-pwa/common';

export const CRYPTO: InjectionToken<Crypto | undefined> = new InjectionToken(
  'CRYPTO',
  {
    providedIn: 'platform',
    factory: (): Crypto | undefined => {
      const platformIdentifier: PlatformIdentifier =
        inject(PLATFORM_IDENTIFIER);

      if (platformIdentifier.isBrowser) {
        return globalThis.crypto;
      }

      return undefined;
    },
  },
);
