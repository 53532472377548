import { type Route } from '@angular/router';

import { authenticationRoutes } from './features/authentication/routes';
import { contentRoutes } from './features/content/routes';
import { globalChatRoutes } from './features/consultation/routes';
import { globalProfileRoutes } from './features/profile/routes';
import { homeRoutes } from './features/home/routes';
import { resetPasswordRoutes } from './features/password-reset/routes';
import { usernameResetRoutes } from './features/forgot-user-name/routes';
import { ConsultantRoutes } from './features/consultants/routes';

export const appRoutes: readonly Route[] = [
  ...authenticationRoutes,
  ...resetPasswordRoutes,
  ...homeRoutes,
  ...contentRoutes,
  ...usernameResetRoutes,
  ...globalChatRoutes,
  ...globalProfileRoutes,
  ...globalChatRoutes,
  ...ConsultantRoutes,
  {
    path: '**',
    redirectTo: 'app/consultations',
    pathMatch: 'full',
  },
];
