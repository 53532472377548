import { type AnimationEvent } from '@angular/animations';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  type AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  type OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import {
  IonAlert,
  IonButton,
  IonButtons,
  IonIcon,
  IonItem,
  IonTextarea,
} from '@ionic/angular/standalone';

import {
  type Consultant,
  ConsultationApplicationService,
  IllegalConsultationFileError,
  type MessagePayloadAttachment,
} from '@mbeon-pwa/domain';

import { TranslocoDirective } from '@ngneat/transloco';

import { RxState } from '@rx-angular/state';
import { RxIf } from '@rx-angular/template/if';
import { RxFor } from '@rx-angular/template/for';
import { RxLet } from '@rx-angular/template/let';
import { RxPush } from '@rx-angular/template/push';

import {
  bounceOutDownOnLeaveAnimation,
  collapseAnimation,
  jackInTheBoxOnEnterAnimation,
} from 'angular-animations';

import { addIcons } from 'ionicons';
import {
  add,
  arrowUpOutline,
  closeOutline,
  documentOutline,
  documentTextOutline,
  micOutline,
} from 'ionicons/icons';

import {
  defer,
  delay,
  filter,
  lastValueFrom,
  map,
  type Observable,
  Subject,
} from 'rxjs';

import { generateRenderCallbackProxy } from '../../../../common/helpers/generate-render-callback-proxy/generate-render-callback-proxy.helper';
import { URL } from '../../../../common/values/url.injection-token';
import { ChatThreadFileSizePipe } from '../../pipes/chat-thread-file-size/chat-thread-file-size.pipe';
import { ConsultationMessageInputVoiceComponent } from '../consultation-message-input-voice/consultation-message-input-voice.component';

import { AppState } from '../../../../core/state/app.state';

import type {
  ConsultationMessageInputComponentState,
  FileAttachment,
} from './consultation-message-input.component.state.types';

@Component({
  selector: 'mbeon-pwa-chat-thread-footer',
  standalone: true,
  imports: [
    ChatThreadFileSizePipe,
    CommonModule,
    ConsultationMessageInputVoiceComponent,
    FormsModule,
    IonAlert,
    IonButton,
    IonButtons,
    IonIcon,
    IonItem,
    IonTextarea,
    NgOptimizedImage,
    ReactiveFormsModule,
    RxIf,
    RxFor,
    RxPush,
    RxLet,
    TranslocoDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './consultation-message-input.component.html',
  styleUrl: './consultation-message-input.component.scss',
  viewProviders: [RxState],
  animations: [
    bounceOutDownOnLeaveAnimation(),
    collapseAnimation({
      animateChildren: 'before',
    }),
    jackInTheBoxOnEnterAnimation(),
  ],
})
export class ConsultationMessageInputComponent
  implements AfterViewInit, OnDestroy
{
  // in bytes -> 5mb
  private static readonly maxFileSize = 5000000 as const;

  readonly #appState: AppState = inject(AppState);

  @Input({
    required: true,
  })
  set consultant(consultant: Consultant) {
    this.#state.set({
      consultant,
    });
  }

  @Input()
  set disabled(isDisabled: boolean) {
    this.#state.set({
      isChatAvailable: !isDisabled,
    });
  }

  @Output()
  messageSent: EventEmitter<void> = new EventEmitter();

  @ViewChild('fileList', {
    read: ElementRef,
    static: false,
  })
  readonly fileList?: ElementRef<HTMLDivElement>;

  get acceptedFiles(): string {
    return this.#knownFileMimeTypes.join(',');
  }

  readonly attachmentsRenderCallback$: Subject<readonly FileAttachment[]>;

  readonly chatMessageForm: FormGroup<{
    readonly attachments: FormControl<string | null>;
    readonly message: FormControl<string | null>;
  }> = new FormGroup({
    attachments: new FormControl<string | null>(null),
    message: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
    ]),
  });

  readonly showAttachments$: Observable<boolean> = defer(
    (): Observable<boolean> =>
      this.#state.select('displayAttachments').pipe(delay(100)),
  );

  readonly state$: Observable<ConsultationMessageInputComponentState> = defer(
    (): Observable<ConsultationMessageInputComponentState> =>
      this.#state.select(),
  );

  readonly #chatRenderCallback$: Subject<readonly FileAttachment[]> =
    new Subject();

  readonly #chatThreadApplicationService: ConsultationApplicationService =
    inject(ConsultationApplicationService);

  readonly #knownFileMimeTypes: readonly string[] = [
    'application/pdf',
    'image/jpeg',
    'image/jpg',
    'image/png',
  ];

  readonly #state: RxState<ConsultationMessageInputComponentState> =
    inject<RxState<ConsultationMessageInputComponentState>>(RxState);

  readonly #url: typeof globalThis.URL = inject(URL);

  constructor() {
    addIcons({
      add,
      arrowUpOutline,
      closeOutline,
      documentTextOutline,
      documentOutline,
      micOutline,
    });

    this.attachmentsRenderCallback$ = generateRenderCallbackProxy(
      this.#chatRenderCallback$,
    );

    this.#state.set({
      attachments: [],
      displayAttachments: false,
      hasSendMessageError: false,
      isSendingMessage: false,
      unknownFileSelected: false,
    });

    this.#state.connect(
      this.#appState.select('consultationServerConnectionIsConnected').pipe(
        map(
          (
            consultationServerConnectionIsConnected: boolean,
          ): Partial<ConsultationMessageInputComponentState> => ({
            isChatAvailable: consultationServerConnectionIsConnected,
          }),
        ),
      ),
    );
  }

  ngAfterViewInit(): void {
    this.attachmentsRenderCallback$
      .pipe(
        filter((): boolean => this.#state.get().displayAttachments),
        delay(500),
      )
      .subscribe({
        next: (): void => {
          this.fileList?.nativeElement.scrollTo({
            left: this.fileList?.nativeElement.scrollWidth,
            behavior: 'smooth',
          });
        },
      });
  }

  ngOnDestroy(): void {
    this.#chatRenderCallback$.complete();
  }

  attachmentDisappeared(event: AnimationEvent): void {
    if (
      this.#state.get().attachments.length === 0 &&
      event.toState === 'void'
    ) {
      this.#state.set({
        displayAttachments: false,
      });
    }
  }

  loadAttachments(files: FileList | null): void {
    const acceptedFiles: File[] = Array.from(files ?? []).filter(
      (file: File): boolean =>
        this.#knownFileMimeTypes.includes(file.type) &&
        file.size <= ConsultationMessageInputComponent.maxFileSize,
    );

    if (files && acceptedFiles.length < files?.length) {
      this.#state.set({
        unknownFileSelected: true,
      });
    }

    if (acceptedFiles) {
      this.#state.set({
        attachments: [
          ...this.#state.get().attachments,
          ...acceptedFiles.map(
            (file: File): FileAttachment =>
              this.#createAttachmentFromFile(file),
          ),
        ],
        displayAttachments: true,
      });
    }

    this.chatMessageForm.controls.attachments.reset();
  }

  removeAttachment(file: FileAttachment): void {
    this.#state.set({
      attachments: this.#state
        .get()
        .attachments.filter(
          (stateFile: FileAttachment): boolean => stateFile !== file,
        ),
    });
  }

  async sendVoiceRecording(voiceMessage: Blob): Promise<void> {
    await this.#submitMessage({
      message: '%voice_message%',
      voiceMessage,
    });
  }

  async submitMessage(event: Event): Promise<void> {
    event.preventDefault();

    const state: ConsultationMessageInputComponentState = this.#state.get();

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    if (this.chatMessageForm.enabled && this.chatMessageForm.valid) {
      await this.#submitMessage({
        attachments: state.attachments,
        message: this.chatMessageForm.value.message ?? undefined,
      });
    }
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }

  toggleUnknownFileSelectionAlert(unknownFileSelected: boolean): void {
    this.#state.set({
      unknownFileSelected,
    });
  }

  toggleSendMessageError(hasSendMessageError: boolean): void {
    this.#state.set({
      hasSendMessageError,
    });
  }

  #createAttachmentFromFile(file: File): FileAttachment {
    if (file.type.startsWith('image')) {
      return {
        type: 'image',
        preview: this.#url.createObjectURL(file),
        file,
        size: file.size,
        name: file.name,
      };
    }

    return {
      type: 'document',
      documentType:
        file.name.lastIndexOf('.') !== -1
          ? file.name.substring(file.name.lastIndexOf('.') + 1)
          : undefined,
      file,
      size: file.size,
      name: file.name,
    };
  }

  async #submitMessage(payload: {
    readonly attachments?: readonly FileAttachment[];
    readonly message?: string;
    readonly voiceMessage?: Blob;
  }): Promise<void> {
    const state: ConsultationMessageInputComponentState = this.#state.get();

    this.chatMessageForm.disable();
    this.#state.set({
      isSendingMessage: true,
    });

    try {
      await lastValueFrom(
        this.#chatThreadApplicationService.addMessageToConsultation(
          state.consultant,
          {
            message: payload.message,
            voiceMessage: payload.voiceMessage,
            attachments: payload.attachments?.map(
              (fileAttachment: FileAttachment): MessagePayloadAttachment => ({
                name: fileAttachment.name,
                data: fileAttachment.file,
              }),
            ),
          },
        ),
      );

      this.chatMessageForm.reset();
      this.#state.set({
        attachments: [],
      });

      this.messageSent.emit();
    } catch (e: unknown) {
      if (e instanceof IllegalConsultationFileError) {
        this.#state.set({
          unknownFileSelected: true,
        });
      } else {
        this.#state.set({
          hasSendMessageError: true,
        });
      }
    } finally {
      this.#state.set({
        isSendingMessage: false,
      });
      this.chatMessageForm.enable();
    }
  }
}
