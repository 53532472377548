import { RxState } from '@rx-angular/state';

import { Injectable } from '@angular/core';

import { ResetPasswordErrors } from '@mbeon-pwa/domain';

import type { PasswordResetState as State } from './password-reset-state.type';

@Injectable({
  providedIn: 'root',
})
export class PasswordResetState extends RxState<State> {
  constructor() {
    super();
    this.reset();
  }

  reset() {
    this.set({
      emailSended: false,
      emailInvalid: false,
      setPasswordSuccess: false,
      resetLinkValid: false,
      token: undefined,
      passwordErrors: undefined,
    });
  }

  setEmailSended(): void {
    this.set({ emailSended: true });
  }

  setPasswordErrors(passwordErrors: ResetPasswordErrors): void {
    this.set({
      passwordErrors: passwordErrors,
    });
  }

  resetPasswordErrors(): void {
    this.set({
      passwordErrors: undefined,
    });
  }
}
