<mbeon-pwa-consultation-messages-infinite-loader
  (isLoading)="disableScrolling($event)"
></mbeon-pwa-consultation-messages-infinite-loader>

<ng-container *transloco="let t">
  <ng-container *rxLet="state$; let state">
    <ng-container *rxLet="consultationItems$; let consultationItems">
      <div
        class="message-group"
        *rxFor="
          let consultationItem of consultationItems;
          strategy: 'native';
          trackBy: identifyConsultationItem;
          renderCallback: renderCallback
        "
      >
        <div
          class="message-group-date-indicator"
          *rxIf="
            consultationItem.date !== undefined &&
            consultationItem.date !== null
          "
        >
          <span>{{ consultationItem.date! | chatThreadMessageGroupDate }}</span>
        </div>

        <div
          class="message-group-message"
          #message
          *rxIf="!!consultationItem.message"
          [attr.data-id]="consultationItem.message!.id"
          [class.is-mine]="consultationItem.message!.isMine"
        >
          <div
            class="message-group-owner-indicator"
            *rxIf="
              consultationItem.message!.isMine &&
              consultationItem.message!.isFirstMessageByPoster
            "
          >
            <span>{{ t('chat-thread.you') }}</span>
          </div>

          <ion-avatar
            class="message-group-owner-avatar"
            *rxIf="!consultationItem.message!.isMine"
          >
            <img
              *rxIf="
                consultationItem.message!.isFirstMessageByPoster &&
                !!state.consultant.image
              "
              [attr.alt]="state.consultant.username"
              [attr.src]="state.consultant.image"
            />

            <img
              alt="avatar"
              class="fallback-avatar"
              src="assets/img/avatar_mbeon_logo.svg"
              *rxIf="
                consultationItem.message!.isFirstMessageByPoster &&
                !state.consultant.image
              "
            />
          </ion-avatar>

          <div class="message-group-message-body">
            <div
              class="message-group-message-body-files"
              *rxIf="consultationItem.message!.attachments.length > 0"
            >
              <div
                class="message-group-message-body-file"
                *rxFor="
                  let attachment of consultationItem.message!.attachments;
                  trackBy: 'url'
                "
              >
                <ng-container
                  *rxIf="
                    attachment.displayInline &&
                    attachment.fileType === 'voice-message' &&
                    attachment.url !== undefined
                  "
                >
                  <mbeon-pwa-consultation-messages-voice-message
                    [name]="attachment.name"
                    [source]="attachment.url!"
                  ></mbeon-pwa-consultation-messages-voice-message>
                </ng-container>

                <ng-container *rxIf="!attachment.displayInline">
                  <ion-button
                    fill="clear"
                    target="_blank"
                    [color]="
                      consultationItem.message!.isMine ? 'light' : 'primary'
                    "
                    class="attachment-link"
                    [download]="attachment.name"
                    [ngSwitch]="attachment.fileType"
                    [href]="attachment.url"
                  >
                    <div class="attachment-link">
                      <ion-icon
                        name="image-outline"
                        size="default"
                        slot="start"
                        *ngSwitchCase="'image'"
                      ></ion-icon>

                      <ion-icon
                        name="document-outline"
                        size="default"
                        slot="start"
                        *ngSwitchDefault
                      ></ion-icon>

                      <span *rxIf="!!attachment.name" class="link-name">
                        {{ attachment.name }}
                      </span>

                      <span *rxIf="!!attachment.size"
                        >&nbsp;({{
                          attachment.size! | chatThreadFileSize
                        }})</span
                      >
                    </div>
                  </ion-button>
                </ng-container>
              </div>
            </div>

            <div
              class="message-group-message-body-text"
              *rxIf="!!consultationItem.message!.message"
            >
              <ng-container
                *rxFor="
                  let chatMessageFragment of consultationItem.message!.message!
                    | chatThreadMessage;
                  let last = last;
                  let index = index;
                  trackBy: 'text'
                "
                [ngSwitch]="chatMessageFragment.type"
              >
                <ng-container *ngSwitchCase="'text'">
                  @if (
                    !consultationItem.message!.isMine &&
                    consultationItem.message!.attachments.length > 0
                  ) {
                    <!--                    no text under BO assets-->
                  } @else {
                    <span [innerHTML]="chatMessageFragment.text"></span>
                  }
                </ng-container>
                <a
                  class="content-link"
                  target="_blank"
                  *ngSwitchCase="'link'"
                  [href]="$any(chatMessageFragment).destination"
                  >{{ chatMessageFragment.text }}</a
                >
                <ng-container *rxIf="!last">&nbsp;</ng-container>
                <ng-container *ngSwitchCase="'break'">
                  <br />
                </ng-container>
              </ng-container>
            </div>

            <footer
              class="message-group-message-footer"
              *rxIf="consultationItem.message!.timestamp !== null"
            >
              {{ consultationItem.message!.timestamp! | date: 'HH:mm' }}

              <figure
                class="message-group-message-footer-status"
                *rxIf="consultationItem.message!.isMine"
              >
                <ion-icon
                  name="checkmark-outline"
                  *rxIf="!consultationItem.message!.acknowledged"
                ></ion-icon>
                <ion-icon
                  name="checkmark-done-outline"
                  *rxIf="consultationItem.message!.acknowledged"
                ></ion-icon>
              </figure>
            </footer>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<div style="height: 1px" #bottomMarker></div>
