import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { compareDates } from '@mbeon-pwa/common';
import {
  AuthenticationRepository,
  type AuthenticationTokens,
  type ChatMessageFilter,
  ConnectionStatus,
  type Consultation,
  type ConsultationMessage,
  type ConsultationPayload,
  type ConsultationPayloadAttachment,
  type ConsultationRepository,
  type ConsultationStatistics,
  ConsultationUpdate,
  ON_DOMAIN_EVENT,
  type OnDomainEvent,
  ProfileRepository,
  userAuthenticated,
  type UserProfile,
  UserRepository,
  userSignedOut,
} from '@mbeon-pwa/domain';

import {
  type ChatHistory,
  ChatUpdate,
  EjabberdClient,
  type Message,
  type MessageFile,
} from 'ejabberd-client';

import {
  BehaviorSubject,
  catchError,
  combineLatest,
  combineLatestWith,
  exhaustMap,
  filter,
  first,
  forkJoin,
  map,
  merge,
  mergeMap,
  type Observable,
  of,
  retry,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';

import { CRYPTO } from '../../../core/values/crypto.injection-token';
import { createConsultationMessage } from '../../helpers/create-consultation-message/create-consultation-message.helper';
import { createConsultationStatistics } from '../../helpers/create-consultation-statistics/create-consultation-statistics.helper';
import { ConsultationConfig } from '../../types/consultation-config.type';
import type { ConsultationDto } from '../../types/consultation-dto.type';
import { ConsultationMetaDataDto } from '../../types/consultation-meta-data.dto.type';

@Injectable()
export class ConsultationRepositoryImpl implements ConsultationRepository {
  private static readonly ejabberdReconnectionInterval = 5000 as const;

  readonly #authenticationRepository: AuthenticationRepository = inject(
    AuthenticationRepository,
  );

  readonly #userRepository: UserRepository = inject(UserRepository);

  readonly #consultationConfig: ConsultationConfig = inject(ConsultationConfig);

  readonly #crypto?: Crypto = inject(CRYPTO);

  readonly #ejabberdClient: EjabberdClient = new EjabberdClient();

  readonly #ejabberdConnectionOnHold: BehaviorSubject<boolean> =
    new BehaviorSubject(false);

  readonly #httpClient: HttpClient = inject(HttpClient);

  readonly #onDomainEvent: OnDomainEvent = inject(ON_DOMAIN_EVENT);

  readonly #profileRepository: ProfileRepository = inject(ProfileRepository);

  readonly #updates: Map<string, Observable<ChatUpdate>> = new Map();

  constructor() {
    let shouldBeConnected = false;

    const connect = (trigger$: Observable<unknown>): Observable<void> =>
      trigger$.pipe(
        filter((): boolean => !this.#ejabberdConnectionOnHold.getValue()),
        tap({
          next: (): void => {
            this.#ejabberdConnectionOnHold.next(true);
          },
        }),
        switchMap(
          (): Observable<AuthenticationTokens | null> =>
            this.#authenticationRepository.getAuthenticationTokens().pipe(
              tap({
                error: (): void => {
                  this.#ejabberdConnectionOnHold.next(false);
                },
              }),
              catchError((): Observable<null> => of(null)),
            ),
        ),
        filter(
          (
            authenticationTokens: AuthenticationTokens | null,
          ): authenticationTokens is AuthenticationTokens =>
            !!authenticationTokens,
        ),
        tap({
          next: (): void => {
            shouldBeConnected = true;
          },
        }),
        switchMap(
          (authenticationTokens: AuthenticationTokens): Observable<void> =>
            this.#profileRepository.getProfile().pipe(
              mergeMap(
                (userProfile: UserProfile): Promise<void> =>
                  this.#ejabberdClient.connect(
                    this.#consultationConfig.serverURL,
                    {
                      jid: this.#createJid(userProfile.username),
                      password: authenticationTokens.xmpp,
                    },
                  ),
              ),
            ),
        ),
        tap({
          next: (): void => {
            this.#updates.clear();
            this.#ejabberdConnectionOnHold.next(false);
          },
          error: (): void => {
            this.#ejabberdConnectionOnHold.next(false);
          },
        }),
      );

    connect(
      this.#onDomainEvent(userAuthenticated).pipe(startWith(null)),
    ).subscribe();

    merge(
      of(undefined),
      this.#ejabberdClient.hasAuthFailed$.pipe(
        switchMap(() => this.#userRepository.extendSession()),
        mergeMap(
          (authenticationTokens: AuthenticationTokens): Observable<void> =>
            this.#authenticationRepository.saveAuthenticationTokens(
              authenticationTokens,
            ),
        ),
      ),
    )
      .pipe(
        switchMap(() =>
          connect(
            this.#ejabberdClient.isConnected$.pipe(
              takeUntil(this.#ejabberdClient.hasAuthFailed$),
              filter(
                (isConnected: boolean): boolean =>
                  !isConnected && shouldBeConnected,
              ),
            ),
          ).pipe(
            retry({
              delay: ConsultationRepositoryImpl.ejabberdReconnectionInterval,
            }),
          ),
        ),
      )
      .subscribe();

    this.#onDomainEvent(userSignedOut).subscribe({
      next: (): void => {
        shouldBeConnected = false;

        this.#ejabberdClient.disconnect();
      },
    });
  }

  addToConsultation(
    consultantId: string,
    payload: ConsultationPayload,
  ): Observable<void> {
    const receiverJid: string = this.#createJid(consultantId);

    return this.#waitForEjabberd().pipe(
      exhaustMap(
        (): Promise<void> =>
          this.#ejabberdClient.handle.sendMessage({
            body: 'message' in payload ? payload.message : undefined,
            to: receiverJid,
            id: this.#crypto!.randomUUID(),
            files:
              'attachments' in payload
                ? payload.attachments.map(
                    (
                      attachment: ConsultationPayloadAttachment['attachments'][0],
                    ): MessageFile => ({
                      disposition: attachment.isVoiceMessage
                        ? 'inline'
                        : 'attachment',
                      mediaType: attachment.mimeType,
                      name: attachment.name,
                      size: attachment.size,
                      source: attachment.url,
                    }),
                  )
                : [],
          }),
      ),
    );
  }

  getConnectionStatus(): Observable<ConnectionStatus> {
    return this.#ejabberdClient.isConnected$.pipe(
      combineLatestWith(this.#ejabberdClient.isConnecting$),
      map(
        ([isConnected, isConnecting]: readonly [
          boolean,
          boolean,
        ]): ConnectionStatus => {
          if (isConnected) {
            return ConnectionStatus.Connected;
          } else if (isConnecting) {
            return ConnectionStatus.Connecting;
          }

          return ConnectionStatus.Disconnected;
        },
      ),
      catchError(
        (): Observable<ConnectionStatus> => of(ConnectionStatus.Disconnected),
      ),
    );
  }

  getConsultationStatistics(consultant: {
    readonly id: string;
    readonly username: string;
  }): Observable<ConsultationStatistics> {
    return forkJoin([
      this.#waitForEjabberd().pipe(
        exhaustMap(
          (): Promise<ChatHistory> =>
            this.#ejabberdClient.handle.getChatHistory(
              this.#createJid(consultant.username),
              {
                max: 1,
              },
            ),
        ),
      ),
      this.#httpClient
        .get<ConsultationMetaDataDto | null>('consultation/meta-data', {
          params: new HttpParams().set('consultant_id', consultant.id),
          withCredentials: true,
        })
        .pipe(
          retry(2),
          catchError(() => of(null)),
        ),
    ]).pipe(
      map(
        ([chatHistory, consultationMetaData]: readonly [
          ChatHistory,
          ConsultationMetaDataDto | null,
        ]): ConsultationStatistics =>
          createConsultationStatistics(
            chatHistory,
            consultationMetaData?.consultant_last_read_at,
          ),
      ),
    );
  }

  getConsultationUpdates(consultantId: string): Observable<ConsultationUpdate> {
    if (!this.#updates.has(consultantId)) {
      this.#updates.set(
        consultantId,
        this.#waitForEjabberd().pipe(
          exhaustMap(
            (): Observable<ChatUpdate> =>
              this.#ejabberdClient.handle.getChatUpdates(
                this.#createJid(consultantId),
              ),
          ),
          shareReplay(1),
        ),
      );
    }

    return this.#updates.get(consultantId)!.pipe(
      map((chatUpdate: ChatUpdate): ConsultationUpdate => {
        if (chatUpdate.type === 'message') {
          return {
            type: 'message',
            message: createConsultationMessage(chatUpdate.message),
          };
        }

        return {
          type: 'acknowledgement',
          id: chatUpdate.messageId,
          timestamp: chatUpdate.timestamp,
        };
      }),
    );
  }

  getConsultationMessages(
    consultantId: string,
    filter?: ChatMessageFilter,
  ): Observable<readonly ConsultationMessage[]> {
    return this.#waitForEjabberd().pipe(
      exhaustMap(
        (): Promise<ChatHistory> =>
          this.#ejabberdClient.handle.getChatHistory(
            this.#createJid(consultantId),
            {
              end: filter?.toDate,
              max: filter?.limit ?? 20,
            },
          ),
      ),
      map((chatHistory: ChatHistory): readonly ConsultationMessage[] =>
        chatHistory.messages.map(
          (message: Message): ConsultationMessage =>
            createConsultationMessage(message),
        ),
      ),
    );
  }

  getUserConsultations(): Observable<any> {
    const ejabberd$: Observable<void> = this.#waitForEjabberd().pipe(
      shareReplay(1),
    );

    return this.#httpClient
      .get<readonly ConsultationDto[]>('consultations', {
        withCredentials: true,
      })
      .pipe(
        exhaustMap(
          (
            consultations: readonly ConsultationDto[] | null,
          ): Observable<readonly Consultation[]> =>
            (consultations?.length ?? 0) === 0
              ? of([])
              : forkJoin(
                  (consultations ?? []).map(
                    (consultation: ConsultationDto): Observable<Consultation> =>
                      ejabberd$.pipe(
                        exhaustMap(
                          (): Promise<ChatHistory> =>
                            this.#ejabberdClient.handle.getChatHistory(
                              this.#createJid(consultation.username),
                              {
                                max: 1,
                              },
                            ),
                        ),
                        map((chatHistory: ChatHistory): Consultation => {
                          return {
                            consultant: {
                              id: consultation.consultant_id,
                              avatar: consultation.avatar,
                              username: consultation.username,
                              firstname: consultation.first_name,
                              lastname: consultation.last_name,
                            },
                            statistics: createConsultationStatistics(
                              chatHistory,
                              consultation.last_read_timestamp,
                            ),
                            hasUnreadMessage:
                              this.#createJid(
                                consultation.username,
                              ).toLowerCase() ===
                                chatHistory.messages
                                  .at(-1)
                                  ?.from.toLowerCase() &&
                              compareDates(
                                chatHistory.messages.at(-1)?.timestamp ?? null,
                                consultation.last_read_timestamp
                                  ? new Date(consultation.last_read_timestamp)
                                  : null,
                              ) === 1,
                          };
                        }),
                        tap({
                          next: (consultation: Consultation): void => {
                            this.#ejabberdClient.handle.sendPresence(
                              this.#createJid(consultation.consultant.username),
                            );
                          },
                        }),
                      ),
                  ),
                ),
        ),
      );
  }

  reportNewMessage(consultantId: string): Observable<void> {
    return this.#httpClient.post<void>(
      'consultation/new-customer-message',
      {
        consultant_id: consultantId,
      },
      {
        withCredentials: true,
      },
    );
  }

  sendConsultationAcknowledgement(consultantId: string): Observable<void> {
    return this.#httpClient.post<void>(
      'consultations/customer-last-read',
      {
        consultant_id: consultantId,
      },
      {
        withCredentials: true,
      },
    );
  }

  sendConsultationPresence(contactUserName: string): Observable<void> {
    return this.#waitForEjabberd().pipe(
      tap({
        next: (): void =>
          this.#ejabberdClient.handle.sendPresence(
            this.#createJid(contactUserName),
          ),
      }),
    );
  }

  #createJid(username: string): `${string}@${string}` {
    return `${username}@${this.#consultationConfig.host}`;
  }

  #waitForEjabberd(): Observable<void> {
    return this.#ejabberdConnectionOnHold.pipe(
      filter((onHold: boolean): onHold is false => !onHold),
      first(),
      exhaustMap(
        (): Observable<readonly [boolean, boolean]> =>
          combineLatest([
            this.#ejabberdClient.isConnecting$,
            this.#ejabberdClient.isConnected$,
          ]).pipe(
            filter(
              ([isConnecting]: readonly [boolean, boolean]): boolean =>
                !isConnecting,
            ),
            first(),
          ),
      ),
      exhaustMap(
        ([_isConnecting, isConnected]: readonly [
          boolean,
          boolean,
        ]): Observable<void> => {
          if (isConnected) {
            return of(undefined);
          }

          return throwError((): unknown => 'Ejabberd is not connected');
        },
      ),
    );
  }
}
