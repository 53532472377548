<ion-header *transloco="let t">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="goBack()">
        <ion-icon name="chevron-back-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ t('password-reset.email.header.title') }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content *transloco="let t">
  <ion-grid *rxLet="state$; let state" [fixed]="true">
    <ion-row class="ion-justify-content-center">
      <ion-col>
        <div class="p-6 content">
          <p class="mb-6 mt-0">
            {{ t('password-reset.email.description') }}
          </p>

          <form (ngSubmit)="submit()" [formGroup]="mailDataFormGroup">
            <ion-list>
              <mbeon-pwa-input-form-field
                [clearInput]="true"
                [control]="mailDataFormGroup.controls.email"
                [hasError]="
                  state.emailInvalid ||
                  mailDataFormGroup.controls.email.errors?.['emailInvalid']
                "
                [label]="t('input.email.label')"
                class="mb-5 block"
              ></mbeon-pwa-input-form-field>
              <div class="input-error-message mb-2 mt-1 pl-4">
                <p
                  *ngIf="
                    state.emailInvalid ||
                    (mailDataFormGroup.controls.email.errors?.[
                      'emailInvalid'
                    ] &&
                      mailDataFormGroup.controls.email.value)
                  "
                >
                  {{ t('input.email.invalid') }}
                </p>
              </div>
            </ion-list>
            <ion-button
              [disabled]="mailDataFormGroup.invalid"
              class="button_primary mb-7 mt-4"
              type="submit"
              >{{ t('password-reset.email.button.next') }}
            </ion-button>
          </form>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
