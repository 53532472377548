import {
  ApproveDeleteTokenError,
  DeleteProfileError,
  NewPasswordErrors,
  ProfileRepository,
  UnknownError,
  UserProfile,
} from '@mbeon-pwa/domain';
import { catchError, map, type Observable, of, throwError } from 'rxjs';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import type { UserDTO } from '../../../authentication/types/user-dto.type';
import { LOCAL_STORAGE } from '../../../core/values/local-storage.injection-token';
import type { NewPasswordErrorDto } from '../../entities/new-password-error-dto.type';
import type { ApproveDeleteProfileTokenErrorDto } from '../../entities/approve-delete-profile-token-error-dto.type';
import type { DeleteProfileErrorDto } from '../../entities/delete-profile-error-dto.type';

import { getDeleteProfileError } from './helpers/get-delete-profile-error/get-delete-profile-error';
import { getApproveDeleteAccountTokenError } from './helpers/get-approve-delete-account-token-error/get-approve-delete-account-token-error';
import { getNewPasswordErrors } from './helpers/get-new-password-error/get-new-password-error';

@Injectable()
export class ProfileRepositoryImpl implements ProfileRepository {
  private static readonly pushEnabledKey = 'pushEnabled' as const;

  readonly #httpClient: HttpClient = inject(HttpClient);

  readonly #localStorage: Storage = inject(LOCAL_STORAGE);

  addPushNotificationsToken(token: string): Observable<void> {
    this.#localStorage.setItem(ProfileRepositoryImpl.pushEnabledKey, '1');

    return this.#httpClient.post<void>(
      'customer/push-notification/enable',
      {
        token,
      },
      {
        withCredentials: true,
      },
    );
  }

  deletePushNotificationsToken(token: string): Observable<void> {
    this.#localStorage.removeItem(ProfileRepositoryImpl.pushEnabledKey);

    return this.#httpClient.post<void>(
      'customer/push-notification/disable',
      {
        token,
      },
      {
        withCredentials: true,
      },
    );
  }

  isPushNotificationsEnabled(): Observable<boolean> {
    return of(
      this.#localStorage.getItem(ProfileRepositoryImpl.pushEnabledKey) !== null,
    );
  }

  getProfile(): Observable<UserProfile> {
    return this.#httpClient
      .get<UserDTO>(`customer/profile`, {
        withCredentials: true,
      })
      .pipe(
        map((UserDTO: UserDTO): UserProfile => {
          return {
            username: UserDTO.username,
            email: UserDTO.email,
            locale: UserDTO.profile?.locale,
          };
        }),
      );
  }

  deleteProfile(token: string): Observable<void> {
    return this.#httpClient
      .post<void>(
        `customer/account-delete`,
        {
          token: token,
        },
        {
          withCredentials: true,
        },
      )
      .pipe(
        catchError((error: unknown) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 422) {
              const errors: DeleteProfileErrorDto | undefined = error.error
                ?.errors as DeleteProfileErrorDto;

              if (errors) {
                return throwError(
                  (): DeleteProfileError => getDeleteProfileError(errors),
                );
              }
            }
          }

          return throwError(() => new UnknownError());
        }),
      );
  }

  initDeleteProfile(): Observable<void> {
    return this.#httpClient.post<void>(
      `customer/account-delete/init`,
      {
        user_init: true,
      },
      {
        withCredentials: true,
      },
    );
  }

  approveDeleteAccountToken(token: string): Observable<void> {
    return this.#httpClient
      .post<void>(
        `customer/account-delete/verify-token`,
        {
          token: token,
        },
        {
          withCredentials: true,
        },
      )
      .pipe(
        catchError((error: unknown) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 422) {
              const errors: ApproveDeleteProfileTokenErrorDto | undefined =
                error.error?.errors as ApproveDeleteProfileTokenErrorDto;

              if (errors) {
                return throwError(
                  (): ApproveDeleteTokenError =>
                    getApproveDeleteAccountTokenError(errors),
                );
              }
            }
          }

          return throwError(() => new UnknownError());
        }),
      );
  }

  setNewPassword(
    oldPassword: string,
    newPassword: string,
    passwordConfirmation: string,
  ): Observable<void> {
    return this.#httpClient
      .post<void>(
        'customer/password',
        {
          current_password: oldPassword,
          password: newPassword,
          password_confirmation: passwordConfirmation,
        },
        {
          withCredentials: true,
        },
      )
      .pipe(
        catchError((error: unknown) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 422) {
              const errors: NewPasswordErrorDto | undefined = error.error
                ?.errors as NewPasswordErrorDto;

              if (errors) {
                return throwError(
                  (): NewPasswordErrors => getNewPasswordErrors(errors),
                );
              }
            }
          }

          return throwError((): UnknownError => new UnknownError());
        }),
      );
  }
}
