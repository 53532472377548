import { ApproveCommunicationChannelTokenError } from '@mbeon-pwa/domain';

import type { ApproveCommunicationChannelTokenErrorDTO } from '../../../../types/approve-communication-channel-token-error-dto.type';

export function getApproveCommunicationChannelTokenErrors(
  responseErrors: ApproveCommunicationChannelTokenErrorDTO,
): ApproveCommunicationChannelTokenError {
  const tokenErrors: boolean = (responseErrors.token ?? '').length > 0;

  return new ApproveCommunicationChannelTokenError({
    tokenForPasswordResetBelongsToDeletedUser:
      tokenErrors &&
      responseErrors.token.includes(
        'TOKEN_FOR_PASSWORD_RESET_BELONGS_TO_DELETED_USER',
      ),
    tokenForPasswordResetHasExpired:
      tokenErrors &&
      responseErrors.token.includes('TOKEN_FOR_PASSWORD_RESET_HAS_EXPIRED'),
    tokenForPasswordResetNotFound:
      tokenErrors &&
      responseErrors.token.includes('TOKEN_FOR_PASSWORD_RESET_NOT_FOUND'),
    tokenContainsSpacesOrTabs:
      tokenErrors &&
      responseErrors.token.includes('TOKEN_CONTAINS_SPACES_OR_TABS'),
    tokenForCommunicationChannelApprovalBelongsToAlreadyApprovedCommunicationChannel:
      tokenErrors &&
      responseErrors.token.includes(
        'TOKEN_FOR_COMMUNICATION_CHANNEL_APPROVAL_BELONGS_TO_ALREADY_APPROVED_COMMUNICATION_CHANNEL',
      ),
    tokenForCommunicationChannelApprovalHasExpired:
      tokenErrors &&
      responseErrors.token.includes(
        'TOKEN_FOR_COMMUNICATION_CHANNEL_APPROVAL_HAS_EXPIRED',
      ),
    tokenForCommunicationChannelApprovalIsValidButApprovalProcessFailed:
      tokenErrors &&
      responseErrors.token.includes(
        'TOKEN_FOR_COMMUNICATION_CHANNEL_APPROVAL_IS_VALID_BUT_APPROVAL_PROCESS_FAILED',
      ),
    tokenForCommunicationChannelApprovalNotFound:
      tokenErrors &&
      responseErrors.token.includes(
        'TOKEN_FOR_COMMUNICATION_CHANNEL_APPROVAL_NOT_FOUND',
      ),
    tokenIsNotAString:
      tokenErrors && responseErrors.token.includes('TOKEN_IS_NOT_A_STRING'),
    tokenIsEmpty:
      tokenErrors && responseErrors.token.includes('TOKEN_IS_EMPTY'),
  });
}
