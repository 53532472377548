<ion-header *transloco="let t">
  <ion-toolbar>
    <ion-title>{{ t('password-reset.success.header.title') }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content *transloco="let t">
  <div class="p-8 full-view-content">
    <div class="content">
      <img alt="" class="mb-3_5" src="/assets/img/check-success.svg" />
      <h2 class="mt-0 mb-2">{{ t('password-reset.success.title') }}</h2>
      <p class="mb-6 mt-0">
        {{ t('password-reset.success.description') }}
      </p>
      <ion-button (click)="close()" class="button_primary">
        {{ t('password-reset.success.button.close') }}
      </ion-button>
    </div>
  </div>
</ion-content>
