import { type AbstractControl, type ValidationErrors } from '@angular/forms';

import {
  isPasswordValid,
  pwContainsLargeLetters,
  pwMaxLength,
  pwMinLength,
  pwContainsWhiteSpace,
  pwContainsNumbers,
  pwContainsSmallLetters,
  pwContainsSpecialCharacters,
  pwContainsOnlyValidCharacters,
} from '@mbeon-pwa/domain';

import type { StrongPassword } from './strong-password.type';

export function passwordStrengthValidator(
  control: AbstractControl,
): ValidationErrors | null {
  const pw: unknown = control.value;

  if (typeof pw !== 'string') {
    return {
      number: false,
      special: false,
      smallLetters: false,
      largeLetters: false,
      whiteSpace: false,
      minLength: false,
      maxLength: false,
      onlyValidCharacters: false,
    };
  }

  const pwCheck: boolean = isPasswordValid(pw);

  const pwCheckModel: StrongPassword = {
    number: pwContainsNumbers(pw),
    special: pwContainsSpecialCharacters(pw),
    smallLetters: pwContainsSmallLetters(pw),
    largeLetters: pwContainsLargeLetters(pw),
    whiteSpace: pwContainsWhiteSpace(pw),
    minLength: pwMinLength(pw),
    maxLength: pwMaxLength(pw),
    onlyValidCharacters: pwContainsOnlyValidCharacters(pw),
  };

  return pwCheck ? null : pwCheckModel;
}
