import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  NgZone,
  type OnInit,
  ViewChild,
} from '@angular/core';
import { Router, RouterLinkWithHref } from '@angular/router';

import {
  IonAlert,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonToggle,
} from '@ionic/angular/standalone';

import {
  LoginApplicationService,
  ProfileApplicationService,
  type UserProfile,
} from '@mbeon-pwa/domain';

import { TranslocoModule } from '@ngneat/transloco';

import { RxState } from '@rx-angular/state';
import { RxLet } from '@rx-angular/template/let';
import { RxPush } from '@rx-angular/template/push';

import { addIcons } from 'ionicons';
import {
  informationCircle,
  informationCircleOutline,
  language,
  lockClosed,
  logOut,
  notifications,
  people,
  person,
  receipt,
  shieldCheckmark,
  trashBin,
} from 'ionicons/icons';

import { defer, lastValueFrom, map, type Observable } from 'rxjs';

import { AppState } from '../../../../core/state/app.state';
import { PushNotifications } from '../../../../core/services/push-notifications/push-notifications';

import { DeleteAccountState } from '../../states/delete-account/delete-account.state';

import type { ProfileComponentState } from './profile-component.state';

@Component({
  selector: 'mbeon-pwa-profile',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    IonAlert,
    IonContent,
    IonIcon,
    IonList,
    IonItem,
    IonToggle,
    IonLabel,
    IonCol,
    IonGrid,
    IonRow,
    RouterLinkWithHref,
    RxLet,
    RxPush,
    TranslocoModule,
  ],
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  viewProviders: [RxState],
})
export class ProfileComponent implements OnInit {
  @ViewChild(IonToggle, {
    read: IonToggle,
    static: false,
  })
  readonly pushPermissionCheckbox?: IonToggle;

  readonly state$: Observable<ProfileComponentState> = defer(
    (): Observable<ProfileComponentState> => this.#state.select(),
  );

  readonly #appState: AppState = inject(AppState);

  readonly #deleteAccState: DeleteAccountState = inject(DeleteAccountState);

  readonly #loginApplicationService: LoginApplicationService = inject(
    LoginApplicationService,
  );

  readonly #ngZone: NgZone = inject(NgZone);

  readonly #pushNotifications: PushNotifications = inject(PushNotifications);

  readonly #profileApplicationService: ProfileApplicationService = inject(
    ProfileApplicationService,
  );

  readonly #router: Router = inject(Router);

  readonly #state: RxState<ProfileComponentState> =
    inject<RxState<ProfileComponentState>>(RxState);

  constructor() {
    addIcons({
      person,
      notifications,
      lockClosed,
      informationCircle,
      people,
      shieldCheckmark,
      receipt,
      informationCircleOutline,
      logOut,
      trashBin,
      language,
    });

    this.deleteAccount = this.deleteAccount.bind(this);

    this.#state.set({
      displayAccountDeletionAlert: false,
      displayNotificationsAlert: false,
      pushNotificationsToggleInAction: false,
    });
  }

  ngOnInit(): void {
    this.#state.connect(
      this.#pushNotifications.canBeActivated$.pipe(
        map(
          (
            canActivatePushNotifications: boolean,
          ): Partial<ProfileComponentState> => ({
            canActivatePushNotifications,
          }),
        ),
      ),
    );

    this.#state.connect(
      this.#pushNotifications.canRetrievePushNotifications$.pipe(
        map(
          (
            canRetrievePushNotifications: boolean,
          ): Partial<ProfileComponentState> => ({
            canRetrievePushNotifications,
          }),
        ),
      ),
    );

    this.#state.connect(
      this.#appState.userProfile$.pipe(
        map(
          (userProfile: UserProfile): Partial<ProfileComponentState> => ({
            userProfile,
          }),
        ),
      ),
    );
  }

  async deleteAccount(): Promise<void> {
    await this.#ngZone.run(async (): Promise<void> => {
      try {
        await lastValueFrom(
          this.#profileApplicationService.initializeProfileDeletion(),
        );
        this.#deleteAccState.setLinkHasBeenSent(true);

        await this.#router.navigate(['delete-account-link-has-been-sent']);
      } catch (e) {
        await this.#router.navigate(['non-specific-error']);
      } finally {
        this.toggleAccountDeletionAlert(false);
      }
    });
  }

  async logout(): Promise<void> {
    await lastValueFrom(this.#loginApplicationService.logout());
    await this.#router.navigate(['login']);
  }

  toggleAccountDeletionAlert(displayAccountDeletionAlert: boolean): void {
    this.#state.set({
      displayAccountDeletionAlert,
    });
  }

  toggleNotificationsActivationAlert(displayNotificationsAlert: boolean): void {
    this.#state.set({
      displayNotificationsAlert,
    });
  }

  async togglePushNotificationPermission(setActive: boolean): Promise<void> {
    this.#state.set({
      pushNotificationsToggleInAction: true,
    });

    try {
      if (setActive) {
        await this.#pushNotifications.enable(false);
      } else {
        await this.#pushNotifications.disable();
      }
    } catch (e: unknown) {
      if (this.pushPermissionCheckbox) {
        this.pushPermissionCheckbox.checked = false;
      }
    } finally {
      this.#state.set({
        pushNotificationsToggleInAction: false,
      });
    }
  }
}
