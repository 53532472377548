import { ModuleWithProviders, NgModule } from '@angular/core';

import {
  ConsultationRepository,
  ConsultantRepository,
  ConsultationFilesRepository,
} from '@mbeon-pwa/domain';

import { ConsultantRepositoryImpl } from './repositories/consultant/consultant.repository';
import { ConsultationRepositoryImpl } from './repositories/consultation/consultation.repository';
import { ConsultationFilesRepositoryImpl } from './repositories/consultation-files/consultation-files.repository';
import { ConsultationConfig } from './types/consultation-config.type';

@NgModule()
export class ConsultationModule {
  static forRoot(
    consultationConfig: ConsultationConfig,
  ): ModuleWithProviders<ConsultationModule> {
    return {
      ngModule: ConsultationModule,
      providers: [
        {
          provide: ConsultationConfig,
          useValue: consultationConfig,
        },
        {
          provide: ConsultantRepository,
          useClass: ConsultantRepositoryImpl,
        },
        {
          provide: ConsultationRepository,
          useClass: ConsultationRepositoryImpl,
        },
        {
          provide: ConsultationFilesRepository,
          useClass: ConsultationFilesRepositoryImpl,
        },
      ],
    };
  }
}
