import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonList,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

import { TranslocoModule } from '@ngneat/transloco';

import {
  AuthenticationApplicationService,
  ResetPasswordInitErrors,
} from '@mbeon-pwa/domain';

import { firstValueFrom, Observable } from 'rxjs';

import { RxLet } from '@rx-angular/template/let';

import { NgIf } from '@angular/common';

import { addIcons } from 'ionicons';

import { chevronBackOutline, close } from 'ionicons/icons';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { InputFormFieldComponent } from '../../../../common/components/inputs/input-form-field/input-form-field.component';
import { PasswordResetState } from '../../state/password-restet.state';
import { PasswordResetState as State } from '../../state/password-reset-state.type';
import { NavBackButtonComponent } from '../../../../common/components/buttons/nav-back-button/nav-back-button.component';
import { emailValidation } from '../../../authentication/validators/email-validator/email-validator';

@UntilDestroy()
@Component({
  selector: 'mbeon-pwa-password-reset-mail',
  standalone: true,
  imports: [
    InputFormFieldComponent,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonList,
    IonTitle,
    IonToolbar,
    ReactiveFormsModule,
    TranslocoModule,
    IonCol,
    IonGrid,
    IonRow,
    RxLet,
    NgIf,
    IonIcon,
    NavBackButtonComponent,
    IonBackButton,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './password-reset-mail.component.html',
  styleUrls: ['./password-reset-mail.component.scss'],
})
export class PasswordResetMailComponent {
  readonly mailDataFormGroup: FormGroup<{
    readonly email: FormControl<string | null>;
  }> = new FormGroup({
    email: new FormControl<string | null>('', [
      Validators.required,
      emailValidation,
    ]),
  });

  readonly #passwordResetState: PasswordResetState = inject(PasswordResetState);

  readonly #authenticationApplicationService: AuthenticationApplicationService =
    inject(AuthenticationApplicationService);

  readonly #router: Router = inject(Router);

  constructor() {
    this.mailDataFormGroup.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.#passwordResetState.set({
          emailInvalid: false,
        });
      });
    addIcons({ close, chevronBackOutline });
  }

  get state$(): Observable<State> {
    return this.#passwordResetState.select();
  }

  async submit(): Promise<void> {
    if (this.mailDataFormGroup.valid) {
      try {
        await firstValueFrom(
          this.#authenticationApplicationService.sendNewPasswordRequest(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.mailDataFormGroup.controls.email.value!,
          ),
        );

        this.#passwordResetState.setEmailSended();
        await this.#router.navigate(['password-reset/link-success']);
      } catch (error) {
        if (error instanceof ResetPasswordInitErrors) {
          if (
            error.emailAddressContainsInvalidString &&
            error.emailAddressContainsSpacesOrTabs &&
            error.emailAddressDnsCheckFailed &&
            error.emailAddressIsNotRfcCompliant
          ) {
            this.#passwordResetState.set({ emailInvalid: true });
          }
        } else {
          await this.#router.navigate(['maintenance']);
        }
      }
    }
  }

  async cancel(): Promise<void> {
    await this.#router.navigate(['login']);
  }

  async goBack(): Promise<void> {
    await this.#router.navigate(['reset-credential']);
  }
}
