import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Router } from '@angular/router';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { TranslocoModule } from '@ngneat/transloco';

import { PasswordResetState } from '../../state/password-restet.state';

@Component({
  selector: 'mbeon-pwa-password-reset-success',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    IonButton,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonCol,
    IonGrid,
    IonRow,
    TranslocoModule,
  ],
  templateUrl: './password-reset-success.component.html',
  styleUrls: ['./password-reset-success.component.scss'],
})
export class PasswordResetSuccessComponent {
  readonly #router: Router = inject(Router);

  readonly #passwordResetState: PasswordResetState = inject(PasswordResetState);

  async close(): Promise<void> {
    this.#passwordResetState.reset();
    await this.#router.navigate(['login']);
  }
}
