import { first, map, Observable } from 'rxjs';

import { inject } from '@angular/core';

import { Router, UrlTree } from '@angular/router';

import { PasswordResetState } from '../../state/password-restet.state';
import { PasswordResetState as State } from '../../state/password-reset-state.type';

export const passwordResetLinkInValidGuard: () => Observable<
  true | UrlTree
> = (): Observable<true | UrlTree> => {
  const passwordResetState: PasswordResetState = inject(PasswordResetState);
  const router: Router = inject(Router);

  return passwordResetState.select().pipe(
    first(),
    map((resetPasswordState: State): true | UrlTree => {
      return (
        !resetPasswordState.resetLinkValid ||
        router.parseUrl('password-reset/mail')
      );
    }),
  );
};
