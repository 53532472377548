import type { Builder } from 'strophe.js';

import type { Message } from '../../types/message.type';
import type { StropheJS } from '../../types/strophe-js.type';
import { receiptsNamespace } from '../../values/namespaces.values';

export function createChatMessage(
  stropheJS: StropheJS,
  message: Omit<Message, 'from' | 'timestamp' | 'isArchived'>,
): Builder {
  const messageBuilder: Builder = stropheJS.$msg({
    id: message.id,
    to: message.to,
    type: 'chat',
  });

  if ('body' in message && message.body) {
    messageBuilder.c('body').t(message.body).up();
  }

  // add XEP-0184 receipts request
  messageBuilder
    .c('request', {
      xmlns: stropheJS.Strophe.NS[receiptsNamespace],
    })
    .up();

  if (message.files.length > 0) {
    for (const file of message.files) {
      messageBuilder.c('file-sharing', {
        xmlns: 'urn:xmpp:sfs:0',
        disposition: file.disposition,
      });

      messageBuilder.c('file', {
        xmlns: 'urn:xmpp:file:metadata:0',
      });

      if ('description' in file && file.description) {
        messageBuilder.c('desc').t(file.description).up();
      }

      if ('dimensions' in file && file.dimensions) {
        messageBuilder.c('dimensions').t(file.dimensions).up();
      }

      if ('mediaType' in file && file.mediaType) {
        messageBuilder.c('media-type').t(file.mediaType).up();
      }

      if ('name' in file && file.name) {
        messageBuilder.c('name').t(file.name).up();
      }

      if ('size' in file && typeof file.size === 'number') {
        messageBuilder.c('size').t(file.size.toString(10)).up();
      }

      messageBuilder.up();

      if ('source' in file && file.source) {
        messageBuilder
          .c('sources')
          .c('url-data', {
            xmlns: 'http://jabber.org/protocol/url-data',
            target: file.source,
          })
          .up()
          .up();
      }
      messageBuilder.up();
    }
  }

  return messageBuilder;
}
