<ng-container *transloco="let t">
  <ion-content>
    <div class="logo-header">
      <img alt="" class="my-1_5" src="/assets/img/logo-small.svg" />
    </div>
    <ng-container *rxLet="state$; let state">
      <div class="p-4">
        <ion-grid [fixed]="true">
          <ion-row class="ion-justify-content-center">
            <ion-col>
              <h2 class="mt-0 mb-1">{{ t('login.title') }}</h2>
              <p class="mb-6 mt-0">{{ t('login.description') }}</p>
              <div *ngIf="state.showLoginError" class="info-message error mb-4">
                <ion-icon name="alert-circle"></ion-icon>
                <p>{{ t('login.message.error') }}</p>
              </div>
              <form (ngSubmit)="submit()" [formGroup]="loginForm">
                <ion-list>
                  <mbeon-pwa-input-form-field
                    [clearInput]="true"
                    [control]="loginForm.controls.username"
                    [hasError]="state.showLoginError"
                    [label]="t('input.username.label')"
                    class="mb-4 block"
                  ></mbeon-pwa-input-form-field>
                  <mbeon-pwa-input-form-field
                    [control]="loginForm.controls.password"
                    [hasError]="state.showLoginError"
                    [label]="t('input.password.label')"
                    class="mb-4 block"
                    type="password"
                  ></mbeon-pwa-input-form-field>
                </ion-list>
                <ion-item class="custom-checkbox">
                  <ion-checkbox
                    labelPlacement="end"
                    [attr.aria-label]="t('login.form.keep-logged-in.label')"
                    formControlName="keepLoggedIn"
                    id="keepLoggedin"
                    slot="start"
                    >{{ t('login.form.keep-logged-in.label') }}
                  </ion-checkbox>
                </ion-item>
                <ion-button
                  [disabled]="
                    (!loginForm.invalid && state.showLoginError) ||
                    (loginForm.invalid && !state.showLoginError)
                  "
                  class="button_primary mb-7 mt-4"
                  type="submit"
                >
                  <ng-container *ngIf="state.showLoadingIndicator">
                    <ion-spinner name="lines-sharp"></ion-spinner>
                  </ng-container>
                  <ng-container *ngIf="!state.showLoadingIndicator"
                    >{{ t('login.form.button.login') }}
                  </ng-container>
                </ion-button>
                <ion-button
                  (click)="forgotCredentials()"
                  class="button_text"
                  fill="clear"
                >
                  {{ t('login.link.forgotten-credentials') }}
                </ion-button>
              </form>

              <div class="content p-6">
                <hr [attr.label]="t('login.divider.label')" class="mb-10" />
              </div>
              <div class="mb-10">
                <ion-button
                  (click)="registerAccount()"
                  class="button_secondary"
                >
                  {{ t('login.button.create-account') }}
                </ion-button>
              </div>
              <p class="text-center">
                <a class="link" [routerLink]="['/imprint']">{{
                  t('login.link.imprint')
                }}</a>
              </p>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ng-container>
  </ion-content>

  <ng-container *rxLet="state$; let state">
    <ion-alert
      (didDismiss)="handleServerErrorPopUp()"
      [buttons]="[
        {
          text: t('login.server-message.button'),
          role: 'confirm'
        }
      ]"
      [header]="t('login.server-message.header')"
      [isOpen]="state.showServerErrorPopUp"
      [message]="t('login.server-message.message')"
    ></ion-alert>
  </ng-container>
</ng-container>
