import type { ChatHistory } from '../../types/chat-history.type';
import type { Message } from '../../types/message.type';
import type { StropheJS } from '../../types/strophe-js.type';

export function transformChatHistory(
  stropheJS: StropheJS,
  stanza: Element,
  messages: readonly Message[],
): ChatHistory {
  const countElement: Element | null = stanza.querySelector('count');
  const total: number = parseInt(
    countElement ? stropheJS.Strophe.getText(countElement) : '0',
    10,
  );

  const firstElement: Element | null = stanza.querySelector('first');
  const firstMessageUid: string | undefined = firstElement
    ? stropheJS.Strophe.getText(firstElement)
    : undefined;

  const lastElement: Element | null = stanza.querySelector('last');
  const lastMessageUid: string | undefined = lastElement
    ? stropheJS.Strophe.getText(lastElement)
    : undefined;

  return {
    total,
    messages,
    firstMessageUid,
    lastMessageUid,
  } satisfies ChatHistory;
}
