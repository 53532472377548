import { DeleteProfileError } from '@mbeon-pwa/domain';

import { DeleteProfileErrorDto } from '../../../../entities/delete-profile-error-dto.type';

export function getDeleteProfileError(
  responseErrors: DeleteProfileErrorDto,
): DeleteProfileError {
  const tokenErrors: boolean = (responseErrors.token ?? '').length > 0;

  return new DeleteProfileError({
    tokenForAccountDeleteHasExpired:
      tokenErrors &&
      responseErrors.token.includes('TOKEN_FOR_ACCOUNT_DELETE_HAS_EXPIRED'),
    tokenForAccountDeleteNotFound:
      tokenErrors &&
      responseErrors.token.includes('TOKEN_FOR_ACCOUNT_DELETE_NOT_FOUND'),
  });
}
