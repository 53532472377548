<ion-tabs *transloco="let t">
  <ion-tab-bar id="tapBar" slot="bottom">
    <ion-tab-button tab="consultations">
      <ion-icon name="chatbubbles"></ion-icon>
      {{ t('tap-bar.chat') }}
    </ion-tab-button>

    <ion-tab-button tab="profile">
      <ion-icon name="person-circle"></ion-icon>
      {{ t('tap-bar.profile') }}
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
