<ng-container *transloco="let t">
  <ion-content [fullscreen]="true" color="light">
    <div class="bg-container">
      <div class="circle-small"></div>
      <div class="circle-medium"></div>
      <div class="circle-large"></div>

      <div class="content">
        <h6>{{ t('profile.title') }}</h6>
        <div class="profile-image">
          <ion-icon color="primary" name="person"></ion-icon>
        </div>
      </div>
    </div>

    <ion-grid *rxLet="state$; let state" [fixed]="true">
      <ion-row class="ion-justify-content-center">
        <ion-col>
          <div class="settings">
            <ion-list [inset]="true">
              <ion-item class="user">
                <ion-icon color="primary" name="person"></ion-icon>
                <dl>
                  <dt>{{ t('profile.user') }}</dt>
                  <dd>{{ state.userProfile.username }}</dd>
                </dl>
              </ion-item>
            </ion-list>

            <h6 class="mt-7 mx-9">{{ t('profile.settings') }}</h6>
            <ion-list [inset]="true">
              <ion-item>
                <div
                  class="toggle-list-item"
                  (click)="
                    !state.canActivatePushNotifications &&
                      toggleNotificationsActivationAlert(true)
                  "
                >
                  <ion-icon color="primary" name="notifications"></ion-icon>
                  <ion-toggle
                    (ionChange)="
                      togglePushNotificationPermission(
                        !state.canRetrievePushNotifications
                      )
                    "
                    [checked]="
                      state.canActivatePushNotifications &&
                      state.canRetrievePushNotifications
                    "
                    [disabled]="
                      state.pushNotificationsToggleInAction ||
                      !state.canActivatePushNotifications
                    "
                  >
                    {{ t('profile.settings.notifications') }}
                  </ion-toggle>
                </div>
              </ion-item>
              <ion-item [routerLink]="['/app/set-password']" detail="true">
                <ion-icon color="primary" name="lock-closed"></ion-icon>
                <ion-label>{{
                  t('profile.settings.change-password')
                }}</ion-label>
              </ion-item>
              <ion-item [routerLink]="['/app/language']" detail="true">
                <ion-icon color="primary" name="language"></ion-icon>
                <ion-label>{{ t('profile.settings.language') }}</ion-label>
              </ion-item>
            </ion-list>

            <h6 class="mt-7 mx-9">{{ t('profile.more-informations') }}</h6>
            <ion-list [inset]="true">
              <ion-item
                [href]="t('profile.more-informations.knowledge.link')"
                detail="true"
                target="_blank"
              >
                <ion-icon color="primary" name="information-circle"></ion-icon>
                <ion-label
                  >{{ t('profile.more-informations.knowledge') }}
                </ion-label>
              </ion-item>
              <ion-item [routerLink]="['/about']" detail="true">
                <ion-icon color="primary" name="people"></ion-icon>
                <ion-label
                  >{{ t('profile.more-informations.about') }}
                </ion-label>
              </ion-item>
              <ion-item [routerLink]="['/data-privacy']" detail="true">
                <ion-icon color="primary" name="shield-checkmark"></ion-icon>
                <ion-label
                  >{{ t('profile.more-informations.data-privacy') }}
                </ion-label>
              </ion-item>
              <ion-item [routerLink]="['/terms-of-use']" detail="true">
                <ion-icon color="primary" name="receipt"></ion-icon>
                <ion-label
                  >{{ t('profile.more-informations.terms-of-use') }}
                </ion-label>
              </ion-item>
              <ion-item [routerLink]="['/imprint']" detail="true">
                <ion-icon color="primary" name="information-circle"></ion-icon>
                <ion-label
                  >{{ t('profile.more-informations.imprint') }}
                </ion-label>
              </ion-item>
            </ion-list>

            <div class="pt-2">
              <ion-list [inset]="true">
                <ion-item (click)="logout()" detail="true">
                  <ion-icon color="primary" name="log-out"></ion-icon>
                  <ion-label color="primary">{{
                    t('profile.logout')
                  }}</ion-label>
                </ion-item>
              </ion-list>
            </div>

            <ion-list [inset]="true">
              <ion-item
                (click)="toggleAccountDeletionAlert(true)"
                detail="true"
              >
                <ion-icon color="danger" name="trash-bin"></ion-icon>
                <ion-label color="danger"
                  >{{ t('profile.delete-account') }}
                </ion-label>
              </ion-item>
            </ion-list>
          </div>

          <!-- TODO  dynamisch machen!  -->
          <p class="version-info">
            {{ t('$build.app-version') }} ({{ t('$build.app-build') }})
          </p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>

  <ion-alert
    *rxLet="state$; let state"
    [buttons]="[
      {
        text: t('general.button.no'),
        role: 'cancel'
      },
      {
        text: t('general.button.yes'),
        role: 'confirm',
        handler: deleteAccount
      }
    ]"
    [header]="t('profile.delete-account-popup.title')"
    [isOpen]="state.displayAccountDeletionAlert"
    [message]="t('profile.delete-account-popup.description')"
    (didDismiss)="toggleAccountDeletionAlert(false)"
  ></ion-alert>

  <ion-alert
    *rxLet="state$; let state"
    [buttons]="[
      {
        text: t('general.button.ok'),
        role: 'cancel'
      }
    ]"
    [isOpen]="state.displayNotificationsAlert"
    [message]="t('profile.notifications-activation-hint')"
    (didDismiss)="toggleNotificationsActivationAlert(false)"
  ></ion-alert>
</ng-container>
