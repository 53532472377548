import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  type OnInit,
  Output,
} from '@angular/core';

import { IonButton, IonIcon, IonModal } from '@ionic/angular/standalone';

import { RxState } from '@rx-angular/state';
import { RxLet } from '@rx-angular/template/let';
import { RxIf } from '@rx-angular/template/if';

import { addIcons } from 'ionicons';
import {
  micOutline,
  pauseCircleOutline,
  playCircleOutline,
} from 'ionicons/icons';

import {
  defer,
  filter,
  fromEvent,
  interval,
  map,
  type Observable,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';

import { TranslocoDirective } from '@ngneat/transloco';

import { ConsultationMessageInputVoiceComponentState } from './consultation-message-input-voice-component.state';

@Component({
  selector: 'mbeon-pwa-consultation-message-input-voice',
  standalone: true,
  imports: [
    CommonModule,
    IonButton,
    IonIcon,
    IonModal,
    RxIf,
    RxLet,
    TranslocoDirective,
  ],
  templateUrl: './consultation-message-input-voice.component.html',
  styleUrl: './consultation-message-input-voice.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [RxState],
})
export class ConsultationMessageInputVoiceComponent
  implements OnInit, OnDestroy
{
  @Input()
  set active(isActive: boolean) {
    this.#state.set({
      isActive,
    });
  }

  @Input()
  set disabled(isDisabled: boolean) {
    this.#state.set({
      isDisabled,
    });
  }

  @Output()
  readonly recording: EventEmitter<Blob> = new EventEmitter();

  readonly state$: Observable<ConsultationMessageInputVoiceComponentState> =
    defer(
      (): Observable<ConsultationMessageInputVoiceComponentState> =>
        this.#state.select(),
    );

  #recording?: Blob;

  readonly #state: RxState<ConsultationMessageInputVoiceComponentState> =
    inject<RxState<ConsultationMessageInputVoiceComponentState>>(RxState);

  constructor() {
    addIcons({
      micOutline,
      pauseCircleOutline,
      playCircleOutline,
    });

    this.#state.set({
      displayRecordingModal: false,
      isActive: false,
      isDisabled: true,
      isRecording: false,
      recordingTime: 0,
    });
  }

  ngOnInit(): void {
    this.#state.connect(
      this.#state.select('isRecording').pipe(
        filter((isRecording: boolean): isRecording is true => isRecording),
        switchMap(
          (): Observable<number> =>
            interval(1000).pipe(
              takeUntil(
                this.#state
                  .select('isRecording')
                  .pipe(
                    filter(
                      (isRecording: boolean): isRecording is false =>
                        !isRecording,
                    ),
                  ),
              ),
            ),
        ),
        map(
          (): Partial<ConsultationMessageInputVoiceComponentState> => ({
            recordingTime: this.#state.get().recordingTime + 1,
          }),
        ),
      ),
    );
  }

  ngOnDestroy(): void {
    this.#recording = undefined;
  }

  abortRecording(): void {
    this.#state.set({
      displayRecordingModal: false,
      isRecording: false,
    });

    this.#recording = undefined;
  }

  async recordVoiceMessage(): Promise<void> {
    this.#state.set({
      displayRecordingModal: true,
      recordingTime: 0,
    });

    try {
      const mediaStream: MediaStream =
        await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });

      this.#state.set({
        isRecording: true,
      });

      const mediaRecorder: MediaRecorder = new MediaRecorder(mediaStream, {
        bitsPerSecond: 128000,
      });

      const endSignal$: Observable<boolean> = this.#state
        .select('displayRecordingModal')
        .pipe(
          filter(
            (displayRecordingModal: boolean): displayRecordingModal is false =>
              !displayRecordingModal,
          ),
          take(1),
        );

      this.#state
        .select('isRecording')
        .pipe(takeUntil(endSignal$))
        .subscribe({
          next: (isRecording: boolean): void => {
            if (isRecording) {
              mediaRecorder.resume();
            } else {
              mediaRecorder.pause();
            }
          },
        });

      const buffer: Blob[] = [];
      fromEvent(mediaRecorder, 'dataavailable')
        .pipe(takeUntil(endSignal$))
        .subscribe((event: Event): void => {
          buffer.push((event as BlobEvent).data);
        });

      endSignal$.subscribe({
        next: (): void => {
          mediaRecorder.stop();

          for (const stream of mediaStream.getTracks()) {
            stream.stop();
          }

          this.#recording = new Blob(buffer, {
            type: mediaRecorder.mimeType,
          });
        },
      });

      // emit blobs every 50 milliseconds
      mediaRecorder.start(50);
    } catch (e) {
      this.#state.set({
        displayRecordingModal: false,
        isRecording: false,
        recordingTime: 0,
      });
    }
  }

  async sendRecording(): Promise<void> {
    this.#state.set({
      displayRecordingModal: false,
    });

    this.recording.emit(this.#recording!);
  }

  toggleRecording(isRecording: boolean): void {
    this.#state.set({
      isRecording,
    });
  }
}
