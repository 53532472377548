import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { LoginApplicationService, UnauthorizedAccess } from '@mbeon-pwa/domain';

import { TranslocoModule } from '@ngneat/transloco';

import { RxState } from '@rx-angular/state';
import { RxLet } from '@rx-angular/template/let';

import { lastValueFrom, Observable } from 'rxjs';

import {
  IonAlert,
  IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonRow,
  IonSpinner,
  NavController,
} from '@ionic/angular/standalone';

import { addIcons } from 'ionicons';
import { alertCircle } from 'ionicons/icons';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { RouterLink } from '@angular/router';

import { InputFormFieldComponent } from '../../../../common/components/inputs/input-form-field/input-form-field.component';

export interface LoginComponentState {
  readonly showLoginError: boolean;
  readonly showLoadingIndicator: boolean;
  readonly showServerErrorPopUp: boolean;
}

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'mbeon-pwa-login',
  imports: [
    FormsModule,
    InputFormFieldComponent,
    IonAlert,
    IonButton,
    IonCheckbox,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonList,
    IonSpinner,
    NgIf,
    ReactiveFormsModule,
    RxLet,
    TranslocoModule,
    IonCol,
    IonGrid,
    IonRow,
    RouterLink,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  viewProviders: [
    {
      provide: RxState,
      useFactory: (): RxState<LoginComponentState> => new RxState(),
    },
  ],
})

// create login component
export class LoginComponent {
  readonly loginForm: FormGroup<{
    readonly username: FormControl<string | null>;
    readonly password: FormControl<string | null>;
    readonly keepLoggedIn: FormControl<boolean | null>;
  }> = new FormGroup({
    username: new FormControl<string>('', [Validators.required]),
    password: new FormControl<string>('', [Validators.required]),
    keepLoggedIn: new FormControl<boolean>(true),
  });

  readonly #loginApplicationService: LoginApplicationService = inject(
    LoginApplicationService,
  );

  readonly #navController: NavController = inject(NavController);

  readonly #state: RxState<LoginComponentState> =
    inject<RxState<LoginComponentState>>(RxState);

  constructor() {
    addIcons({
      alertCircle,
    });

    this.#state.set({
      showLoginError: false,
      showLoadingIndicator: false,
      showServerErrorPopUp: false,
    });

    this.loginForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.#state.set({
        showLoginError: false,
      });
    });
  }

  get state$(): Observable<LoginComponentState> {
    return this.#state.select();
  }

  async registerAccount(): Promise<void> {
    await this.#navController.navigateForward(['register/account-data']);
  }

  async forgotCredentials(): Promise<void> {
    await this.#navController.navigateForward(['reset-credential']);
  }

  async submit(): Promise<void> {
    if (this.loginForm.valid) {
      this.#state.set({
        showLoadingIndicator: true,
      });

      try {
        await lastValueFrom(
          // included non null assertion because form is only valid when values are truthy
          /* eslint-disable @typescript-eslint/no-non-null-assertion*/
          this.#loginApplicationService.login(
            this.loginForm.value.username!,
            this.loginForm.value.password!,
            !!this.loginForm.value.keepLoggedIn,
          ),
          /* eslint-enable */
        );

        this.loginForm.reset();
        this.loginForm.controls.keepLoggedIn.setValue(true);

        await this.#navController.navigateRoot(['app/consultations']);
      } catch (error) {
        if (error instanceof UnauthorizedAccess) {
          this.#state.set({
            showLoginError: true,
          });
        } else {
          this.#state.set({
            showServerErrorPopUp: true,
          });
        }
      } finally {
        this.#state.set({
          showLoadingIndicator: false,
        });
      }
    }
  }

  handleServerErrorPopUp(): void {
    this.#state.set({
      showServerErrorPopUp: false,
    });
  }
}
