import { Injectable } from '@angular/core';

@Injectable()
export class MemoryStorage implements Storage {
  readonly #data: Map<string, string> = new Map();

  get length(): number {
    return Object.keys(this.#data).length;
  }

  clear(): void {
    this.#data.clear();
  }

  getItem(key: string): string | null {
    return this.#data.get(key) ?? null;
  }

  key(index: number): string | null {
    return Array.from(this.#data.keys())[index] ?? null;
  }

  removeItem(key: string): void {
    this.#data.delete(key);
  }

  setItem(key: string, value: string): void {
    this.#data.set(key, value);
  }
}
