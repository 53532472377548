import {
  type AbstractControl,
  type ValidationErrors,
  type ValidatorFn,
} from '@angular/forms';

export function passwordRepeatValidator(): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const password: string | undefined = formGroup.get('password')?.value;
    const confirmPassword = formGroup.get('confirmPassword')?.value;

    return password === confirmPassword ? null : { mismatch: true };
  };
}
