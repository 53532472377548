<ion-header *transloco="let t">
  <ion-toolbar>
    <ion-title>{{ t('password-reset.link-success.header.title') }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content *transloco="let t">
  <ion-grid [fixed]="true">
    <ion-row class="ion-justify-content-center">
      <ion-col>
        <div class="p-8 full-view-content">
          <div>
            <h2 class="mt-0 mb-2">
              {{ t('password-reset.link-success.title') }}
            </h2>
            <p
              [innerHTML]="t('password-reset.link-success.description')"
              class="mb-6 mt-0"
            ></p>
          </div>
          <ion-button (click)="close()" class="button_primary">
            {{ t('password-reset.link-success.button.return-to-page') }}
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
