import {
  DatePipe,
  NgForOf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
} from '@angular/common';
import {
  type AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  inject,
  input,
  InputSignal,
  type OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  HostBinding,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';

import {
  IonAvatar,
  IonButton,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonText,
} from '@ionic/angular/standalone';

import { PLATFORM_IDENTIFIER, PlatformIdentifier } from '@mbeon-pwa/common';
import {
  type Consultant,
  ConsultationApplicationService,
  type ConsultationMessage,
} from '@mbeon-pwa/domain';

import { TranslocoDirective } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';

import { RxState } from '@rx-angular/state';
import { RxFor } from '@rx-angular/template/for';
import { RxIf } from '@rx-angular/template/if';
import { RxLet } from '@rx-angular/template/let';

import { addIcons } from 'ionicons';
import {
  checkmarkOutline,
  checkmarkDoneOutline,
  documentOutline,
  imageOutline,
} from 'ionicons/icons';

import {
  debounceTime,
  defer,
  filter,
  first,
  map,
  type Observable,
  switchMap,
  Subject,
  tap,
  exhaustMap,
  of,
  takeUntil,
} from 'rxjs';

import { TruncatePipe } from '../../../../common/pipes/tuncate/truncate.pipe';
import { ChatThreadFileSizePipe } from '../../pipes/chat-thread-file-size/chat-thread-file-size.pipe';
import { ChatThreadMessageGroupDatePipe } from '../../pipes/chat-thread-message-group/chat-thread-message-group-date.pipe';
import { ChatThreadMessagePipe } from '../../pipes/chat-thread-message/chat-thread-message.pipe';
import {
  CONSULTANT$,
  ConsultationMessagesViewService,
} from '../../service/consultation-messages-view.service';
import type { ChatThreadItem } from '../../types/chat-thread-group.type';
import {
  ConsultationMessagesInfiniteLoaderComponent,
  SCROLL_CONTAINER,
} from '../consultation-messages-infinite-loader/consultation-messages-infinite-loader.component';
import { ConsultationMessagesVoiceMessageComponent } from '../consultation-messages-voice-message/consultation-messages-voice-message.component';

import type { ConsultationMessagesComponentState } from './consultation-messages.component.state.types';

@UntilDestroy()
@Component({
  selector: 'mbeon-pwa-chat-thread-messages',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ChatThreadFileSizePipe,
    ChatThreadMessageGroupDatePipe,
    ChatThreadMessagePipe,
    ConsultationMessagesInfiniteLoaderComponent,
    ConsultationMessagesVoiceMessageComponent,
    DatePipe,
    IonAvatar,
    IonButton,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonText,
    NgForOf,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    RxFor,
    RxIf,
    RxLet,
    TranslocoDirective,
    TruncatePipe,
  ],
  templateUrl: './consultation-messages.component.html',
  styleUrl: './consultation-messages.component.scss',
  viewProviders: [
    {
      provide: CONSULTANT$,
      useFactory: (
        state: RxState<ConsultationMessagesComponentState>,
      ): Observable<Consultant> => state.select('consultant'),
      deps: [RxState],
    },
    {
      provide: SCROLL_CONTAINER,
      useExisting: ElementRef,
    },
    ConsultationMessagesViewService,
    RxState,
  ],
})
export class ConsultationMessagesComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private static readonly acknowledgementDebounceTimeout = 5000 as const;

  @HostBinding('class.lock-scrolling')
  get isLoading(): boolean {
    return this.#state.get()?.disableScrolling ?? true;
  }

  @Output()
  readonly initiallyRendered: EventEmitter<void> = new EventEmitter();

  @ViewChild('bottomMarker', {
    read: ElementRef,
    static: false,
  })
  readonly bottomMarker?: ElementRef;

  @ViewChildren('message', {
    read: ElementRef,
    emitDistinctChangesOnly: true,
  })
  readonly messages!: QueryList<ElementRef>;

  readonly consultant: InputSignal<Consultant> = input.required();

  readonly consultationItems$: Observable<readonly ChatThreadItem[]> = defer(
    (): Observable<readonly ChatThreadItem[]> =>
      this.#consultationMessagesViewService.messages$,
  );

  readonly renderCallback: Subject<readonly ChatThreadItem[]> = new Subject();

  readonly state$: Observable<ConsultationMessagesComponentState> = defer(
    (): Observable<ConsultationMessagesComponentState> => this.#state.select(),
  );

  readonly #changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  readonly #consultationApplicationService: ConsultationApplicationService =
    inject(ConsultationApplicationService);

  readonly #consultationMessagesViewService: ConsultationMessagesViewService =
    inject(ConsultationMessagesViewService);

  readonly #elementRef: ElementRef<HTMLElement> = inject(ElementRef);

  readonly #platformIdentifier: PlatformIdentifier =
    inject(PLATFORM_IDENTIFIER);

  readonly #state: RxState<ConsultationMessagesComponentState> =
    inject<RxState<ConsultationMessagesComponentState>>(RxState);

  readonly #stopSendAcknowledge$: Subject<boolean> = new Subject<boolean>();

  constructor() {
    addIcons({
      checkmarkOutline,
      checkmarkDoneOutline,
      documentOutline,
      imageOutline,
    });

    effect((): void => {
      this.#state.set({
        consultant: this.consultant(),
        initialFetchFinished: false,
        initialRenderFinished: false,
        isFirstBatchDisplayed: true,
        retrievalError: false,
        disableScrolling: false,
      });
    });
  }

  ngOnInit(): void {
    // initial messages
    this.#state.connect(
      this.#state.select('consultant').pipe(
        switchMap(
          (): Observable<unknown> =>
            this.#consultationMessagesViewService.messages$,
        ),
        first(),
        map(
          (): Partial<ConsultationMessagesComponentState> => ({
            isFirstBatchDisplayed: true,
            initialFetchFinished: true,
          }),
        ),
      ),
    );

    // send acknowledgement, buffer 6 seconds
    this.#state.select('consultant').pipe(
      switchMap(
        (consultant: Consultant): Observable<ConsultationMessage> =>
          this.#consultationApplicationService.getConsultationMessageUpdates(
            consultant,
          ),
      ),
      filter(
        (consultationMessage: ConsultationMessage): boolean =>
          // necessary "toLowerCase", since ejabberd only uses lowercase
          consultationMessage.poster.toLowerCase() ===
          this.#state.get('consultant').username.toLowerCase(),
      ),
      debounceTime(
        ConsultationMessagesComponent.acknowledgementDebounceTimeout,
      ),
      switchMap(
        (): Observable<void> =>
          this.#consultationApplicationService.sendConsultationAcknowledgement(
            this.#state.get('consultant'),
          ),
      ),
      takeUntil(this.#stopSendAcknowledge$),
    );
  }

  ngAfterViewInit(): void {
    // scroll to latest message
    if (this.#platformIdentifier.isBrowser) {
      const initial$: Observable<void> =
        this.#consultationMessagesViewService.messages$.pipe(
          first(),
          exhaustMap(
            (
              messages: readonly ChatThreadItem[],
            ): Observable<void> | Observable<undefined> => {
              if (messages.length === 0) {
                return of(undefined);
              }

              return this.messages.changes.pipe(debounceTime(350), first());
            },
          ),
        );

      const changes$: Observable<void> = this.messages.changes.pipe(
        debounceTime(200),
        filter(
          (): boolean =>
            this.#elementRef.nativeElement.scrollTop +
              this.#elementRef.nativeElement.offsetHeight >=
            this.#elementRef.nativeElement.scrollHeight -
              (this.messages.last.nativeElement.offsetHeight + 50),
        ),
      );

      this.#state.hold(changes$, (): void => {
        this.bottomMarker?.nativeElement.scrollIntoView({
          behavior: 'smooth',
        });
      });

      this.#state.hold(
        initial$.pipe(
          tap({
            next: (): void => {
              this.bottomMarker?.nativeElement.scrollIntoView({
                behavior: 'instant',
              });
            },
          }),
        ),
        (): void => {
          this.initiallyRendered.next();
        },
      );
    }
  }

  disableScrolling(disableScrolling: boolean): void {
    this.#state.set({
      disableScrolling,
    });

    this.#changeDetectorRef.markForCheck();
  }

  identifyConsultationItem(_index: number, item: ChatThreadItem): string {
    if ('date' in item) {
      return item.date?.getTime().toString(10) || 'init';
    }

    return item.message?.id ?? 'unknown';
  }

  ngOnDestroy(): void {
    this.#stopSendAcknowledge$.next(true);
  }
}
