<ng-container *transloco="let t">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <mbeon-pwa-nav-back-button></mbeon-pwa-nav-back-button>
      </ion-buttons>
      <ion-title>{{ t('language-switch.header.title') }}</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content *rxLet="activeLang$; let activeLang">
    <ion-grid [fixed]="true">
      <ion-row class="ion-justify-content-center">
        <ion-col>
          <p class="mb-6 mt-0 p-6">
            {{ t('language-switch.info') }}
          </p>
          <ion-list class="pl-2">
            <ion-item
              (click)="switchLanguage(language)"
              *ngFor="let language of availableLanguages"
            >
              <ion-label>{{ t(language) }}</ion-label>
              <ion-icon
                *ngIf="activeLang === language"
                name="checkmark-outline"
              ></ion-icon>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</ng-container>
