import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonTabs,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { TranslocoModule } from '@ngneat/transloco';
import { Router } from '@angular/router';

@Component({
  selector: 'mbeon-pwa-set-password-success',
  standalone: true,
  imports: [
    IonButton,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    TranslocoModule,
    IonTabs,
  ],
  templateUrl: './set-password-success.component.html',
  styleUrl: './set-password-success.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetPasswordSuccessComponent {
  readonly #router: Router = inject(Router);

  async close(): Promise<void> {
    await this.#router.navigate(['login']);
  }
}
