<ng-container *transloco="let t">
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ t('reset-credentials.header.title') }}</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="close()">
          <ion-icon name="close" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content [fullscreen]="true" color="light">
    <ion-grid [fixed]="true">
      <ion-row class="ion-justify-content-center">
        <ion-col>
          <p class="mb-6 mt-0 p-6">{{ t('reset-credentials.description') }}</p>
          <div>
            <ion-list [inset]="true">
              <ion-item (click)="gotToPasswordReset()" button="true">
                <ion-icon color="primary" name="lock-closed"></ion-icon>
                <ion-label>{{
                  t('reset-credentials.link.forgot-password')
                }}</ion-label>
              </ion-item>
            </ion-list>
            <ion-list [inset]="true">
              <ion-item (click)="gotToUserNameReset()" button="true">
                <ion-icon color="primary" name="person"></ion-icon>
                <ion-label>{{
                  t('reset-credentials.link.forgot-username')
                }}</ion-label>
              </ion-item>
            </ion-list>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</ng-container>
