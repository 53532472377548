<ion-header *transloco="let t">
  <ion-toolbar>
    <ion-title>{{ t('register.email-link-expired.title') }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *transloco="let t" [fullscreen]="true">
  <ion-grid [fixed]="true">
    <ion-row class="ion-justify-content-center">
      <ion-col>
        <div *rxLet="state$; let state" class="p-8 container">
          <p class="mt-0 font-semibold">
            {{ t('register.email-link-expired.description') }}
          </p>
          <div>
            <ion-button
              (click)="resendActivationLink()"
              [disabled]="!state.token"
              class="button_primary mb-6"
            >
              {{ t('register.email-link-expired.button.resend') }}
            </ion-button>
            <ion-button (click)="goToLogin()" class="button_secondary mb-8">
              {{ t('register.email-link-expired.button.back-to-login') }}
            </ion-button>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
