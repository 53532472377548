import { type Route } from '@angular/router';

import { notAuthenticatedGuard } from '../../common/guards/not-authenticated/not-authenticated.guard';

import { PasswordResetSuccessComponent } from './pages/password-reset-success/password-reset-success.component';
import { passwordResetEmailSendedGuard } from './guard/password-reset-email-sended/password-reset-email-sended.guard';
import { passwordResetSuccessGuard } from './guard/password-reset-success/password-reset-success.guard';
import { passwordResetLinkInValidGuard } from './guard/password-reset-link-invalid/password-reset-link-invalid.guard';

import { ApprovePasswordResetComponent } from './pages/approve-password-reset/approve-password-reset.component';
import { PasswordResetLinkExpiredComponent } from './pages/password-reset-link-expired/password-reset-link-expired.component';
import { PasswordResetLinkSuccessComponent } from './pages/password-reset-link-success/password-reset-link-success.component';
import { PasswordResetMailComponent } from './pages/password-reset-mail/password-reset-mail.component';
import { PasswordResetSetPasswordComponent } from './pages/password-reset-set-password/password-reset-set-password.component';
import { passwordResetLinkValidGuard } from './guard/password-reset-link-valid/password-reset-link-valid.guard';

export const resetPasswordRoutes: readonly Route[] = [
  {
    path: 'password-reset/mail',
    component: PasswordResetMailComponent,
    canActivate: [notAuthenticatedGuard],
  },
  {
    path: 'password-reset/link-success',
    component: PasswordResetLinkSuccessComponent,
    canActivate: [passwordResetEmailSendedGuard],
  },
  {
    path: 'password-reset/set-password',
    component: PasswordResetSetPasswordComponent,
    canActivate: [passwordResetLinkValidGuard],
  },
  {
    path: 'password-reset/reset-success',
    component: PasswordResetSuccessComponent,
    canActivate: [passwordResetSuccessGuard],
  },
  {
    path: 'password-reset/link-expired',
    component: PasswordResetLinkExpiredComponent,
    canActivate: [passwordResetLinkInValidGuard],
  },
  {
    path: 'approve-data-change/password',
    component: ApprovePasswordResetComponent,
  },
];
