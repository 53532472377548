import { RxState } from '@rx-angular/state';

import { inject, Injectable } from '@angular/core';

import { PLATFORM_IDENTIFIER } from '@mbeon-pwa/common';
import {
  ConnectionStatus,
  ConsultationRepository,
  type UserProfile,
} from '@mbeon-pwa/domain';

import { filter, type Observable } from 'rxjs';

import type { AppState as State } from './app.state.type';

@Injectable({
  providedIn: 'root',
})
export class AppState extends RxState<State> {
  readonly userProfile$: Observable<UserProfile> = this.select(
    'userProfile',
  ).pipe(
    filter(
      (userProfile: UserProfile | null): userProfile is UserProfile =>
        !!userProfile,
    ),
  );

  readonly #consultationRepository: ConsultationRepository = inject(
    ConsultationRepository,
  );

  constructor() {
    super();

    this.set({
      consultationServerConnectionDisconnected: false,
      consultationServerConnectionIsConnecting: false,
      consultationServerConnectionIsConnected: false,
      userProfile: null,
      displayedStatus: 'initial',
    });

    if (inject(PLATFORM_IDENTIFIER).isBrowser) {
      this.connect(
        this.#consultationRepository.getConnectionStatus(),
        (_: State, connectionStatus: ConnectionStatus): Partial<State> => {
          let consultationServerConnectionDisconnected = false;
          let consultationServerConnectionIsConnecting = false;
          let consultationServerConnectionIsConnected = false;

          switch (connectionStatus) {
            case ConnectionStatus.Connected:
              consultationServerConnectionIsConnected = true;
              break;

            case ConnectionStatus.Connecting:
              consultationServerConnectionIsConnecting = true;
              break;

            case ConnectionStatus.Disconnected:
              consultationServerConnectionDisconnected = true;
              break;
          }

          return {
            consultationServerConnectionDisconnected,
            consultationServerConnectionIsConnecting,
            consultationServerConnectionIsConnected,
          };
        },
      );
    }
  }

  setUserProfile(userProfile: UserProfile) {
    this.set({
      userProfile,
    });
  }

  reset(): void {
    this.set({
      userProfile: undefined,
      consultationServerConnectionIsConnecting: false,
      consultationServerConnectionIsConnected: false,
      consultationServerConnectionDisconnected: false,
    });
  }
}
