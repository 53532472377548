import { ApproveDeleteTokenError } from '@mbeon-pwa/domain';

import { ApproveDeleteProfileTokenErrorDto } from '../../../../entities/approve-delete-profile-token-error-dto.type';

export function getApproveDeleteAccountTokenError(
  responseErrors: ApproveDeleteProfileTokenErrorDto,
): ApproveDeleteTokenError {
  const tokenErrors: boolean = (responseErrors.token ?? '').length > 0;

  return new ApproveDeleteTokenError({
    tokenForAccountDeleteHasExpired:
      tokenErrors &&
      responseErrors.token.includes('TOKEN_FOR_ACCOUNT_DELETE_HAS_EXPIRED'),
    tokenForAccountDeleteNotFound:
      tokenErrors &&
      responseErrors.token.includes('TOKEN_FOR_ACCOUNT_DELETE_NOT_FOUND'),
  });
}
