import { Observable, Observer, Subject, Subscription } from 'rxjs';

import type Connection from 'strophe.js/src/types/connection';
import type Handler from 'strophe.js/src/types/handler';

import { createChatMessage } from '../../helpers/create-chat-message/create-chat-message.helper';
import { generateMAMIq } from '../../helpers/generate-mam-iq/generate-mam-iq.helper';
import { transformChatHistory } from '../../helpers/transform-chat-history/transform-chat-history.helper';
import { transformChatMessage } from '../../helpers/transform-chat-message/transform-chat-message.helper';
import type { ChatHistory } from '../../types/chat-history.type';
import type { Message } from '../../types/message.type';
import type { StropheJS } from '../../types/strophe-js.type';
import {
  mamNamespace,
  receiptsNamespace,
} from '../../values/namespaces.values';
import { ChatUpdate } from '../../types/chat-update.type';

export class EjabberdClientHandle {
  readonly #connectionFactory: () => Connection | undefined;

  readonly #internalMessages: Subject<Message> = new Subject();

  readonly #stropheJS: StropheJS;

  constructor(
    stropheJS: StropheJS,
    connectionFactory: () => Connection | undefined,
  ) {
    this.#connectionFactory = connectionFactory;
    this.#stropheJS = stropheJS;
  }

  async getChatHistory(
    recipientJid: string,
    filter?: {
      readonly end?: Date;
      readonly max: number;
    },
  ): Promise<ChatHistory> {
    const userJid: string = this.#getConnection().jid;

    const chatHistory: ChatHistory = await this.#getChatHistory(
      userJid,
      recipientJid,
      filter,
    );

    // auto send acknowledgment for first scouted message by recipient
    if ((filter?.max ?? 0) > 1 && chatHistory.total > 0) {
      const messages: Message[] = [...chatHistory.messages];
      messages.reverse();

      const lastMessageByRecipient: Message | undefined = messages.find(
        (message: Message): boolean => message.from === recipientJid,
      );

      if (lastMessageByRecipient) {
        this.#sendReceipt(lastMessageByRecipient);
      }
    }

    return chatHistory;
  }

  getChatUpdates(fromJid: string): Observable<ChatUpdate> {
    return new Observable<ChatUpdate>(
      (observer: Observer<ChatUpdate>): (() => void) => {
        const connection: Connection = this.#getConnection();

        let latestMessage: Message | undefined = undefined;

        const messageSignal: Handler = connection.addHandler(
          (stanza: Element): boolean => {
            const receivedElement: Element | null =
              stanza.querySelector('received');
            // message is an acknowledgement request -> XEP-0184
            if (receivedElement) {
              const messageId: string | null =
                receivedElement.getAttribute('id');
              const timestamp: string | null =
                receivedElement.getAttribute('timestamp');

              if (
                messageId &&
                timestamp &&
                !isNaN(new Date(timestamp).getTime())
              ) {
                observer.next({
                  type: 'receipt',
                  messageId,
                  timestamp: new Date(timestamp),
                });
              }

              return true;
            }

            const message: Message = transformChatMessage(
              this.#stropheJS,
              stanza,
            );

            if (
              !message.isArchived &&
              message.from.toLowerCase() === fromJid.toLowerCase() &&
              !stanza.querySelector('error')
            ) {
              const message: Message = transformChatMessage(
                this.#stropheJS,
                stanza,
              );

              if (!latestMessage || message.id !== latestMessage.id) {
                latestMessage = message;
                observer.next({
                  type: 'message',
                  message,
                });
              }

              const requestElement: Element | null =
                stanza.querySelector('request');
              // message sender wants to receive an acknowledgement
              if (
                requestElement &&
                requestElement.getAttribute('xmlns') ===
                  this.#stropheJS.Strophe.NS[receiptsNamespace]
              ) {
                this.#sendReceipt(message);
              }
            }
            return true;
          },
          '',
          'message',
          '',
          '',
          '',
        );

        const internalMessagesSubscription: Subscription =
          this.#internalMessages.subscribe({
            next: (message: Message): void => {
              observer.next({
                type: 'message',
                message,
              });
            },
          });

        return (): void => {
          internalMessagesSubscription.unsubscribe();
          connection.deleteHandler(messageSignal);
          observer.complete();
        };
      },
    );
  }

  async sendMessage(
    message: Omit<Message, 'from' | 'timestamp' | 'isArchived'>,
  ): Promise<void> {
    const connection: Connection = this.#getConnection();

    connection.send(createChatMessage(this.#stropheJS, message));

    const chatHistory: ChatHistory = await this.#getChatHistory(
      connection.jid,
      message.to,
      {
        max: 1,
      },
    );

    if (chatHistory.messages.length > 0) {
      this.#internalMessages.next(chatHistory.messages.at(-1)!);
    }
  }

  #getChatHistory(
    senderJid: string,
    recipientJid: string,
    filter?: {
      readonly end?: Date;
      readonly max?: number;
    },
  ): Promise<ChatHistory> {
    const connection: Connection = this.#getConnection();

    const messages: Message[] = [];
    const handler: Handler = connection.addHandler(
      (stanza: Element): boolean => {
        messages.push(transformChatMessage(this.#stropheJS, stanza));

        return true;
      },
      this.#stropheJS.Strophe.NS[mamNamespace],
      'message',
      '',
      '',
    );

    return new Promise<ChatHistory>(
      (
        resolve: (chatHistory: ChatHistory) => void,
        reject: (error: unknown) => void,
      ) => {
        connection.sendIQ(
          generateMAMIq(this.#stropheJS, senderJid, recipientJid, filter),
          (stanza: Element): boolean => {
            resolve(transformChatHistory(this.#stropheJS, stanza, messages));
            connection.deleteHandler(handler);

            return true;
          },
          (err: unknown): void => reject(err),
        );
      },
    );
  }

  sendPresence(contactUserJid: `${string}@${string}`): void {
    this.#getConnection().sendPresence(
      this.#stropheJS.$pres({ to: `${contactUserJid}` }).tree(),
    );
  }

  #getConnection(): Connection {
    const connection: Connection | undefined = this.#connectionFactory();

    if (!connection) {
      throw 'Not connected';
    }

    return connection;
  }

  #sendReceipt(message: Message) {
    const connection: Connection = this.#getConnection();

    connection.send(
      this.#stropheJS
        .$msg({
          id: connection.getUniqueId('auto-receipt'),
          to: message.from,
        })
        .c('received', {
          xmlns: this.#stropheJS.Strophe.NS[receiptsNamespace],
          id: message.id,
          timestamp: new Date().toISOString(),
        }),
    );
  }
}
