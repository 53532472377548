import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonTitle,
  IonToolbar,
  NavController,
} from '@ionic/angular/standalone';

import { addIcons } from 'ionicons';

import { close, lockClosed, person } from 'ionicons/icons';

import { TranslocoModule } from '@ngneat/transloco';

import { NavBackButtonComponent } from '../../../../common/components/buttons/nav-back-button/nav-back-button.component';

@Component({
  selector: 'mbeon-pwa-reset-credentials',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButton,
    IonList,
    IonLabel,
    IonButtons,
    IonButton,
    IonIcon,
    IonItem,
    NavBackButtonComponent,
    IonGrid,
    IonRow,
    IonCol,
  ],
  templateUrl: './reset-credentials.component.html',
  styleUrl: './reset-credentials.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetCredentialsComponent {
  readonly #navController: NavController = inject(NavController);

  constructor() {
    addIcons({ close, person, lockClosed });
  }

  async gotToPasswordReset(): Promise<void> {
    await this.#navController.navigateForward(['/password-reset/mail']);
  }

  async gotToUserNameReset(): Promise<void> {
    await this.#navController.navigateForward(['forgot-username-mail']);
  }

  async close(): Promise<void> {
    await this.#navController.navigateBack(['login']);
  }
}
