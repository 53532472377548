import { inject, Injectable } from '@angular/core';
import { type Event, ResolveEnd, Router } from '@angular/router';

import { filter, map, type Observable, shareReplay, startWith } from 'rxjs';

/**
 * This service serves as a workaround since Ionic's lifecycle methods like
 * "didViewEnter", etc. unfortunately do not work in the expected way when using
 * tabs and global routes
 */
@Injectable({
  providedIn: 'root',
})
export class ActiveRouteService {
  readonly #events$: Observable<string>;

  readonly #router: Router = inject(Router);

  constructor() {
    this.#events$ = this.#router.events.pipe(
      filter(
        (event: Event): event is ResolveEnd => event instanceof ResolveEnd,
      ),
      map((event: ResolveEnd): string => event.url),
      shareReplay(1),
    );
  }

  isActiveRoute(route: string): Observable<boolean> {
    return this.#events$.pipe(
      startWith(this.#router.routerState.snapshot.url),
      map((url: string): boolean => url === route),
    );
  }
}
