import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { Router } from '@angular/router';

import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonList,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

import { TranslocoModule } from '@ngneat/transloco';

import { addIcons } from 'ionicons';
import { checkmarkCircle, closeCircle, ellipse } from 'ionicons/icons';

import { lastValueFrom, Observable } from 'rxjs';

import {
  AuthenticationApplicationService,
  ResetPasswordErrors,
} from '@mbeon-pwa/domain';

import { RxLet } from '@rx-angular/template/let';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { InputFormFieldComponent } from '../../../../common/components/inputs/input-form-field/input-form-field.component';

import { PasswordValidatorComponent } from '../../../../common/components/password-validators/password-validator.component';
import { PasswordResetState } from '../../state/password-restet.state';
import { PasswordResetState as State } from '../../state/password-reset-state.type';
import { passwordRepeatValidator } from '../../../authentication/validators/password-repeat/password-repeat.validator';

@UntilDestroy()
@Component({
  selector: 'mbeon-pwa-password-reset-set-password',
  standalone: true,
  imports: [
    CommonModule,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonList,
    IonTitle,
    IonToolbar,
    ReactiveFormsModule,
    InputFormFieldComponent,
    TranslocoModule,
    PasswordValidatorComponent,
    IonCol,
    IonGrid,
    IonRow,
    RxLet,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './password-reset-set-password.component.html',
  styleUrls: ['./password-reset-set-password.component.scss'],
})
export class PasswordResetSetPasswordComponent implements OnInit {
  readonly passwordForm: FormGroup<{
    readonly password: FormControl<string | null>;
    readonly confirmPassword: FormControl<string | null>;
  }> = new FormGroup({
    password: new FormControl('', {
      validators: [Validators.required],
    }),
    confirmPassword: new FormControl('', []),
  });

  readonly #router: Router = inject(Router);

  readonly #passwordResetState: PasswordResetState = inject(PasswordResetState);

  readonly #passwordResetStateData = this.#passwordResetState.get();

  readonly #authenticationApplicationService: AuthenticationApplicationService =
    inject(AuthenticationApplicationService);

  constructor() {
    addIcons({
      checkmarkCircle,
      closeCircle,
      ellipse,
    });

    this.passwordForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((): void => {
        this.#passwordResetState.resetPasswordErrors();
      });
  }

  get state$(): Observable<State> {
    return this.#passwordResetState.select();
  }

  ngOnInit(): void {
    this.passwordForm.setValidators([
      passwordRepeatValidator(),
      Validators.required,
    ]);
  }

  async submit() {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    if (this.passwordForm.valid) {
      try {
        await lastValueFrom(
          this.#authenticationApplicationService.resetPassword(
            this.passwordForm.controls.password.value!,
            this.passwordForm.controls.confirmPassword.value!,
            this.#passwordResetStateData.token!,
          ),
        );

        this.#passwordResetState.set({
          setPasswordSuccess: true,
        });

        await this.#router.navigate(['password-reset/reset-success']);
      } catch (error: unknown) {
        if (error instanceof ResetPasswordErrors) {
          this.#passwordResetState.setPasswordErrors(error);
        } else {
          await this.#router.navigate(['maintenance']);
        }
      }
    }
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }

  async cancel(): Promise<void> {
    this.#passwordResetState.reset();
    await this.#router.navigate(['login']);
  }
}
