<ion-header *transloco="let t">
  <ion-toolbar>
    <ion-buttons>
      <ion-button (click)="goBack()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title
      >{{ t('delete-account.link-has-been-sent.header.title') }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *transloco="let t" [fullscreen]="true">
  <ion-grid [fixed]="true">
    <ion-row class="ion-justify-content-center">
      <ion-col>
        <div class="p-8 container">
          <div>
            <h2 class="mt-0 mb-1">
              {{ t('delete-account.link-has-been-sent.title') }}
            </h2>
            <p
              [innerHTML]="t('delete-account.link-has-been-sent.description')"
              class="mt-0 font-semibold"
            ></p>
          </div>
          <ion-button (click)="goToStartPage()" class="mb-8">
            {{ t('delete-account.link-has-been-sent.button.start-page') }}
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
