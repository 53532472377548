<ng-container *transloco="let t">
  <form
    *rxLet="state$; let state"
    [formGroup]="chatMessageForm"
    (submit)="submitMessage($event)"
  >
    <ion-buttons slot="start">
      <ion-button
        color="primary"
        fill="solid"
        shape="round"
        size="small"
        (click)="filePicker.click()"
        [disabled]="!state.isChatAvailable"
      >
        <ion-icon color="light" name="add" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>

    <div class="input">
      <div
        class="attachment-list"
        #fileList
        [@collapse]="!(showAttachments$ | push)"
      >
        <div
          class="attachment-list-item"
          *rxFor="
            let file of state.attachments;
            let index = index;
            renderCallback: attachmentsRenderCallback$
          "
          [ngSwitch]="file.type"
          [@jackInTheBoxOnEnter]="{
            value: '',
            params: {
              delay: index * 100
            }
          }"
          [@bounceOutDownOnLeave]
          (@bounceOutDownOnLeave.start)="attachmentDisappeared($event)"
        >
          <ion-button
            class="attachment-list-item-delete"
            color="medium"
            shape="round"
            size="small"
            (click)="removeAttachment(file)"
          >
            <ion-icon name="close-outline" slot="icon-only"></ion-icon>
          </ion-button>

          <picture class="attachment-list-item-image" *ngSwitchCase="'image'">
            <img
              [attr.alt]="'upload-image-' + index"
              [attr.src]="$any(file).preview"
            />
          </picture>

          <div class="attachment-list-item-document" *ngSwitchCase="'document'">
            <picture>
              <ion-icon name="document-outline"></ion-icon>
              <figcaption *rxIf="$any(file).fileType">
                {{ $any(file).fileType | uppercase }}
              </figcaption>
            </picture>

            <dl>
              <dt>{{ file.name }}</dt>
              <dd>{{ file.size | chatThreadFileSize }}</dd>
            </dl>
          </div>
        </div>
      </div>

      <ion-item
        lines="none"
        [disabled]="state.isSendingMessage || !state.isChatAvailable"
      >
        <ion-textarea
          formControlName="message"
          [autoGrow]="true"
          [placeholder]="t('chat-thread.message.write-message')"
          [rows]="1"
        >
        </ion-textarea>
      </ion-item>
    </div>

    <ion-button
      class="send-message"
      color="primary"
      fill="solid"
      shape="round"
      size="small"
      type="submit"
      [class.state-active]="
        chatMessageForm.valid || state.attachments.length > 0
      "
      [disabled]="
        state.isSendingMessage ||
        chatMessageForm.invalid ||
        !state.isChatAvailable
      "
    >
      <ion-icon
        color="light"
        name="arrow-up-outline"
        slot="icon-only"
      ></ion-icon>
    </ion-button>

    <mbeon-pwa-consultation-message-input-voice
      class="record-message"
      [class.state-active]="
        chatMessageForm.invalid && state.attachments.length === 0
      "
      [disabled]="
        state.isSendingMessage ||
        chatMessageForm.valid ||
        !state.isChatAvailable
      "
      (recording)="sendVoiceRecording($event)"
    ></mbeon-pwa-consultation-message-input-voice>

    <input
      class="file-picker"
      formControlName="attachments"
      type="file"
      #filePicker
      [accept]="acceptedFiles"
      (change)="loadAttachments(filePicker.files)"
    />

    <ion-alert
      [buttons]="[{ role: 'cancel', text: t('general.button.ok') }]"
      [isOpen]="state.unknownFileSelected"
      [message]="t('consultation.unknown-file')"
      (didDismiss)="toggleUnknownFileSelectionAlert(false)"
    >
    </ion-alert>

    <ion-alert
      [buttons]="[{ role: 'cancel', text: t('general.button.ok') }]"
      [isOpen]="state.hasSendMessageError"
      [message]="t('consultation.send-message-error')"
      (didDismiss)="toggleSendMessageError(false)"
    ></ion-alert>
  </form>
</ng-container>
