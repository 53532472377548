<ng-container *transloco="let t">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button (click)="cancel()">
          {{ t('register.password-data.header.button') }}
        </ion-button>
      </ion-buttons>
      <ion-title>{{ t('register.password-data.header.title') }}</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content [fullscreen]="true">
    <ion-grid [fixed]="true">
      <ion-row class="ion-justify-content-center">
        <ion-col>
          <div *rxLet="state$; let state" class="p-6">
            <h1 class="mt-0 mb-2">{{ t('register.password-data.title') }}</h1>
            <p class="mb-6 mt-0">
              {{ t('register.password-data.description') }}
            </p>

            <form (ngSubmit)="submit()" [formGroup]="passwordForm">
              <mbeon-pwa-password-validator
                [control]="passwordForm.controls.password"
              ></mbeon-pwa-password-validator>

              <ng-container>
                <ion-list>
                  <mbeon-pwa-input-form-field
                    [control]="passwordForm.controls.password"
                    [hasError]="
                      state.passwordInvalid ||
                      (!!passwordForm.controls.password.errors &&
                        !!passwordForm.controls.password.value)
                    "
                    [label]="t('input.password.label')"
                    class="mb-1 block"
                    type="password"
                  ></mbeon-pwa-input-form-field>

                  <div class="input-error-message mb-2 pl-4">
                    <p
                      *ngIf="
                        !!passwordForm.controls.password.errors?.[
                          'whiteSpace'
                        ] && !!passwordForm.controls.password.value
                      "
                    >
                      {{ t('input-validator.password.whitespace') }}
                    </p>

                    <p
                      *ngIf="
                        !!passwordForm.controls.password.errors?.[
                          'emailInPassword'
                        ] && !!passwordForm.controls.password.value
                      "
                    >
                      {{ t('input-validator.password-contains-email') }}
                    </p>

                    <p
                      *ngIf="
                        !!passwordForm.controls.password.errors?.[
                          'usernameInPassword'
                        ] && !!passwordForm.controls.password.value
                      "
                    >
                      {{ t('input-validator.password-contains-username') }}
                    </p>

                    <p *ngIf="state.passwordContainsEmailOrUsername">
                      {{
                        t('input-validator.password-contains-username-or-email')
                      }}
                    </p>
                  </div>

                  <mbeon-pwa-input-form-field
                    [control]="passwordForm.controls.confirmPassword"
                    [hasError]="!!passwordForm.errors?.['mismatch']"
                    [label]="t('input.password-repeat.label')"
                    class="block"
                    type="password"
                  ></mbeon-pwa-input-form-field>
                  <div class="input-error-message mb-0 pl-4">
                    <p
                      *ngIf="
                        !!passwordForm.errors &&
                        passwordForm.errors?.['mismatch']
                      "
                    >
                      {{ t('input-validator.password.repeat') }}
                    </p>
                  </div>
                </ion-list>

                <ion-button
                  *rxLet="componentState$; let componentState"
                  [disabled]="
                    passwordForm.invalid || componentState.showLoadingIndicator
                  "
                  class="button_primary mb-7 mt-2"
                  type="submit"
                >
                  <ng-container *rxIf="componentState.showLoadingIndicator">
                    <ion-spinner name="lines-sharp"></ion-spinner>
                  </ng-container>
                  <ng-container *rxIf="!componentState.showLoadingIndicator"
                    >{{ t('register.password-data.form.button.submit') }}
                  </ng-container>
                </ion-button>
                <ion-button (click)="goBack()" class="button_text" fill="clear"
                  >{{ t('general.button.back') }}
                </ion-button>
              </ng-container>
            </form>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</ng-container>
