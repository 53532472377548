import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

import { TranslocoModule } from '@ngneat/transloco';

import { RxLet } from '@rx-angular/template/let';

import { Observable } from 'rxjs';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { InputFormFieldComponent } from '../../../../common/components/inputs/input-form-field/input-form-field.component';
import { RegistrationState } from '../../states/registration/registration.state';
import { RegistrationState as State } from '../../states/registration/registration-state.type';
import { usernameValidator } from '../../validators/username/username.validator';
import { usernameInEmailValidator } from '../../validators/email-in-username/username-in-email.validator';
import { emailValidation } from '../../validators/email-validator/email-validator';

@UntilDestroy()
@Component({
  selector: 'mbeon-pwa-register',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,

  imports: [
    FormsModule,
    InputFormFieldComponent,
    IonButton,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonTitle,
    IonToolbar,
    NgIf,
    ReactiveFormsModule,
    TranslocoModule,
    RxLet,
    IonLabel,
    IonCol,
    IonGrid,
    IonRow,
    IonIcon,
  ],
  templateUrl: './registration-account-data-page.component.html',
  styleUrls: ['./registration-account-data-page.component.scss'],
})
export class RegistrationAccountDataComponent implements OnInit {
  readonly registrationAccountDataFormGroup: FormGroup<{
    readonly email: FormControl<string | null>;
    readonly username: FormControl<string | null>;
    readonly acceptTermsOfUse: FormControl<boolean | null>;
    readonly privacy: FormControl<boolean | null>;
  }>;

  readonly #registrationState: RegistrationState = inject(RegistrationState);

  readonly #router: Router = inject(Router);

  readonly #registrationStateData: State = this.#registrationState.get();

  constructor() {
    this.registrationAccountDataFormGroup = new FormGroup({
      email: new FormControl<string | null>(
        this.#registrationStateData?.email ?? '',
        {
          validators: [Validators.required, emailValidation],
        },
      ),
      username: new FormControl<string | null>(
        this.#registrationStateData?.username ?? '',
      ),
      acceptTermsOfUse: new FormControl<boolean>(
        !!this.#registrationStateData?.termsOfUse,
        [Validators.requiredTrue],
      ),
      privacy: new FormControl<boolean>(
        !!this.#registrationStateData?.privacy,
        [Validators.requiredTrue],
      ),
    });

    this.registrationAccountDataFormGroup.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((): void => {
        this.#registrationState.setResetAccountDataErrors();
      });
  }

  get state$(): Observable<State> {
    return this.#registrationState.select();
  }

  ngOnInit(): void {
    this.registrationAccountDataFormGroup.controls.username.addValidators([
      Validators.required,
      usernameValidator,
      usernameInEmailValidator(
        this.registrationAccountDataFormGroup.controls.email,
      ),
    ]);
  }

  async cancel(): Promise<void> {
    this.#registrationState.reset();
    await this.#router.navigate(['login']);
  }

  async submit(): Promise<void> {
    if (this.registrationAccountDataFormGroup.valid) {
      /* eslint-disable @typescript-eslint/no-non-null-assertion*/
      this.#registrationState.setResetAccountDataErrors();

      this.#registrationState.setAccountData({
        username: this.registrationAccountDataFormGroup.value.username!,
        email: this.registrationAccountDataFormGroup.value.email!,
        acceptTermsOfUse:
          this.registrationAccountDataFormGroup.value.acceptTermsOfUse!,
        privacy: this.registrationAccountDataFormGroup.value.privacy!,
      });
      /* eslint-enable */

      await this.#router.navigate(['register/password-data']);
    }
  }

  processLinks(e: Event) {
    const element: HTMLElement = e.target as HTMLElement;
    if (element.nodeName === 'A') {
      e.preventDefault();
      const link = element.getAttribute('href');
      this.#router.navigate([link]);
    }
  }
}
