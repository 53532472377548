import { inject, Injectable } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';

import { RxState } from '@rx-angular/state';

import { filter } from 'rxjs';

import { AccountDataErrors } from '@mbeon-pwa/domain';

import type { RegistrationState as State } from './registration-state.type';

@Injectable({
  providedIn: 'root',
})
export class RegistrationState extends RxState<State> {
  #router: Router = inject(Router);

  constructor() {
    super();

    this.reset();

    this.#router.events
      .pipe(
        filter(
          (event: Event): event is NavigationStart =>
            event instanceof NavigationStart,
        ),
      )
      .subscribe({
        next: (event: NavigationStart): void => {
          if (!event.url.startsWith('/register')) {
            this.reset();
          }
        },
      });
  }

  setAccountDataSended(sended: boolean): void {
    this.set({
      accountDataSended: sended,
    });
  }

  setAccountDataError(data: AccountDataErrors) {
    this.set({
      emailInvalid:
        data.emailAddressContainsInvalidString ||
        data.emailAddressIsEmpty ||
        data.emailAddressContainsSpacesOrTabs ||
        data.emailAddressIsTooLong ||
        data.emailAddressIsTooShort ||
        data.emailAddressContainsNotAllowedCharacters ||
        data.emailAddressDnsCheckFailed ||
        data.emailAddressIsNotRfcCompliant,
      emailAlreadyInUse: data.emailAddressIsAlreadyInUse,
      usernameInvalid:
        data.usernameIsNotAString ||
        data.usernameContainsNotAllowedCharacters ||
        data.usernameIsEmpty ||
        data.usernameIsSimilarToEmail ||
        data.usernameContainsSpacesOrTabs ||
        data.usernameIsTooLong ||
        data.usernameIsTooShort,
      usernameAlreadyInUse: data.usernameIsAlreadyInUse,
      passwordInvalid:
        data.passwordIsEmpty ||
        data.passwordIsTooLong ||
        data.passwordIsTooShort ||
        data.passwordContainsNoMandatorySpecialCharacters ||
        data.passwordContainsSpacesOrTabs ||
        data.passwordDoesNotContainLowercaseCharacters ||
        data.passwordDoesNotContainANumber ||
        data.passwordDoesNotContainUppercaseCharacters,
      passwordContainsEmailOrUsername: data.passwordIsSimilarToEmailOrUsername,
      usernameIsSimilarToEmail: data.usernameIsSimilarToEmail,
    });
  }

  setResetAccountDataErrors() {
    this.set({
      emailAlreadyInUse: false,
      emailInvalid: false,
      usernameAlreadyInUse: false,
      usernameInvalid: false,
      usernameIsSimilarToEmail: false,
    });
  }

  setResetPasswordDataErrors() {
    this.set({
      passwordInvalid: false,
      passwordContainsEmailOrUsername: false,
    });
  }

  setAccountData(data: {
    readonly acceptTermsOfUse: boolean;
    readonly privacy: boolean;
    readonly username: string;
    readonly email: string;
  }) {
    this.set({
      email: data.email,
      username: data.username,
      termsOfUse: data.acceptTermsOfUse,
      privacy: data.privacy,
    });
  }

  setPasswordData(password: string | null, confirmPassword: string | null) {
    this.set({
      password,
      confirmPassword,
    });
  }

  reset() {
    this.set({
      email: undefined,
      emailInvalid: undefined,
      emailAlreadyInUse: false,
      username: undefined,
      usernameInvalid: false,
      usernameAlreadyInUse: false,
      usernameIsSimilarToEmail: false,
      termsOfUse: false,
      registrationLinkExpired: false,
      privacy: false,
      registerSuccess: false,
      passwordInvalid: false,
      passwordContainsEmailOrUsername: false,
      password: undefined,
      confirmPassword: undefined,
      accountDataSended: false,
      token: undefined,
    });
  }
}
