import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  UrlTree,
} from '@angular/router';
import { inject } from '@angular/core';

export const consultantIdGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
): true | UrlTree => {
  const consultantId: string | null = route.paramMap.get('id');
  const router: Router = inject(Router);

  if (consultantId) {
    return true;
  } else {
    return router.parseUrl('favorites');
  }
};
