import { type Route } from '@angular/router';

import { ForgotUsernameMailComponent } from './pages/forgot-username-mail/forgot-username-mail.component';
import { userNameSuccessGuard } from './guards/user-name-success/user-name-success.guard';
import { ForgotUserNameSendedComponent } from './pages/username-reset-sended/forgot-user-name-sended.component';

export const usernameResetRoutes: readonly Route[] = [
  {
    path: 'forgot-username-mail',
    component: ForgotUsernameMailComponent,
  },

  {
    path: 'forgot-username-sended',
    component: ForgotUserNameSendedComponent,
    canActivate: [userNameSuccessGuard],
  },
];
