import { bootstrapApplication } from '@angular/platform-browser';

import { provideInfrastructure } from '@mbeon-pwa/infrastructure';

import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environments';

bootstrapApplication(AppComponent, {
  providers: [
    ...appConfig.providers,
    provideInfrastructure({
      authentication: {
        baseUrl: environment.apiBaseUrl,
      },
      consultation: {
        host: environment.chat.host,
        serverURL: environment.chat.websocket,
      },
    }),
  ],
}).catch((err: unknown): void =>
  // eslint-disable-next-line no-console
  console.error(err),
);
