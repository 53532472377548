import { type Route } from '@angular/router';

import { authenticatedGuard } from '../../common/guards/authenticated/authenticated.guard';

import { deleteAccountDeepLinkGuard } from './guards/delete-account-deep-link/delete-account-deep-link.guard';
import { deleteAccountLinkExpiredGuard } from './guards/delete-account-link-expired/delete-account-link-expired.guard';
import { deleteAccountLinkHasBeenSentGuard } from './guards/delete-account-link-has-been-sent/delete-account-link-has-been-sended.guard';
import { deleteAccountSuccessGuard } from './guards/delete-account-success/delete-account-success.guard';
import { deleteAccountTokenValidGuard } from './guards/delete-account-token-valid/delete-account-token-valid.guard';
import { setPasswordGuard } from './guards/set-password/set-password.guard';
import { ApproveDeleteAccTokenComponent } from './pages/approve-delete-acc-token/approve-delete-acc-token.component';
import { DeleteAccountComponent } from './pages/delete-account/delete-account.component';
import { DeleteAccountLinkHasBeenSentComponent } from './pages/delete-account-link-has-been-sent/delete-account-link-has-been-sent.component';
import { DeleteAccountLinkExpiredComponent } from './pages/delete-account-link-expired/delete-account-link-expired.component';
import { DeleteAccountSuccessComponent } from './pages/delete-account-success/delete-account-success.component';
import { LanguageSwitchComponent } from './pages/language-switch/language-switch.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { SetPasswordComponent } from './pages/set-password/set-password.component';
import { SetPasswordSuccessComponent } from './pages/set-password-success/set-password-success.component';

export const profileRoutes: readonly Route[] = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [authenticatedGuard],
  },
  {
    path: 'language',
    component: LanguageSwitchComponent,
    canActivate: [authenticatedGuard],
  },
  {
    path: 'set-password',
    component: SetPasswordComponent,
    canActivate: [authenticatedGuard],
  },
];

export const globalProfileRoutes: readonly Route[] = [
  {
    path: 'delete-account-link-has-been-sent',
    component: DeleteAccountLinkHasBeenSentComponent,
    canActivate: [deleteAccountLinkHasBeenSentGuard],
  },
  {
    path: 'delete-account',
    component: DeleteAccountComponent,
    canActivate: [deleteAccountTokenValidGuard],
  },
  {
    path: 'delete-account-success',
    component: DeleteAccountSuccessComponent,
    canActivate: [deleteAccountSuccessGuard],
  },
  {
    path: 'delete-account-link-expired',
    component: DeleteAccountLinkExpiredComponent,
    canActivate: [deleteAccountLinkExpiredGuard],
  },
  {
    path: 'customer/approve-customer-delete',
    component: ApproveDeleteAccTokenComponent,
    canActivate: [deleteAccountDeepLinkGuard, authenticatedGuard],
  },
  {
    path: 'set-password-success',
    component: SetPasswordSuccessComponent,
    canActivate: [setPasswordGuard],
  },
];
