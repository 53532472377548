<ng-container *transloco="let t">
  <ion-header>
    <ion-toolbar>
      <ion-title
        >{{ t('forgot-user-name-mail-sended.header.title') }}
      </ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content [fullscreen]="true">
    <ion-grid [fixed]="true" class="height-full">
      <ion-row class="ion-justify-content-center height-full">
        <ion-col>
          <div class="px-4 content-wrapper pt-2">
            <div>
              <h2>{{ t('forgot-user-name-mail-sended.title') }}</h2>
              <p class="mb-6 mt-0">
                {{ t('forgot-user-name-mail-sended.description') }}
              </p>
            </div>
            <ion-button (click)="close()" class="button_primary mb-12 mt-2"
              >{{ t('forgot-user-name-mail-sended.button.send-username') }}
            </ion-button>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</ng-container>
