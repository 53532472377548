import { type ComponentRef } from '@angular/core';

import type { PlatformIdentifier } from '@mbeon-pwa/common';

import { fromEvent } from 'rxjs';

import { AppComponent } from '../../../app.component';
import { PushNotifications } from '../../services/push-notifications/push-notifications';

export function pushNotificationsBootstrapListener(
  document: Document,
  navigator: Navigator,
  platformIdentifier: PlatformIdentifier,
  pushNotifications: PushNotifications,
): (compRef: ComponentRef<unknown>) => void {
  return (compRef: ComponentRef<unknown>): void => {
    if (
      platformIdentifier.isBrowser &&
      compRef.componentType === AppComponent
    ) {
      (async (): Promise<void> => {
        try {
          await pushNotifications.init();
        } catch (e) {
          // ignore
        }
      })();

      fromEvent(document, 'visibilitychange').subscribe({
        next: async (): Promise<void> => {
          if (!document.hidden && navigator.clearAppBadge) {
            await navigator.clearAppBadge();
          }
        },
      });
    }
  };
}
