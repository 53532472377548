import { type Route } from '@angular/router';

import { notAuthenticatedGuard } from '../../common/guards/not-authenticated/not-authenticated.guard';

import { LoginComponent } from './pages/login/login-page.component';
import { RegistrationAccountDataComponent } from './pages/registration-account-data/registration-account-data-page.component';
import { RegistrationPasswordDataComponent } from './pages/registration-password-data/registration-password-data-page.component';
import { RegistrationEmailLinkSendedComponent } from './pages/registration-email-link-sended/registration-email-link-sended.component';
import { registrationPasswordDataGuard } from './guards/registration/registration-password-data/registration-password-data.guard';
import { RegistrationLinkExpiredComponent } from './pages/registration-link-expired/registration-link-expired.component';
import { ApproveEmailComponent } from './pages/approve-email/approve-email.component';
import { RegistrationSuccessComponent } from './pages/registration-success/registration-success.component';
import { ResetCredentialsComponent } from './pages/reset-credentials/reset-credentials.component';
import { registrationAccountDataSendedGuard } from './guards/registration/registration-accound-data-sended/registration-account-data-sended.guard';
import { registrationLinkExpiredGuard } from './guards/registration/registration-link-expired/registration-link-expired.guard';
import { registrationSuccessGuard } from './guards/registration/registration-success/registration-success.guard';

export const authenticationRoutes: readonly Route[] = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [notAuthenticatedGuard],
  },
  {
    path: 'register/account-data',
    component: RegistrationAccountDataComponent,
  },
  {
    path: 'register/password-data',
    component: RegistrationPasswordDataComponent,
    canActivate: [registrationPasswordDataGuard],
  },
  {
    path: 'register/email-link-sended',
    component: RegistrationEmailLinkSendedComponent,
    canActivate: [registrationAccountDataSendedGuard],
  },
  {
    path: 'register/registration-link-expired',
    component: RegistrationLinkExpiredComponent,
    canActivate: [registrationLinkExpiredGuard],
  },
  {
    path: 'register/registration-success',
    component: RegistrationSuccessComponent,
    canActivate: [registrationSuccessGuard],
  },
  {
    path: 'approve-communication-channel/email',
    component: ApproveEmailComponent,
  },
  {
    path: 'reset-credential',
    component: ResetCredentialsComponent,
  },
];
