<ion-header *transloco="let t">
  <ion-toolbar>
    <ion-title>{{ t('delete-account.link-expired.header.title') }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *transloco="let t" [fullscreen]="true">
  <ion-grid [fixed]="true" class="height-full">
    <ion-row class="ion-justify-content-center height-full">
      <ion-col>
        <div class="p-6 container">
          <p class="mt-0">
            {{ t('delete-account.link-expired.description') }}
          </p>
          <div>
            <ion-button
              (click)="reInitDeleteAccount()"
              class="button_primary mb-6"
            >
              {{ t('delete-account.link-expired.button.delete-account') }}
            </ion-button>
            <ion-button (click)="goToStartPage()" class="button_secondary mb-8">
              {{ t('delete-account.link-expired.button.back-to-login') }}
            </ion-button>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
