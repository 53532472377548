import { ModuleWithProviders, NgModule } from '@angular/core';
import { UserInfoRepository } from '@mbeon-pwa/domain';

import { UserInfoRepositoryImpl } from './repositories/messages/message.repository';

@NgModule()
export class UserInfoModule {
  static forRoot(): ModuleWithProviders<UserInfoModule> {
    return {
      ngModule: UserInfoModule,
      providers: [
        {
          provide: UserInfoRepository,
          useClass: UserInfoRepositoryImpl,
        },
      ],
    };
  }
}
