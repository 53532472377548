import { isPlatformBrowser } from '@angular/common';
import { inject, InjectionToken, PLATFORM_ID } from '@angular/core';

import { MemoryStorage } from '../services/memory-storage/memory-storage.service';

export const LOCAL_STORAGE: InjectionToken<Storage> = new InjectionToken(
  'LOCAL_STORAGE',
  {
    providedIn: 'platform',
    factory: (): Storage => {
      const platformId: object = inject(PLATFORM_ID);

      if (isPlatformBrowser(platformId)) {
        return window.localStorage;
      }

      return new MemoryStorage();
    },
  },
);
