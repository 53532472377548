import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

import { addIcons } from 'ionicons';
import { chevronBackOutline, close } from 'ionicons/icons';

import { TranslocoModule } from '@ngneat/transloco';

import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import {
  ForgotProfileNameErrors,
  LoginApplicationService,
} from '@mbeon-pwa/domain';

import { lastValueFrom, Observable } from 'rxjs';

import { Router } from '@angular/router';

import { RxLet } from '@rx-angular/template/let';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { InputFormFieldComponent } from '../../../../common/components/inputs/input-form-field/input-form-field.component';
import { UsernameResetState } from '../../state/username-reset/username-reset.state';
import { UsernameResetState as State } from '../../state/username-reset/username-reset.state.type';
import { emailValidation } from '../../../authentication/validators/email-validator/email-validator';

@UntilDestroy()
@Component({
  selector: 'mbeon-pwa-forgot-username-mail',
  standalone: true,
  imports: [
    CommonModule,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonList,
    IonItem,
    IonLabel,
    IonToolbar,
    IonTitle,
    TranslocoModule,
    InputFormFieldComponent,
    ReactiveFormsModule,
    IonGrid,
    IonRow,
    IonCol,
    RxLet,
  ],
  templateUrl: './forgot-username-mail.component.html',
  styleUrl: './forgot-username-mail.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotUsernameMailComponent {
  readonly userNameForm: FormGroup<{
    readonly userMail: FormControl<string | null>;
  }> = new FormGroup({
    userMail: new FormControl<string>('', [
      Validators.required,
      emailValidation,
    ]),
  });

  readonly #router: Router = inject(Router);

  readonly #loginApplicationService: LoginApplicationService = inject(
    LoginApplicationService,
  );

  readonly #userNameResetState: UsernameResetState = inject(UsernameResetState);

  constructor() {
    this.userNameForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.#userNameResetState.reset();
    });
    addIcons({ chevronBackOutline, close });
  }

  get state$(): Observable<State> {
    return this.#userNameResetState.select();
  }

  async submit(): Promise<void> {
    if (this.userNameForm.valid) {
      try {
        await lastValueFrom(
          this.#loginApplicationService.getForgottenUsername(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.userNameForm.controls.userMail.value!,
          ),
        );
        this.#userNameResetState.setSuccess(true);
        await this.#router.navigate(['forgot-username-sended']);
      } catch (error) {
        if (error instanceof ForgotProfileNameErrors) {
          this.#userNameResetState.setError(error);
        } else {
          await this.#router.navigate(['non-specific-error']);
        }
      }
    }
  }

  async close(): Promise<void> {
    await this.#router.navigate(['login']);
  }

  async goBack(): Promise<void> {
    await this.#router.navigate(['reset-credential']);
  }
}
